import { Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { modificadores_producto } from '../../../../models/modificadores_producto';
import { tipomodificadores } from '../../../../models/tipomodificadores';
import { ColoresDPana, ColorsDPana } from '../../../../utils/Color';
import { useStateBasedOnProps } from '../../../../utils/helpers';
import "../style.css";
import ItemExtraDlg from './item-extra-dlg';

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    handleClick?: any;
    items?: modificadores_producto[];
    ThemeColor?: ColoresDPana;
    titulo?: string;
    max: number;
    esLimite: boolean;
    totalItems: number;
    settotalExtras?: any;
    handleResponse?: any;
    onExtrasChange?: any;
    esMultiExtras: boolean;
}

export const ExtraItemsDlg = forwardRef(({ esMultiExtras, onExtrasChange, settotalExtras, totalItems, esLimite, max, titulo, items, handleResponse, style }: Props, ref) => {
    const [Maximo, setMaximo] = useStateBasedOnProps(totalItems > max ? totalItems : max);
    const [Van, setVan] = useState(0);
    const [VanGratis, setVanGratis] = useState(0);
    const [itemsCount, setitemsCount] = useState<{ id: number, count: number, precioTotal: number }[]>([]);
    const [tituloVan, settituloVan] = useState("");
    const extraRef = useRef<any>([]);
    useImperativeHandle(ref, () => ({
        ResetExtras,
    }));
    const ResetExtras = () => {
        extrasaCero();
        for (let index = 0; index < extraRef.current.length; index++) {
            if (extraRef.current[index])
                extraRef.current[index].ResetExtras();
        }
    }
    const mstyle: React.CSSProperties = {
        color: `rgb(${ColorsDPana.black[0]}, ${ColorsDPana.black[1]}, ${ColorsDPana.black[2]})`,
        backgroundColor: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})`,
        background: `linear-gradient(180deg, rgba(${ColorsDPana.primary[0]},${ColorsDPana.primary[1]},${ColorsDPana.primary[2]}, 0.1) 0%, rgba(${ColorsDPana.textcolor[0]},${ColorsDPana.textcolor[1]},${ColorsDPana.textcolor[2]}, 1) 30px)`,
        ...style
    }
    const createItemListItem = (item: modificadores_producto, key: string | number) => {
        //build a display name
        let displayName = item.Nombre ? item.Nombre : "";
        if (!displayName) {
            item.Nombre = "Extra sin Nombre";
        }
        return (
            <ItemExtraDlg
                key={"extra" + key}
                ref={el => extraRef.current[key] = el}
                item={item}
                onClick={() => { }}
                max={Maximo}
                setVan={setVan}
                setVanGratis={setVanGratis}
                VanGratis={VanGratis}
                Van={Van}
                handleCount={onCount}
                esLimite={esLimite}
                totalItems={totalItems}
            />
        )
    }
    const extrasaCero = () => {
        let extras: number = 0;
        /*  itemsCount.forEach(el => {
             el.count = 0;
             el.precioTotal = 0;
         }); */
        settotalExtras(0);
        setVan(0);
        setVanGratis(0);
        onExtrasChange(undefined);
    }

    const onCount = (id: number, count: number, PrecioTotal: number) => {
        if (items) {
            if (!itemsCount)
                setitemsCount([]);
            let ic = itemsCount.find(x => x.id == id);
            if (ic) {
                ic.count = count;
                ic.precioTotal = PrecioTotal;
            }
            else {
                PrecioTotal = !Number.isNaN(Number(PrecioTotal)) ? +PrecioTotal : 0;
                itemsCount.push({ id: id, count: count, precioTotal: PrecioTotal });
            }
        }
        sumaExtras(id);
    }

    const sumaExtras = (id: number | undefined = undefined) => {
        let suma: number = 0;
        if (!itemsCount)
            setitemsCount([]);
        if (esMultiExtras) {
            itemsCount.forEach(el => {
                suma += el.precioTotal;
            });
            setitemsCount(itemsCount.filter((x, index, itemsCount) => {
                return x.count !== 0;
            }));
        } else {
            if (id) {
                let item: modificadores_producto | undefined = items?.find(x => x.ID == id);
                extrasaCero();
                itemsCount.length = 0;
                if (item) {
                    let PrecioTotal = !Number.isNaN(Number(item.MontoPrecioDivisa1)) ? item.MontoPrecioDivisa1 ? +item.MontoPrecioDivisa1 : 0 : 0;
                    itemsCount.push({ id: item.ID, count: 1, precioTotal: PrecioTotal });
                }
            }
        }
        if (settotalExtras)
            settotalExtras(suma);
        onExtrasChange(itemsCount);
    }

    useEffect(() => {
        if (totalItems < Van) {
            ResetExtras();
        }
    }, [totalItems]);
    useEffect(() => {
        if (Maximo && Maximo > 0) {
            settituloVan(esLimite ? Maximo > 1 ? ` (${Maximo} gratis)` : "" : `(Más de ${Maximo} gratis. Aplica cobro)`)
        }
    }, [Maximo]);

    useEffect(() => {
        /*  if (item) {
             itemsCount.length = 0;
             item.forEach(el => {
                 itemsCount.push({ id: el.ID, count: 0, precioTotal: 0 });
             });
         } */
    }, [items]);

    return (
        <>
            <div className="item-detail-root" style={mstyle} >
                <div className="item-detail-header">
                    <Typography className="item-detail-textheader" style={{ color: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})` }} >{titulo ? titulo : "Agregue a su pedido"}</Typography>
                    <div className="w-100 text-end">
                        <Typography variant='caption' style={{ color: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})` }} > {tituloVan}</Typography>
                    </div>
                </div>
                <div className="item-detail-content">
                    {items ?
                        items.length ?
                            items.map((data, index) => createItemListItem(data, index))
                            : ""
                        : ""
                    }
                </div>
            </div>

        </>
    )
});
export default ExtraItemsDlg;
