import { Container } from '@mui/material';
import React, { useState, Component, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import SimpleToast from '../../../components/simple-toast';
import VerticalCenter from '../../../components/vertical-center';
import "./style.css";

export default function PaymentDetailView() {
    const history = useHistory();
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    return (
        <Container vh-100 position-relative py-3  >
            <VerticalCenter>
                Hola Mundo
            </VerticalCenter>
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />
        </Container>
    )
}