import { pedidos } from "../pedidos";
import { pedidos_detalle } from "../pedidos_detalle";
import { tipomodificadores } from "../tipomodificadores";
import { pedidos_detalle_modificadores } from "../pedidos_detalle_modificadores";
import { estaciones } from "../estaciones";
import { mesas } from "../mesas";
import { clientes } from "../clientes";
import { calcularIVA, undefToNumber } from "../../utils/helpers";
import { comandas } from "../comandas";
import { comandas_detalle } from "../comandas_detalle";
import { pedidos_detalle_movimientos } from "../pedidos_detalle_movimientos";
import { pedidos_detalle_modificadores_mov } from "../pedidos_detalle_modificadores_mov";
import { enuStatusComanda, enuStatusDocumento, enuStatusPedido, enuStatusPedidoDetalle, enuTipoMovimientoPedidoDetalle, enuTipoOrigenPedido, enuTipoPedidoDespacho, EnuTiposPrecio } from "../../utils/enums";
import SettingsApp from "./Settings";
import axios from "axios";
import { config } from "../../utils/configs";
import { tipomonedas } from "../tipomonedas";
import { usuarios } from "../usuarios";
//import { useLoginToken } from "../../contexts/login-context";

enum enuStatusEdicion {
    Ninguno,
    Nuevo,
    EditandoSinProcesar,
    EditandoProcesado,
    SumaSinProcesar,
    SumaProcesado,
    SumaDevuelto,
    DisminucionSinProcesar,
    DisminucionProcesado,
    DisminucionDevuelto,
    EliminarSinProcesar,
    EliminarProcesado,
    EliminarDevuelto,
    EditandoDevuelto
}
enum enuTipoItem {
    Ninguno,
    ItemNuevo,
    Item,
    ItemProcesado,
    ItemProcesadoconCopia,
    ItemSinProcesar,
    ItemSinProcesarConProcesado
}
export class ItemPedido extends pedidos_detalle {
    //new
    id_Comanda!: number;
    id_SectorImpresion!: number;
    MovimientosExtra!: pedidos_detalle_modificadores_mov[];
    MovimientosItem!: pedidos_detalle_movimientos[];
    Extras!: pedidos_detalle_modificadores[];
    TipoModificador?: tipomodificadores;
    extraActual?: pedidos_detalle_modificadores;
    Imagen: string = '';
    MontoTotalTipoModif: number = 0;
    MontoTotalExtras: number = 0;
    NroItemsExtras: number = 0;
    NroLineasExtra: number = 0;
    MontoTotalBaseExtras: number = 0;
    MontoTotalIVAExtras: number = 0;
    MontoTotalIVAItem: number = 0;
    MontoTotalBaseItem: number = 0;
    newCantidadTotal: number = 0;
    PuntosRating: number = 0;
    //public settingApp: SettingsApp | undefined;
    MonedasActuales?: tipomonedas[];
    TipoPrecioActual?: number = 0;

    constructor(setting: SettingsApp | undefined) {
        super();
        this.NroItem = 0;
        this.CantidadTotal = 0;
        this.CantidadDevolucion = 0;
        this.TasaIVA = 0;
        this.MontoPrecio = 0;
        this.MontoPrecioDivisa = 0;
        this.MontoTotalImpuesto = 0;
        this.MontoTotalBase = 0;
        this.MontoDescuento = 0;
        this.ValorDescuento = 0;
        this.PuntosAplicados = 0;
        this.TipoPrecioActual = setting?.TipoPrecioActual;
        this.MonedasActuales = setting?.MonedasActuales;
    }


    //update:06012022
    public esImprimirCorreccion: boolean = false;
    public esImprimirObservacion: boolean = false;

    private _MontoTotalItem: number = 0;
    public get MontoTotalItem(): number {
        return this._MontoTotalItem;
    }
    public set MontoTotalItem(v: number) { }

    private _MontoTotal: number = 0;
    public get MontoTotal(): number {
        this.calcularTotales();
        return this._MontoTotal;
    }
    public set MontoTotal(v: number) { }

    private _lastNroItemExtra: number = 0;
    private get lastNroItemExtra(): number {
        this._lastNroItemExtra = this.Extras.length + 1;
        return this._lastNroItemExtra;
    }

    calcularTotales() {
        this.calcularMontosExtras();
        let MI: number = (this.MontoPrecio ? +this.MontoPrecio : 0) * (this.CantidadTotal ? +this.CantidadTotal : 0);
        this.MontoTotalBaseItem = calcularIVA((this.TasaIVA ? +this.TasaIVA : 0), MI);
        this.MontoTotalIVAItem = MI - +this.MontoTotalBaseItem;
        this._MontoTotalItem = +this.MontoTotalBaseItem + +this.MontoTotalIVAItem;
        this.MontoTotalImpuesto = (this.Extras ? +this.MontoTotalIVAExtras : 0) + this.MontoTotalIVAItem;
        this.MontoTotalBase = (this.Extras ? +this.MontoTotalBaseExtras : 0) + +this.MontoTotalBaseItem;
        this._MontoTotal = +this.MontoTotalImpuesto + +this.MontoTotalBase;
    }
    addNewTipoModificador(tipomodificador: tipomodificadores): tipomodificadores {
        this.TipoModificador = tipomodificador;
        return this.TipoModificador;
    }
    removeTipoModificador() {
        if (this.TipoModificador) {
            this.MontoTotalExtras -= this.TipoModificador.MontoPrecioDivisa ? +this.TipoModificador.MontoPrecioDivisa : 0;
            this.TipoModificador = undefined;
        }
    }
    private editExtraMov(item: pedidos_detalle_modificadores, cantidad: number) {
        let mov: pedidos_detalle_modificadores_mov = new pedidos_detalle_modificadores_mov;
        let New: Boolean = false;
        if (!this.MovimientosExtra)
            this.MovimientosExtra = [];
        else
            mov = this.MovimientosExtra.find(x => x.id_Pedido_DetalleModificador == item.id_pedido_detalle) as pedidos_detalle_modificadores_mov;
        if (New) {
            mov.id_Pedido_DetalleModificador = item.id_pedido_detalle;
            mov.id_defTipoMovimientoPedido = enuTipoMovimientoPedidoDetalle.Agregar;
        }
        else
            mov.id_defTipoMovimientoPedido = enuTipoMovimientoPedidoDetalle.Modificar;
        mov.Cantidad = +cantidad;
        mov.Mensaje = item.Mensaje;
        mov.FechaActual = new Date();
        this.MovimientosExtra.push(mov);
    }
    private addNewExtra(extra: pedidos_detalle_modificadores): pedidos_detalle_modificadores | undefined {
        extra.NroItem = this.lastNroItemExtra;
        this.Extras.push(extra);
        this.extraActual = this.Extras.find(x => x.NroItem == extra.NroItem && x.ID == extra.ID);
        //Add movimiento extra
        return this.extraActual;
    }
    removeExtrasAll() {
        if (this.Extras) {
            this.Extras.length = 0;
            this.calcularMontosExtras();
            return null
        }
    }
    editExtra(extra: pedidos_detalle_modificadores): pedidos_detalle_modificadores | undefined {
        // if (extra.id_TipoMoneda && extra.id_TipoMoneda !== this.settingApp?.MonedaPrincipal?.ID)
        extra.MontoTasaDivisa = this.MonedasActuales ? this.MonedasActuales.find(y => y.ID === extra.id_TipoMoneda)?.Factor ?? 1 : 1;
        extra.id_defTipoPrecio = this.TipoPrecioActual;
        if (extra.id_defTipoPrecio === EnuTiposPrecio.precioDivisa) {
            if (extra.MontoPrecioDivisa && extra.MontoPrecioDivisa > 0) {
                extra.MontoPrecio = (extra.MontoPrecioDivisa ?? 0) * (extra.MontoTasaDivisa ?? 1);
            }
        } else {
            if (extra.MontoPrecio && extra.MontoPrecio > 0) {
                extra.MontoPrecioDivisa = (extra.MontoPrecio ?? 0) / (extra.MontoTasaDivisa ?? 1);
            }
        }

        if (!this.Extras) {
            this.Extras = [];
            this.extraActual = this.addNewExtra(extra);
        }
        else
            this.extraActual = this.Extras.find(x => x.NroItem == extra.NroItem && x.ID == extra.ID);

        if (!this.extraActual)
            this.addNewExtra(extra);
        else {
            if (this.extraActual)
                this.editExtraMov(this.extraActual as pedidos_detalle_modificadores, (extra.CantidadTotal ? +extra.CantidadTotal : 0));

            if (undefToNumber(this.extraActual!.CantidadTotal) <= 0) {
                this.removeExtra(this.extraActual?.NroItem);
                this.extraActual = undefined;
                return undefined;
            }
        }

        this.calcularMontosExtras();
        return this.extraActual;
    }
    removeExtra(nroItem: number | undefined): void {
        if (this.Extras) {
            this.Extras.splice(this.Extras.findIndex(x => x.NroItem == nroItem), 1);
            let i: number = 1;
            this.Extras.forEach(x => { x.NroItem = i; i++; })
            this.calcularMontosExtras();
        }
    }
    calcularMontosExtras(): void {
        if (this.Extras) {
            this.MontoTotalTipoModif = 0;
            this.MontoTotalExtras = 0;
            this.MontoTotalIVAExtras = 0;
            this.MontoTotalBaseExtras = 0;
            this.NroItemsExtras = 0;
            this.Extras?.forEach(x => {
                if (undefToNumber(x.CantidadTotal) > 0) {

                    x.MontoPrecio = undefToNumber(x.MontoPrecio);
                    x.MontoPrecioDivisa = undefToNumber(x.MontoPrecioDivisa);
                    x.TasaIVA = undefToNumber(x.TasaIVA);
                    x.MontoTotalBase = undefToNumber(x.MontoTotalBase);
                    x.MontoTotalImpuesto = undefToNumber(x.MontoTotalImpuesto);

                    let MontoTotal: number = +x.MontoPrecioDivisa * undefToNumber(x.CantidadTotal);
                    x.MontoTotalBase = calcularIVA(+x.TasaIVA ?? 0, +MontoTotal);
                    x.MontoTotalImpuesto = +MontoTotal - +x.MontoTotalBase;
                    this.MontoTotalExtras += +MontoTotal;
                    this.MontoTotalIVAExtras += +x.MontoTotalImpuesto;
                    this.MontoTotalBaseExtras += +x.MontoTotalBase;
                    this.NroItemsExtras += undefToNumber(x.CantidadTotal);
                }
            });
            this.NroLineasExtra = this.Extras?.length;
        }
        if (this.TipoModificador) {
            this.MontoTotalTipoModif = this.TipoModificador.MontoPrecioDivisa ? +this.TipoModificador.MontoPrecioDivisa : 0;
            //this.MontoTotalExtras += this.TipoModificador.MontoPrecioDivisa;
        }
    }
}
export default class Orden extends pedidos {
    //update:06012022
    public esImprimirEmpleado: boolean = false;
    public esImprimirRefPedido: boolean = false;
    public esImprimirRefFactura: boolean = false;
    public esImprimirNombreCaja: boolean = false;
    public lblRefPedido: string = "";
    public lblEmpleado: string = "";
    public lblNombreCaja: string = "";
    public lblRefFactura: string = "";
    public settingApp: SettingsApp | undefined;

    public loginToken: any;
    public Items!: ItemPedido[];
    public Estacion: estaciones | undefined;
    public Mesa: mesas | undefined;
    public Mesero: usuarios | undefined;
    public Cliente: clientes | undefined;
    public MontoTotalItems: number = 0;
    public MontoTotalExtras: number = 0;
    //Servicio
    public TasaServicio: number = 10;
    public MontoTotalServicio: number = 0;
    public MontoTotalConServicio: number = 0;
    public esIncluyeServicio: boolean = false;
    // 
    public MontoTotalItemsDivisa: number = 0;
    public MontoTotalExtrasDivisa: number = 0;
    public MontoTotalServicioDivisa: number = 0;
    public MontoTotalConServicioDivisa: number = 0;
    public MontoTasaDivisaActual: number = 0;
    //Comandas
    private ComandaActual!: comandas;
    private MovimientoActual!: pedidos_detalle_movimientos;
    public itemActual!: ItemPedido;
    nroLineas: number = 0;
    savingData: boolean = false;
    private _lastNroItem: number = 0;
    public get lastNroItem(): number {
        this._lastNroItem = this.Items.length + 1;
        return this._lastNroItem;
    }
    private _MontoTotal: number = 0;
    public get getMontoTotal(): number {
        this.calcularMontos();
        return this._MontoTotal;
    }
    async initOrden(setting: SettingsApp, tipoDespacho: enuTipoPedidoDespacho, status: enuStatusPedido, origenPedido: enuTipoOrigenPedido, cliente: clientes | undefined = undefined) {
        if (setting.CajaActual) {
            this.Id_Caja_caja = setting.CajaActual;
            this.Id_Caja = this.Id_Caja_caja ? this.Id_Caja_caja.ID : 0;
            this.FechaPedido = this.Id_Caja_caja ? this.Id_Caja_caja.FechaAperturaUltima : new Date;
        }
        if (setting.UsuarioActual) {
            this.Id_Usuario_usuario = setting.UsuarioActual;
            this.Id_Usuario = setting.UsuarioActual ? setting.UsuarioActual.ID : 1;
            this.Mesero = setting.UsuarioActual;
        } else
            this.Id_Usuario = setting.EmpleadoActual ? setting.EmpleadoActual.ID : 1;

        if (cliente)
            this.Id_Cliente_cliente = cliente;
        this.Estacion = setting.EstacionActual;
        this.Mesa = setting.MesaActual;
        this.FechaPedido = setting.CajaActual ? setting.CajaActual.FechaAperturaUltima : new Date;
        this.Id_Estaciones_Sesiones = setting.SesionActual ? setting.SesionActual.ID : 1;
        //Valores de Carga
        this.Id_TipoDespachoPedido = tipoDespacho;
        this.id_defTipoSatusPedido = status;
        this.id_defTipoOrigenPedido = origenPedido;
        this.id_defTipoPedido = tipoDespacho;
        this.esImprimirEmpleado = setting.esImprimirEmpleado;
        this.esImprimirRefPedido = setting.esImprimirRefPedido;
        this.esImprimirRefFactura = setting.esImprimirRefFactura;
        this.esImprimirNombreCaja = setting.esImprimirNombreCaja;
        this.lblRefPedido = setting.lblRefPedido;
        this.lblEmpleado = setting.lblEmpleado;
        this.lblNombreCaja = setting.lblNombreCaja;
        this.lblRefFactura = setting.lblRefFactura;
        this.id_defTipoPrecio = setting.TipoPrecioActual;
        this.Id_MonedaDivisa = setting.EstacionActual?.id_MonedaDivisa ?? 1;
        //Cargar tasa cambio
        this.MontoTasaDivisa = setting.MonedasActuales.find(x => x.ID)?.Factor ?? 1;

        this.settingApp = setting;
        /* let res = await axios.post(config.SERVER_URI + '/tipomonedas/get', { data: { id: this.Id_MonedaDivisa } })
        if (res.status == 200) {
            this.MontoTasaDivisa = res.data.Factor;
        } else {
            this.MontoTasaDivisa = 1;
        } */
    }

    constructor() {
        super();
        this.FechaActual = new Date;
        this.MontoTotal = 0;
        this.MontoTotalBase = 0;
        this.MontoTotalIVA = 0;
        this.MontoTotalDescuento = 0;
        this.MontoCambio = 0;
        this.MontoPagado = 0;
        this.MontoPropina = 0;
        this.NroItems = 0;
        this.NroPedido = 0;
        this.RefPedido = "";
        this.Comentarios = "";
        this.Mensaje = "";
        this.CantidadImpreso = 0;
        this.esTieneDevolucion = 0;
        this.esPagado = 0;
        this.esDespachado = 0;
        this.esImpreso = 0;
        this.Historico = "";
        this.MontoTotalItems = 0;
        this.MontoTotalExtras = 0;
    }


    editPedido(pedido: pedidos) {
        this.FechaActual = pedido?.FechaActual;
        this.FechaPedido = pedido?.FechaPedido;
        this.calcularMontos();
    }
    private addItemMov(item: pedidos_detalle) {
        let mov: pedidos_detalle_movimientos = new pedidos_detalle_movimientos;
        mov.id_defTipoMovimientoPedido = enuTipoMovimientoPedidoDetalle.Agregar;
        mov.id_defTipoStatusPedido = enuStatusPedidoDetalle.SinProcesar;
        mov.Cantidad = undefToNumber(item.CantidadTotal);
        mov.Mensaje = item.Mensaje;
        mov.FechaActual = new Date();
        mov.esDevolucion = 0;
        mov.esImpreso = 0;
        mov.esDespachado = 0;
        mov.id_Usuario = this.Id_Usuario;
        // mov.id_EstacionesSesiones
        this.itemActual?.MovimientosItem.push(mov);
        //this.Items.find(x => x.NroItem == item.NroItem && x.ID == item.ID) as ItemPedido;
        this.MovimientoActual = mov;
    }
    private editItemMov(item: pedidos_detalle) {
        if (this.itemActual && !this.itemActual?.MovimientosItem) {
            this.itemActual.MovimientosItem = [];
            this.addItemMov(item);
        }
        else
            if (item.ID)
                this.MovimientoActual = this.itemActual.MovimientosItem.find(x => x.id_Pedido_Detalle == item.ID) as pedidos_detalle_movimientos;
            else
                this.MovimientoActual = this.itemActual.MovimientosItem.find(x => x.id_defTipoStatusPedido == enuStatusPedidoDetalle.SinProcesar) as pedidos_detalle_movimientos;
        if (!this.MovimientoActual) {
            this.addItemMov(item);
        } else {
            switch (this.MovimientoActual.id_defTipoStatusPedido) {
                case enuStatusPedidoDetalle.SinProcesar:
                    this.MovimientoActual.Cantidad = undefToNumber(item.CantidadTotal);
                    break;
                case enuStatusPedidoDetalle.DespachoCocina:
                case enuStatusPedidoDetalle.PedidoNuevo:
                case enuStatusPedidoDetalle.Procesado:
                case enuStatusPedidoDetalle.ProcesadoDevuelto:
                case enuStatusPedidoDetalle.Recibido:
                case enuStatusPedidoDetalle.RecibidoCocina:
                case enuStatusPedidoDetalle.RecibidoDevuelto:
                    this.MovimientoActual.id_defTipoMovimientoPedido = enuTipoMovimientoPedidoDetalle.Modificar;
                    if (this.MovimientoActual?.Cantidad as number < 0) {
                        if (this.itemActual.CantidadTotal == 0)
                            this.MovimientoActual.id_defTipoMovimientoPedido = enuTipoMovimientoPedidoDetalle.Modificar;
                        this.MovimientoActual.esDevolucion = 1;
                    }
                    break;
            }
            this.MovimientoActual.FechaActual = new Date();
            this.MovimientoActual.Mensaje = item.Mensaje;
        }
        this.editComanda(this.MovimientoActual);
    }
    private addComanda(item: pedidos_detalle_movimientos) {
        let comanda: comandas = new comandas;
        comanda.id_defTipoStatusDoc = enuStatusDocumento.enProceso;
        comanda.id_defStatusComanda = enuStatusComanda.SinProcesar;
        comanda.FechaActual = new Date();
        comanda.Mensaje = "";
        comanda.id_Usuario = item.id_Usuario;
        comanda.esImpreso = 0;
        comanda.esDespachado = 0;
        comanda.esImpreso = 0;
        comanda.esDespachado = 0;
        comanda.txtComanda = this.itemActual.Descripcion;
        comanda.nroComanda = this.itemActual.id_SectorImpresion;
        comanda.id_Sector_Impresion = 0;
        /*  FechaAtendida ?: Date;
         FechaImpreso ?: Date;
         id_Sector_Impresion ?: number; */
        comanda.comandas_detalles = [];
        let comdet: comandas_detalle = new comandas_detalle;
        comdet.id_Producto = this.itemActual.Id_Producto;
        comdet.id_defTipoStatusPedido = enuStatusPedidoDetalle.SinProcesar;
        comdet.Cantidad = item.Cantidad;
        comdet.Mensaje = item.Mensaje;
        comdet.esDespachado = 0;
        comdet.esImpreso = 0;
        comdet.esDevolucion = 0;
        // comdet.id_Modificador = number;
        comanda.comandas_detalles.push(comdet);
        this.MovimientoActual.comandas.push(comanda);
        this.ComandaActual = comanda;
    }
    private editComanda(item: pedidos_detalle_movimientos) {
        if (!this.MovimientoActual.comandas) {
            this.MovimientoActual.comandas = [];
            this.addComanda(item);
        }
        else {
            if (this.MovimientoActual.id_defTipoStatusPedido == enuStatusPedidoDetalle.SinProcesar) {
                /*  let lastIndex = this.MovimientoActual.comandas.map(com => com.id_defStatusComanda === enuStatusComanda.SinProcesar).lastIndexOf(true);
                 this.ComandaActual = this.MovimientoActual.comandas[lastIndex]; */
                this.ComandaActual = this.MovimientoActual.comandas[0];
                this.ComandaActual.FechaActual = new Date();
                this.ComandaActual.id_Usuario = item.id_Usuario;
                let comdet = this.MovimientoActual.comandas[0].comandas_detalles[0];
                comdet.Cantidad = +item.Cantidad;
                comdet.Mensaje = item.Mensaje;
                if (item.Cantidad < 0)
                    comdet.esDevolucion = 0;
            } else {
                this.addComanda(item);
            }
        }
    }
    private addNewItem(item: ItemPedido): ItemPedido {
        item.NroItem = this.lastNroItem;
        item.id_defTipoStatusPedido = enuStatusPedidoDetalle.SinProcesar;
        if (item.Extras && item.Extras.length) {
            item.Extras.forEach(e => {
                this.editExtra(item, e);
            });
        };
        this.itemActual = item;
        this.Items.push(item);
        item.calcularMontosExtras()
        item.calcularTotales();
        this.calcularMontos();
        return this.itemActual;
    }
    buscarItem(element: number, index: any, array: any) {
        let start = 2;
        while (start <= Math.sqrt(element)) {
            if (element % start++ < 1) {
                return false;
            }
        }
        return element > 1;
    }
    setTmpitem(item: ItemPedido, cantidad: number): ItemPedido {
        let tmpIP: ItemPedido = new ItemPedido(this.settingApp);
        tmpIP.Extras = item.Extras;
        tmpIP.Id_Producto = item.Id_Producto;
        tmpIP.id_ProductoPromocion = item.id_ProductoPromocion;
        tmpIP.id_TipoPromocion = item.id_TipoPromocion;
        tmpIP.id_TipoOferta = item.id_TipoOferta;
        tmpIP.id_defTipoPrecio = item.id_defTipoPrecio;
        tmpIP.Descripcion = item.Descripcion;
        tmpIP.Imagen = item.Imagen;
        tmpIP.CantidadTotal = +cantidad;
        tmpIP.CantidadDevolucion = 0;
        tmpIP.TasaIVA = undefToNumber(item.TasaIVA);
        tmpIP.MontoPrecio = undefToNumber(item.MontoPrecio);
        tmpIP.MontoPrecioDivisa = undefToNumber(item.MontoPrecioDivisa);
        tmpIP.MontoTotalImpuesto = undefToNumber(item.MontoTotalImpuesto);
        tmpIP.MontoTotalBase = undefToNumber(item.MontoTotalBase);
        tmpIP.MontoDescuento = undefToNumber(item.MontoDescuento);
        tmpIP.ValorDescuento = undefToNumber(item.ValorDescuento);
        tmpIP.PuntosAplicados = undefToNumber(item.PuntosAplicados);
        tmpIP.Comentario = item.Comentario;
        tmpIP.HistoricoDetalle = item.HistoricoDetalle;
        tmpIP.Mensaje = item.Mensaje;
        tmpIP.FechaActual = new Date();
        tmpIP.esDevolucion = 0;
        tmpIP.esImpreso = 0;
        tmpIP.esDespachado = 0;
        tmpIP.id_TipoImpuesto = item.id_TipoImpuesto;
        return tmpIP;
    }
    buscando() {

    }
    editItem(item: ItemPedido, esnew: boolean = false): ItemPedido | undefined {
        item.MontoTasaDivisa = this.settingApp?.MonedasActuales.find(y => y.ID === item.id_TipoMoneda)?.Factor ?? 1;
        item.id_defTipoPrecio = this.settingApp?.TipoPrecioActual;
        if (item.MontoPrecioDivisa && item.MontoPrecioDivisa > 0) {
            item.MontoPrecio = (item.MontoPrecioDivisa ?? 0) * (item.MontoTasaDivisa ?? 1);
        }
        else if (item.MontoPrecio && item.MontoPrecio > 0) {
            item.MontoPrecioDivisa = (item.MontoPrecio ?? 0) / (item.MontoTasaDivisa ?? 1);
        }
        if (item.id_defTipoPrecio === EnuTiposPrecio.precioDivisa) {

        }

        if (!this.Items) {
            this.Items = [];
            this.addNewItem(item);
            return this.editItem(item, true);
        }
        let enStatusEditando: enuStatusEdicion = 0;
        let diferencia: number = 0
        let foundItemProcesado!: ItemPedido;
        let foundItemSinProcesar!: ItemPedido;
        let enTipoItemEditando: enuTipoItem = 0;
        let esSuma: boolean = true;
        let esELiminar: boolean = false

        const buscar = (x: ItemPedido) => {
            if (item.id_defTipoStatusPedido == x.id_defTipoStatusPedido) {
                switch (item.id_defTipoStatusPedido) {
                    case enuStatusPedidoDetalle.DespachoCocina:
                    case enuStatusPedidoDetalle.Recibido:
                    case enuStatusPedidoDetalle.RecibidoCocina:
                    case enuStatusPedidoDetalle.Procesado:
                        enStatusEditando = enuStatusEdicion.EditandoProcesado;
                        foundItemProcesado = x;
                        foundItemSinProcesar = this.Items
                            .filter(f => f.Id_Producto ? f.Id_Producto == item.Id_Producto : f.id_ProductoPromocion ? f.id_ProductoPromocion == item.id_ProductoPromocion : 0)
                            .find(y => !y.ID) as ItemPedido;
                        if (foundItemSinProcesar)
                            enTipoItemEditando = enuTipoItem.ItemProcesadoconCopia;
                        else
                            enTipoItemEditando = enuTipoItem.ItemProcesado;
                        return;
                    case enuStatusPedidoDetalle.SinProcesar:
                    case enuStatusPedidoDetalle.PedidoNuevo:
                        if (!foundItemProcesado) {
                            enStatusEditando = enuStatusEdicion.EditandoSinProcesar;
                            foundItemSinProcesar = x;
                            foundItemProcesado = this.Items
                                .filter(f => f.Id_Producto ? f.Id_Producto == item.Id_Producto : f.id_ProductoPromocion ? f.id_ProductoPromocion == item.id_ProductoPromocion : 0)
                                .find(x => x.ID && x.NroItem) as ItemPedido;
                            if (foundItemProcesado)
                                enTipoItemEditando = enuTipoItem.ItemSinProcesarConProcesado;
                            else
                                enTipoItemEditando = enuTipoItem.ItemSinProcesar;
                        }
                        return;
                    case enuStatusPedidoDetalle.ProcesadoDevuelto:
                    case enuStatusPedidoDetalle.RecibidoDevuelto:
                        enStatusEditando = enuStatusEdicion.EditandoDevuelto; foundItemProcesado = x;
                        foundItemProcesado = x;
                        foundItemSinProcesar = this.Items
                            .filter(f => f.Id_Producto ? f.Id_Producto == item.Id_Producto : f.id_ProductoPromocion ? f.id_ProductoPromocion == item.id_ProductoPromocion : 0)
                            .find(x => !x.ID) as ItemPedido;
                        if (foundItemSinProcesar)
                            enTipoItemEditando = enuTipoItem.ItemProcesadoconCopia;
                        else
                            enTipoItemEditando = enuTipoItem.ItemProcesado;
                        return;
                }
            }
        };

        this.Items
            .filter(f => f.Id_Producto ? f.Id_Producto == item.Id_Producto : f.id_ProductoPromocion ? f.id_ProductoPromocion == item.id_ProductoPromocion : 0)
            .find(buscar);
        if (!foundItemSinProcesar && !foundItemProcesado)
            enTipoItemEditando = enuTipoItem.ItemNuevo
        if ((foundItemSinProcesar || foundItemProcesado) && (item.NroItem === 0 && item.Extras))
            enTipoItemEditando = enuTipoItem.ItemNuevo

        switch (enTipoItemEditando) {
            case enuTipoItem.ItemProcesado:
                if (undefToNumber(foundItemProcesado.CantidadTotal) > foundItemProcesado.newCantidadTotal) {
                    esSuma = false;
                    if (foundItemProcesado.newCantidadTotal == 0) { esELiminar = true; }
                }
                else
                    esSuma = true;
                diferencia = item.newCantidadTotal;
                this.addNewItem(this.setTmpitem(item, diferencia));
                return this.itemActual;
            case enuTipoItem.ItemProcesadoconCopia:
                if (item.newCantidadTotal > undefToNumber(foundItemProcesado.CantidadTotal)) {
                    esSuma = true;
                    diferencia = 1;
                } else if (item.newCantidadTotal > 0 && item.newCantidadTotal < undefToNumber(foundItemProcesado.CantidadTotal)) {
                    esSuma = false;
                    diferencia = 1;
                } else if (item.newCantidadTotal == 0 && undefToNumber(foundItemProcesado.CantidadTotal) > 0) {
                    esELiminar = true;
                    esSuma = false;
                    diferencia = 1;
                }
                break;
            case enuTipoItem.ItemSinProcesar:
            case enuTipoItem.ItemSinProcesarConProcesado:
                if (!item.NroItem) {
                    esSuma = true;
                    diferencia = item.newCantidadTotal;
                } else {
                    if (item.newCantidadTotal > undefToNumber(foundItemSinProcesar.CantidadTotal)) {
                        esSuma = true;
                        diferencia = +item.newCantidadTotal - undefToNumber(foundItemSinProcesar.CantidadTotal);
                    } else if (item.newCantidadTotal > 0 && item.newCantidadTotal < undefToNumber(foundItemSinProcesar.CantidadTotal)) {
                        esSuma = false;
                        diferencia = (undefToNumber(foundItemSinProcesar.CantidadTotal) - +item.newCantidadTotal);
                    } else if (item.newCantidadTotal == 0 && undefToNumber(foundItemSinProcesar.CantidadTotal) > 0) {
                        esELiminar = true;
                        esSuma = false;
                        diferencia = undefToNumber(item.CantidadTotal);
                    }
                }
                break;
            case enuTipoItem.ItemNuevo:
                esSuma = true;
                diferencia = +item.newCantidadTotal;
                this.addNewItem(this.setTmpitem(item, diferencia));
                return this.itemActual;
            default:
                break;
        }

        switch (enStatusEditando) {
            case enuStatusEdicion.EditandoDevuelto:
                if (esSuma) {
                    enStatusEditando = enuStatusEdicion.SumaDevuelto;
                } else {
                    enStatusEditando = enuStatusEdicion.DisminucionDevuelto;
                    if (esELiminar)
                        enStatusEditando = enuStatusEdicion.EliminarDevuelto;
                }
                break;
            case enuStatusEdicion.EditandoProcesado:
                if (esSuma) {
                    enStatusEditando = enuStatusEdicion.SumaProcesado;
                    if (foundItemProcesado)
                        enStatusEditando = enuStatusEdicion.SumaSinProcesar;
                } else {
                    enStatusEditando = enuStatusEdicion.DisminucionProcesado;
                    if (esELiminar)
                        enStatusEditando = enuStatusEdicion.EliminarProcesado;
                }
                break;
            case enuStatusEdicion.EditandoSinProcesar:
                if (esSuma) {
                    enStatusEditando = enuStatusEdicion.SumaSinProcesar;
                } else {
                    enStatusEditando = enuStatusEdicion.DisminucionSinProcesar;
                    if (esELiminar)
                        enStatusEditando = enuStatusEdicion.EliminarSinProcesar;
                }
                break;
        }
        switch (enStatusEditando) {
            case enuStatusEdicion.SumaDevuelto:
                this.addNewItem(item);
                return;
            case enuStatusEdicion.SumaProcesado:
                foundItemSinProcesar.CantidadTotal = undefToNumber(foundItemSinProcesar.CantidadTotal) + 1;
                break;
            case enuStatusEdicion.SumaSinProcesar:
                if (foundItemSinProcesar)
                    foundItemSinProcesar.CantidadTotal = undefToNumber(foundItemSinProcesar.CantidadTotal) + diferencia;
                break;
            case enuStatusEdicion.DisminucionDevuelto:
                //no existe funcion
                break;
            case enuStatusEdicion.DisminucionProcesado:
                alert("Necesita Autorizacion (DISMINUIR PROCESADO)")
                return;
            case enuStatusEdicion.DisminucionSinProcesar:
                foundItemSinProcesar.CantidadTotal = undefToNumber(foundItemSinProcesar.CantidadTotal) - diferencia;
                break;
            case enuStatusEdicion.EliminarDevuelto:
                //Sin Funcion
                break;
            case enuStatusEdicion.EliminarProcesado:
                alert("Necesita Autorizacion (ELIMINAR PROCESADO)")
                return;
            case enuStatusEdicion.EliminarSinProcesar:
                foundItemSinProcesar.CantidadTotal = 0;
                foundItemSinProcesar.removeExtrasAll();
                /*  if (foundItemSinProcesar.Extras) {
                     foundItemSinProcesar.Extras.forEach(ex => {
                         ex.CantidadTotal = 0;
                         foundItemSinProcesar.editExtra(ex);
                     });
                 } */
                break;
            default:
                alert("Estatus Desconocido");
                break;
        }
        foundItemSinProcesar.calcularTotales();
        this.itemActual = foundItemSinProcesar;
        this.editItemMov(foundItemSinProcesar);
        this.calcularMontos();
        return this.itemActual;


    }
    editExtra(item: ItemPedido, extra: pedidos_detalle_modificadores): pedidos_detalle_modificadores | undefined {
        if (!this.Items) {
            this.editItem(item);
        }
        this.itemActual = this.Items.find(x => x.NroItem == item.NroItem && x.ID == item.ID) as ItemPedido;
        if (this.itemActual) {
            /*  if ((item.Extras && item.Extras.length > 0) && (foundSinProcesar.Extras && foundSinProcesar.Extras.length > 0)) {
                 //Tienen Extras
                 if (item.Extras !== foundSinProcesar.Extras) {
                     //Diferencia de Extras
 
                 } else {
                     //Mismos extras
                 }
             } else {
                 //SIn Extras
 
             } */
            this.itemActual.editExtra(extra);
        }
        this.calcularMontos();
        return this.itemActual?.extraActual;
    }
    removeExtrasItemActual() {
        if (this.itemActual)
            this.itemActual.removeExtrasAll();
        this.calcularMontos();
    }
    editTipoModificador(tipo?: tipomodificadores) {
        if (tipo == null) {
            if (this.itemActual)
                this.itemActual.removeTipoModificador();
        }
        else
            if (this.itemActual)
                this.itemActual.addNewTipoModificador(tipo);
        this.calcularMontos();
    }
    removeItemsAll() {
        if (this.Items) {
            this.Items.length = 0;
            this.Items = [];
            this.calcularMontos();
        }
    }
    private removeItem(nroItem: number | undefined) {
        if (this.Items) {
            this.Items.splice(this.Items.findIndex(z => z.NroItem == nroItem), 1);
            let i: number = 1;
            this.Items.forEach(x => { x.NroItem = i; i++; })
            this.calcularMontos();
        }
    }
    calcularMontos() {
        this.MontoTotalItems = 0;
        this.MontoTotalExtras = 0;
        this.MontoTotal = 0;
        this.MontoTotalIVA = 0;
        this.MontoTotalBase = 0;
        this.nroLineas = 0;
        this.NroItems = 0;

        this.MontoCambioDivisa = 0;
        this.MontoPagadoDivisa = 0;
        this.MontoPropinaDivisa = 0;
        this.MontoTotalBaseDivisa = 0;
        this.MontoTotalDivisa = 0;
        this.MontoTotalIVADivisa = 0;

        this.Items?.forEach(x => {
            x.calcularTotales();

            this.MontoTotalItemsDivisa += undefToNumber(x.MontoTotalItem);
            this.MontoTotalExtrasDivisa += undefToNumber(x.MontoTotalExtras / (x.MontoTasaDivisa ?? 1));
            if (this.MontoTotalIVADivisa !== undefined)
                this.MontoTotalIVADivisa += undefToNumber((x.MontoTotalImpuesto ?? 0) / (x.MontoTasaDivisa ?? 1));
            if (this.MontoTotalBaseDivisa !== undefined)
                this.MontoTotalBaseDivisa += undefToNumber((x.MontoTotalBase ?? 0) / (x.MontoTasaDivisa ?? 1));

            this.MontoTotalItems += undefToNumber(x.MontoTotalItem);
            this.MontoTotalExtras += undefToNumber(x.MontoTotalExtras);
            if (this.MontoTotalIVA !== undefined)
                this.MontoTotalIVA += undefToNumber(x.MontoTotalImpuesto);
            if (this.MontoTotalBase !== undefined)
                this.MontoTotalBase += undefToNumber(x.MontoTotalBase);

            if (this.NroItems !== undefined)
                this.NroItems += undefToNumber(x.CantidadTotal);
        });
        this.MontoTotalDivisa = +this.MontoTotalBaseDivisa + +this.MontoTotalIVADivisa;
        this.MontoTotal = +this.MontoTotalBase + +this.MontoTotalIVA;
        if (this.TasaServicio > 0 && this.esIncluyeServicio) {
            this.MontoTotalServicio = +this.MontoTotal * (1 + (+this.TasaServicio / 100));
            this.MontoTotalConServicio = +this.MontoTotal + +this.MontoTotalConServicio;
        }
        let servicioDivisa: number = (this.esIncluyeServicio ? +this.MontoTotalServicio : 0) / (this.MontoTasaDivisa ?? 1);
        this.MontoTotalDivisa = this.MontoTotalDivisa + servicioDivisa;
        this._MontoTotal = this.MontoTotal + (this.esIncluyeServicio ? +this.MontoTotalServicio : 0);
        this.nroLineas = this.Items?.length;
    }
}


