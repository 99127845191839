import { Deck, LocalPrintshop, PointOfSale, RememberMe, RemoveShoppingCart } from "@mui/icons-material";
import { Box, Grid, FormControl, Button, InputLabel, Select, OutlinedInput, MenuItem, Typography, Container, Theme, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import SimpleToast from "../../../components/simple-toast";
import { mesas } from "../../../models/mesas";
import { ColorsDPana } from "../../../utils/Color";
import ModalDialog from "../../dialogs/modal-dialog";
import SimpleDialog from "../../dialogs/simple-dialog";
import { makeStyles, } from '@mui/styles';
import { usuarios } from "../../../models/usuarios";
import { useStateBasedOnProps } from "../../../utils/helpers";
import SelectMesero from "./select-mesero";
import { dialogResponse } from "../../../utils/models";
import { pedidos } from "../../../models/pedidos";
import { formatNumber } from "fast-number-formatter";

interface Props {
    handleResponse?: any;
    titulo?: string;
    actionMethod?: any;
    openModal?: boolean;
    List?: mesas[];
    ListMeseros?: usuarios[];
    handleChange?: any;
    esOK?: boolean;
    setMesaSelected?: any;
    setMeseroSelected?: any;
    meseroSelected?: usuarios;
    mesaSelected?: mesas;
    setOpen?: any;
}
const useStyles = makeStyles((theme: Theme) => ({
    boxWrapper: {
        minHeight: "calc(20vh + 150px)"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    }
}));
export default function SelectMesa(props: Props) {
    const classes = useStyles();
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [TitleMDialog, setTitleMDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [message, setMessage] = useState("");
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    const [idSelected, setIdSelected] = useStateBasedOnProps(props.mesaSelected ? props.mesaSelected.ID : 0);
    const [selected, setSelected] = useStateBasedOnProps(props.mesaSelected)
    const [selectedMesero, setSelectedMesero] = useStateBasedOnProps(props.meseroSelected)
    const [sDialogOptions, setsDialogOptions] = useState<any>();
    const [pedido, setPedido] = useState<pedidos>();
    const [cambiarMesero, setCambiarMesero] = useState(false);
    const loginDialogRes = (e: boolean, actionMethod: any) => {
        /*  if (!e)
             setMessage("Acceso no Autorizado!")
         if (actionMethod)
             actionMethod();
         setOpenSDialog(!e); */
    }
    const loadLoginDialog = (titulo: string) => {
        setMessage("inf-->Espere Mientras Iniciamos Sesión.");
        /* 
                if (props.setMesaSelected)
                    props.setMesaSelected(undefined);
                setSelected(undefined); */
        setChildrenMDialog(<SelectMesero
            meseroSelected={undefined}
            setMeseroSelected={setSelectedMesero}
            List={props.ListMeseros}
            esOK={props.esOK}
            setOpen={setopenMDIalog}
            handleResponse={loginDialogRes}
            titulo={titulo} />);
        setTimeout(() => {
            setopenMDIalog(true);
        }, 2500);
    }
    const handleChangeMesa = (event: SelectChangeEvent<typeof idSelected>) => {
        if (event.target.value) {
            setIdSelected(Number(event.target.value));
        }
        else
            setIdSelected(0);
    };
    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    };
    const dialogResponse = (e: any) => {
        setOpenSDialog(false);

    }
    const handleOK = (res: dialogResponse) => {
        switch (res) {
            case 'OK':
                props.handleResponse(res, selected, selectedMesero);
                break;
            case 'No':
                props.handleResponse(res, selected);
                break;

            default:
                break;
        }
        if (props.setOpen)
            props.setOpen(false);

    }
    const selectMesero = () => {
        setCambiarMesero(true);
        loadLoginDialog('Indique Nuevo Usuario');
    }
    const selectMesa = () => {
        let m: mesas | undefined = props.List ? props.List.find(x => x.ID == idSelected) : undefined;
        if (m && idSelected > 0) {
            if (m && m.mesas_detalles && m.mesas_detalles.length > 0) {
                if (selectedMesero) {
                    if (
                        cambiarMesero ||
                        (selectedMesero.ID == m.mesas_detalles[0].id_UsuarioApertura) ||
                        (selectedMesero.ID == m.mesas_detalles[0].id_UsuarioUltimoAcceso)
                    ) {
                        setSelected(m);
                    } else {
                        setSelected(undefined);
                        loadLoginDialog(`Cambiar a ${m.mesas_detalles[0].id_UsuarioUltimoAcceso_usuario.Nombre}`);
                    }
                } else {
                    setSelected(undefined);
                    loadLoginDialog(`Cambiar a ${m.mesas_detalles[0].id_UsuarioUltimoAcceso_usuario.Nombre}`);
                }
            } else {
                setSelected(m);
                if (!selectedMesero)
                    loadLoginDialog('Indique Nuevo Usuario');
                setPedido(undefined);
            }
            setCambiarMesero(false);
        } else {
            setSelected(undefined);
        }
    }
    useEffect(() => {
        if (selected) {
            if (props.setMesaSelected) {
                props.setMesaSelected(selected);
            }
            if (selected?.mesas_detalles?.length && selected.mesas_detalles[0].id_Pedido_pedido)
                setPedido(selected.mesas_detalles[0].id_Pedido_pedido);
        } else {
            setPedido(undefined);
        }

    }, [selected])
    useEffect(() => {
        if (selectedMesero && idSelected) {
            selectMesa();
            if (!cambiarMesero)
                props.setMeseroSelected(selectedMesero);
        }
    }, [selectedMesero])
    useEffect(() => {
        if (idSelected) {
            selectMesa();
        } else {
            setSelected(undefined);
        }
    }, [idSelected])
    useEffect(() => {
        // setSelected(undefined);
    }, [])
    //#endregion
    return (
        <>
            <Box component="main" className={classes.boxWrapper + ' bg-body my-1 p-1'}>
                <Container maxWidth="md" className={classes.container + ' m-0 p-0'}>
                    <Grid
                        className="p-0 m-1"
                        container
                        xs={12} md={12}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}>
                        <Typography className="text-center w-100 alert-dark rounded-3" variant="subtitle1">
                            {props.titulo}
                        </Typography>
                        <Grid
                            className="px-0 "
                            item
                            container
                            xs={12} md={12}
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                            <Deck fontSize='large' />
                        </Grid>
                        <Grid
                            className="px-0"
                            item
                            container
                            xs={12} md={12}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                <InputLabel id="mesa-label">Seleccionar Mesa</InputLabel>
                                <Select
                                    labelId="mesa-label"
                                    id="mesa-select"
                                    value={idSelected}
                                    onChange={handleChangeMesa}
                                    input={<OutlinedInput label="Seleccionar Mesa" />}
                                >
                                    <MenuItem className='px-1' key={'0'} value={0}>
                                        <Grid
                                            className="px-1 m-0 alert-dark"
                                            item
                                            container
                                            xs={12} md={12}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center">
                                            <Typography sx={{ p: 0 }} variant='subtitle1' > {'NINGUNA'} </Typography>
                                        </Grid>
                                    </MenuItem>
                                    {props.List
                                        ? props.List.map((e, index) => {
                                            return (
                                                <MenuItem disabled={e.esModificando ? true : false} className='px-1' key={index} value={e.ID}>{
                                                    e.mesas_detalles && e.mesas_detalles.length > 0
                                                        ? <Grid
                                                            className="px-1 m-0 alert-success"
                                                            item
                                                            container
                                                            xs={12} md={12}
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center">
                                                            <Typography variant='subtitle1' >{e.Nombre} </Typography>
                                                            <Typography variant='caption' > {e.mesas_detalles[0].id_UsuarioUltimoAcceso_usuario?.Nombre} </Typography>
                                                        </Grid>
                                                        : <Grid
                                                            className="px-1 m-0 alert-light"
                                                            item
                                                            container
                                                            xs={12} md={12}
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center">
                                                            <Typography variant='subtitle2' >{e.Nombre} </Typography>
                                                        </Grid>
                                                }</MenuItem>
                                            )
                                        })
                                        : ""}
                                </Select>
                            </FormControl>
                        </Grid>
                        {selected && pedido
                            ?
                            <Grid
                                container
                                className="m-0 p-1 h-100 bg-transparent"
                                direction="column"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid
                                    className="m-0 p-0 bg-transparent"
                                    item
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Grid
                                        className="m-0 px-1 alert-dark  w-100"
                                        item
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography className="text-center  rounded-3" variant="subtitle1">
                                            {`Mesa: (${selected.Nombre?.toUpperCase()})`}
                                        </Typography>
                                        <Typography className="text-centerrounded-3" component='b' variant="h6">
                                            {`#${pedido.NroPedido} `}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        className="m-0 p-0 bg-transparent"
                                        item
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Typography variant="caption">
                                            Total Base:
                                        </Typography>
                                        <Typography className="w-25 text-end" component="p" variant="subtitle2">
                                            {pedido && pedido.MontoTotalBase
                                                ? formatNumber(pedido.MontoTotalBase, 2)
                                                : '0.00'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        className="m-0 p-0 bg-transparent"
                                        item
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Typography variant="caption">
                                            Total IVA:
                                        </Typography>
                                        <Typography className="w-25 text-end" component="p" variant="subtitle2">
                                            {pedido && pedido.MontoTotalIVA
                                                ? formatNumber(pedido.MontoTotalIVA, 2)
                                                : '0.00'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        className="m-0 p-0 bg-transparent"
                                        item
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Typography variant="h6">
                                            Total:
                                        </Typography>
                                        <Typography className="w-25 text-end" component='b' variant="h6">
                                            {pedido && pedido.MontoTotal
                                                ? formatNumber(pedido.MontoTotal, 2)
                                                : '0.00'
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    className=" w-100 m-0 p-0 bg-transparent"
                                    item
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"

                                >
                                    <Grid item className="m-0 p-1 bg-transparent text-center" xs={6} sm={4}>
                                        <Button
                                            className=" bg-body my-1"
                                            size='small' variant="outlined"
                                            endIcon={<RememberMe />}
                                            onClick={() => {
                                                selectMesero();
                                            }}
                                        >
                                            Cambiar Mesero
                                        </Button>
                                    </Grid>
                                    {/*  <Grid item className="m-0 p-1 bg-transparent text-center" xs={6} sm={4}>
                                        <Button
                                            className=" bg-body w-100 my-1"
                                            size='small' variant="outlined"
                                            endIcon={<Deck />}>
                                            Cambiar Mesa
                                        </Button>
                                    </Grid> */}
                                    <Grid item className="m-0 p-1 bg-transparent text-center" xs={6} sm={4}>
                                        <Button className=" bg-body w-100 my-1" size='small' variant='outlined' endIcon={<LocalPrintshop />}>
                                            Imprimir
                                        </Button>
                                    </Grid>
                                    <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={4}>
                                        <Button
                                            className=" bg-danger w-100  my-1"
                                            size='small'
                                            variant="contained"
                                            onClick={() => {
                                                handleOK('OK');
                                            }}
                                            endIcon={<PointOfSale />}
                                        >
                                            Abrir
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                            : <Grid
                                className="m-0 p-0 bg-transparent h-100 w-100 alert-danger"
                                item
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {selected && selectedMesero && !pedido
                                    ?
                                    <Grid
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        item className="m-0 p-1 bg-transparent text-center"
                                        xs={12} sm={12}
                                    >
                                        <Button
                                            className=" bg-body my-1"
                                            size='small' variant="outlined"
                                            endIcon={<RememberMe />}
                                            onClick={() => {
                                                selectMesero();
                                            }}
                                        >
                                            Cambiar Mesero
                                        </Button>
                                        <Button
                                            className=" bg-danger m-1"
                                            size='small'
                                            variant="contained"
                                            onClick={() => {
                                                handleOK('OK');
                                            }}
                                            endIcon={<PointOfSale />}
                                        >
                                            Abrir Mesa
                                        </Button>
                                    </Grid>
                                    : idSelected === 0
                                        ? <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={12}>
                                            <Button
                                                className=" bg-danger   my-1"
                                                size='small'
                                                variant="contained"
                                                onClick={() => {
                                                    handleOK('No');
                                                }}
                                                endIcon={<RemoveShoppingCart />}
                                            >
                                                Limpiar
                                            </Button>
                                        </Grid>
                                        : <Typography variant="h6">
                                            {selected
                                                ?
                                                <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={12}>
                                                    <Button
                                                        className=" bg-danger m-1"
                                                        size='small'
                                                        variant="contained"
                                                        onClick={() => {
                                                            selectMesero();
                                                        }}
                                                        endIcon={<RememberMe />}
                                                    >
                                                        Mesero
                                                    </Button>
                                                </Grid>
                                                : 'Seleccionar Mesa'}
                                        </Typography>
                                }
                            </Grid>

                        }

                    </Grid>
                </Container>
            </Box >
            <SimpleToast title="Mensaje Aplicación:" message={message} setMessage={setMessage} />
            <SimpleDialog selectedValue={"selectedValue"} ThemeColor={ColorsDPana.primary} open={openSDIalog} setOpen={setOpenSDialog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
            <ModalDialog selectedValue={"selectedValue"} open={openMDIalog} setOpen={setopenMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
        </>
    )

}