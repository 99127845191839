import React from "react";
import { LoginProvider } from "./login-context";
import { OrderProvider } from "./order-context";
import { SettingProvider } from "./setting-context";
export interface Props {
    children?: any;
}

export default function ContextProviders({ children }: Props): JSX.Element {
    return (
        <SettingProvider>
            <LoginProvider>
                <OrderProvider>
                    {children}
                </OrderProvider>
            </LoginProvider>
        </SettingProvider>
    )
}