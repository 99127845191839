import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Panel from './panel';
import PanelBody from '../components/panel-body';
import PanelHeader from '../components/panel-header';
import { useSetLoginToken } from '../contexts/login-context';
import { ColorsDPana } from '../utils/Color';
import SimpleToast from './simple-toast';
import { useHistory } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { config } from '../utils/configs';
import SpinnerLoading from './spinner-loading';
import TabPanel from './tabs/tabpanel';
import InputGroup from './input-group';
import NumberPad from './number-pad';
import Label from './label';
import TextInput from './text-input';
import Button from './button';
import { useSetSetting, useSetting } from '../contexts/setting-context';
import { usuarios } from '../models/usuarios';
import logoWhite from "../assets/imagenes/logoicon-white.png";

const usesStyle = makeStyles({
    iconBack: {
        fontSize: "32px",
        color: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})`,
    },
});

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    esDialog?: boolean;
    onLogin?: any;
    header?: any;
    esPIN?: boolean | undefined;

}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function LoginPanel({ esPIN, header, esDialog = false, onLogin }: Props) {
    const history = useHistory();
    const [cssize, setcsSize] = useState(esDialog ? " h-100 w-100" : " vh-100");

    const setting = useSetting();
    const setSetting = useSetSetting();
    const setLoginToken = useSetLoginToken();
    const [CodigoPin, setAccessCode] = useState("");
    const [NombreUsuario, setUsername] = useState("");
    const [pass, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const setConfig = (token: any) => {
        getUser(token.ID).then(res => {
            if (res.status == 200) {
                let user: usuarios = res.data as unknown as usuarios;
                if (user) {
                    setting.UsuarioActual = user;
                    setting.EmpleadoActual = user.id_empleado_empleado;
                }
            }
        })
        setting.loginToken = token;
        setSetting(setting);
    }
    const loginWithAccessCode = function () {
        setSavingData(true);
        axios.post(config.SERVER_URI + "/login/accessCode", {
            CodigoPin
        }).then(res => {
            setLoginToken(res.data);
            setConfig(res.data);
            onLogin(true);
            setSavingData(false);
        }).catch(err => {
            onLogin(false);
            if (!esDialog)
                setLoginToken({});
            if (!err.response)
                setMessage('err-->' + err.message ? err.message : "Error Desconocido");
            else
                setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
            setSavingData(false);
        });
    };
    const [loading, setLoaing] = useState(false);
    const [textloading, settextLoaing] = useState("Cargando...");
    let savingData: boolean = false;

    const setSavingData = (saving: boolean, textLoading: string = "Cargando...",) => {
        savingData = saving;
        setLoaing(saving);
    }
    const getUser = async (id: number) => await axios.post(config.SERVER_URI + '/usuarios/get', {
        data: { id: id },
        options: {
            include: ["id_empleado_empleado"]
        }
    })
    const loginWithPassword = function () {
        setSavingData(true);
        axios.post(config.SERVER_URI + "/login/password", {
            NombreUsuario, pass
        }).then(res => {
            setLoginToken(res.data);
            setConfig(res.data);
            onLogin();
            setSavingData(false);
        }).catch(err => {
            setLoginToken({});
            if (!err.response)
                setMessage('err-->' + err.message ? err.message : "Error Desconocido");
            else
                setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
            setSavingData(false);
        });
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const titulo = () => {

        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                className="m-0 p-0 w-100"
            >
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    xs={1} sm={1}
                >
                    <img className="m-1 text-start" src={logoWhite}
                        style={{
                            position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, maxHeight: 30
                        }}

                        onClick={() => {
                            history.push('/gotodpana');
                        }} >
                    </img>
                </Grid>
                <Grid
                    className="m-0 p-0"
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={11} sm={11}
                >
                    <Typography className="text-white" variant='subtitle1'>
                        {header ? header : "Iniciar Sesión"}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {

    }, [])


    return <>

        <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={"m-0 p-0 mx-auto" + cssize}
        >
            <Panel className="mx-auto my-auto" style={{ maxWidth: "350px" }}>
                <PanelHeader style={{ display: "flex", alignItems: "center" }}>
                    {titulo()}
                </PanelHeader>
                <PanelBody>
                    <Box className="bg-transparent p-2 " sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            textColor='inherit'
                        >
                            {esPIN !== undefined
                                ? esPIN === true
                                    ? <Tab sx={{ color: 'white' }} label="Código" {...a11yProps(0)} />
                                    : <Tab sx={{ color: 'white' }} label="Password" {...a11yProps(1)} />

                                : <>
                                    < Tab sx={{ color: 'white' }} label="Código" {...a11yProps(0)} />
                                    <Tab sx={{ color: 'white' }} label="Password" {...a11yProps(1)} />
                                </>
                            }
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <InputGroup>
                            <Label>PIN:</Label>
                            <TextInput autoFocus={true} type='password' value={CodigoPin} onChange={(e) => { setAccessCode(e.target.value) }}></TextInput>
                        </InputGroup>
                        <NumberPad className="mt-2" text={CodigoPin} setText={setAccessCode} onEnter={loginWithAccessCode} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <InputGroup className="my-2">
                            <Label>Usuario:</Label>
                            <TextInput value={NombreUsuario} onChange={(e) => { setUsername(e.target.value) }}></TextInput>
                        </InputGroup>
                        <InputGroup className="my-2">
                            <Label>Password:</Label>
                            <TextInput type="password" value={pass}
                                onChange={(e) => { setPassword(e.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        loginWithPassword();
                                    }
                                }} />
                        </InputGroup>
                        <div className="d-flex justify-content-end">
                            <Button themeColor={ColorsDPana.secundary} onClick={loginWithPassword}>Login</Button>
                        </div>
                    </TabPanel>
                </PanelBody>
            </Panel>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Message:" message={message} setMessage={setMessage} />
        </Grid>
    </>


}
 