import { Box, Modal } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { opendirSync } from "fs";
import React from "react";
import { ColorsDPana } from "../../utils/Color";
import { useStateBasedOnProps } from "../../utils/helpers";
import "./style.css";
interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children?: React.ReactNode;
    tipo?: 'spinner' | 'barra' | 'loader';
    text?: string;
    open: boolean;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
export default function SpinnerLoading({ open, tipo, text, ...restOfProps }: Props) {
    const [abrir, setAbrir] = useStateBasedOnProps(open);
    return (
        <React.Fragment>
            <Modal
                open={abrir}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                onClose={(event, reason) => {
                    if (reason === "backdropClick") {
                        /* setAbrir(false); */
                    }
                }}
            >
                <div className="loader-container">
                    <div className="loader" />
                    <span className="loading-text">
                        {text ? text : "Loading..."}
                    </span>
                </div>
            </Modal>
        </React.Fragment>
    )
}