import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from "react";
import { ColoresDPana, ColorsDPana } from '../../utils/Color';
import "./style.css";


interface PanelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: React.ReactNode;
  ThemeColor?: ColoresDPana;
  style?: React.CSSProperties;
  className?: string;
}

/**
 * A value between 0-255 of how much whiter the glare should be than rest of the panel
 */
const GLARE = 90;
const GLARE_ALPHA = 0.3
const ALPHA = 0.8;

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
const useStyles: any = makeStyles((theme: Theme) => ({
  root: {
    border: "2px solid #e66306",
    borderRadius: "0",
    backdropFilter: "blur(1px) saturate(100%) contrast(45%) brightness(130%)",
    backgroundColor: "#fff",
    padding: "2px",

  },

}));
export default function Panel({ children, className, ThemeColor = ColorsDPana.primary, style, ...restOfProps }: PanelProps) {
  //additional style to make the galssy background effect

  const classes = useStyles();
  const panelStyle: React.CSSProperties = {
    border: "2px solid",
    borderColor: `rgb(${ThemeColor[0]}, ${ThemeColor[1]}, ${ThemeColor[2]})`,
    borderRadius: "0",
    backdropFilter: "blur(1px) saturate(100%) contrast(45%) brightness(130%)",
    backgroundColor: "#fff",
    padding: "4px",
    ...style
  }

  //spread rest of the props to the parent div
  return <div className={className ? className + " panel" : " panel"} style={panelStyle} {...restOfProps}>
    {children}
  </div>;
}
