import { Typography } from '@mui/material';
import { CSSProperties, useEffect } from 'react'
import { ColorsDPana } from '../../../../utils/Color';
import ObservacionesItemDlg from './observaciones-item-dlg';
import "../style.css";
interface Props {
    mensaje?: string;
    setMensaje?: any;
}

export default function ObservacioneDlg({ mensaje, setMensaje }: Props, style: CSSProperties) {

    const mstyle: React.CSSProperties = {
        color: `rgb(${ColorsDPana.black[0]}, ${ColorsDPana.black[1]}, ${ColorsDPana.black[2]})`,
        backgroundColor: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})`,
        background: `linear-gradient(180deg, rgba(${ColorsDPana.primary[0]},${ColorsDPana.primary[1]},${ColorsDPana.primary[2]}, 0.1) 0%, rgba(${ColorsDPana.textcolor[0]},${ColorsDPana.textcolor[1]},${ColorsDPana.textcolor[2]}, 1) 30px)`,
        ...style
    }
    useEffect(() => {

    }, []);

    return (
        <div className="item-detail-root" style={mstyle} >
            <div className="item-detail-header" style={{ color: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})` }}  >
                <Typography className="item-detail-textheader"  >¿Alguna Observación?</Typography>
            </div>
            <div className="item-detail-body">
                <ObservacionesItemDlg mensaje={mensaje} setMensaje={setMensaje}></ObservacionesItemDlg>
            </div>
        </div>
    )
}
