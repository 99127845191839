import ContextProviders from "./contexts";
/* import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core"; */
import { BrowserRouter as Router, Route } from "react-router-dom";
import RootView from "./views/root-view";
import HomeView from "./views/home-view";
import CartView from "./views/cart-view";
import CartDetailView from "./views/cart-view/cartdetail-view";
import ClienteView from "./views/cliente-view";
import ClienteRegistroView from "./views/cliente-view/clienteregistro-view";
import PaymentView from "./views/payment-view";
import PaymentDetailView from "./views/payment-view/paymentdetail-view";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { config } from "./utils/configs";

/* library.add(fas);
library.add(fab); */
const redirectToHomePage = (): null => {
  window.location.reload();
  return null;
};

function App() {
  return (
    <div className="App">
      <ContextProviders>
        <Router basename={process.env.PUBLIC_URL}  >
          <Route path={`/`} exact component={RootView} />
          <Route path={`/home`} exact component={HomeView} />
          <Route path={`/cart`} exact component={CartView} />
          <Route path={`/cartdetail`} exact component={CartDetailView} />
          <Route path={`/cliente`} exact component={ClienteView} />
          <Route path={`/clienteregistro`} exact component={ClienteRegistroView} />
          <Route path="/payment" exact component={PaymentView} />
          <Route path="/paymentdetail/:orderId" component={PaymentDetailView} />
          <Route path="/gotoadmin"
            render={() => window.location.href = config.gotoadmin} />
          <Route path="/gotodpana"
            render={() => window.open(config.gotodpana, '_blank')} />
          <Route path="/gotowhatsappmsg"
            render={() => window.location.href = config.gotowhatsappmsg} />
          <Route path="/gotowhatsappshare"
            render={() => window.open(config.gotowhatsappshare, '_blank')} />
        </Router>
      </ContextProviders>
    </div>
  );
}

export default App;
