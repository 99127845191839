import type { modificadores_producto } from './modificadores_producto';
import type { pedidos_detalle } from './pedidos_detalle';
import type { pedidos_detalle_modificadores_mov } from './pedidos_detalle_modificadores_mov';
import type { tipoacompanantes } from './tipoacompanantes';
import type { tipoimpuestos } from './tipoimpuestos';
import type { tipomodificadores } from './tipomodificadores';
import type { tipomonedas } from './tipomonedas';

export interface pedidos_detalle_modificadoresAttributes {
  ID: number;
  id_pedido_detalle?: number;
  id_TipoAcompanante?: number;
  id_TipoModificador?: number;
  id_ModificadorProducto?: number;
  id_TipoImpuesto?: number;
  id_TipoMoneda?: number;
  id_defTipoStatusPedido?: number;
  Nombre: string;
  NroItem?: number;
  MontoPrecio?: number;
  MontoPrecioDivisa?: number;
  MontoTotalImpuesto?: number;
  MontoTotalBase?: number;
  TasaIVA?: number;
  CantidadTotal?: number;
  CantidadImpreso?: number;
  FechaActual?: Date;
  esDevolucion?: number;
  esDespachado?: number;
  esImpreso?: number;
  Historico?: string;
  Mensaje?: string;
  MontoTasaDivisa?: number;
  id_defTipoPrecio?: number;
}

export class pedidos_detalle_modificadores implements pedidos_detalle_modificadoresAttributes {
  ID!: number;
  id_pedido_detalle?: number;
  id_TipoAcompanante?: number;
  id_TipoModificador?: number;
  id_ModificadorProducto?: number;
  id_TipoImpuesto?: number;
  id_TipoMoneda?: number;
  id_defTipoStatusPedido?: number;
  Nombre!: string;
  NroItem?: number;
  MontoPrecio?: number;
  MontoPrecioDivisa?: number;
  MontoTotalImpuesto?: number;
  MontoTotalBase?: number;
  TasaIVA?: number;
  CantidadTotal?: number;
  CantidadImpreso?: number;
  FechaActual?: Date;
  esDevolucion?: number;
  esDespachado?: number;
  esImpreso?: number;
  Historico?: string;
  Mensaje?: string;
  MontoTasaDivisa?: number;
  id_defTipoPrecio?: number;

  // pedidos_detalle_modificadores belongsTo modificadores_producto via id_ModificadorProducto
  id_ModificadorProducto_modificadores_producto!: modificadores_producto;
  id_pedido_detalle_pedidos_detalle!: pedidos_detalle;
  pedidos_detalle_modificadores_movs!: pedidos_detalle_modificadores_mov[];
  id_TipoAcompanante_tipoacompanante!: tipoacompanantes;
  id_TipoImpuesto_tipoimpuesto!: tipoimpuestos;
  id_TipoModificador_tipomodificadore!: tipomodificadores;
  id_TipoMoneda_tipomoneda!: tipomonedas;

}
