
import Dexie from 'dexie';

export interface IDBConfig {
    id: number;
    id_Estacion: string;
    id_Caja: string;
    id_lastSession: string;
    id_lastUser: string;
    id_Licencia: string;
    LastFecha: string;
}

export class AppDatabase extends Dexie {
    public Config_dx!: Dexie.Table<IDBConfig, number>;

    constructor() {
        super("dPana-Dx");
        var db = this;
        db.version(1).stores({
            Config_dx: '++id, id_Estacion, id_Caja,id_lastSession,id_lastUser,id_Licencia,LastFecha',
        });
        this.Config_dx = this.table('Config_dx'); // Just informing Typescript what Dexie has already done...
    }
}
