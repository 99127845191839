import { RememberMe, VisibilityOff, Visibility, Deck, LocalPrintshop } from "@mui/icons-material"
import { Box, Grid, FormControl, InputLabel, Select, Theme, OutlinedInput, MenuItem, InputAdornment, IconButton, FormHelperText, SelectChangeEvent, Container, Button, Typography } from "@mui/material"
import { values } from "lodash"
import { useEffect, useState } from "react";
import { usuarios } from "../../../models/usuarios"
import { sha256, useStateBasedOnProps } from "../../../utils/helpers";
import axios from 'axios';
import { config } from '../../../utils/configs';
import { useLoginToken, useSetLoginToken } from "../../../contexts/login-context";
import SimpleDialog from "../../dialogs/simple-dialog";
import ModalDialog from "../../dialogs/modal-dialog";
import SimpleToast from "../../../components/simple-toast";
import { makeStyles, } from '@mui/styles';
import { ColorsDPana } from "../../../utils/Color";

interface State {
    password: string;
    codigoPin: string;
    nombreUsuario: string;
    showPassword: boolean;
}
interface Props {
    handleResponse?: any;
    titulo?: string;
    actionMethod?: any;
    openModal?: boolean;
    List?: usuarios[];
    handleChange?: any;
    esOK?: boolean;
    setMeseroSelected?: any;
    meseroSelected?: usuarios;
    setOpen?: any;
}
const useStyles = makeStyles((theme: Theme) => ({
    boxWrapper: {
        minHeight: "calc(20vh + 150px)"
    },
    container: {
        position: "relative",
        zIndex: 1100,

    }
}));
export default function SelectMesero(props: Props) {
    const classes = useStyles();
    const [values, setValues] = useState<State>({
        password: '',
        codigoPin: '',
        nombreUsuario: '',
        showPassword: false,
    });
    const [eserrLogin, seteserrLogin] = useState(false);
    const setLoginToken = useSetLoginToken();
    const loginToken = useLoginToken();
    const [idSelected, setIdSelected] = useStateBasedOnProps(props.meseroSelected ? props.meseroSelected.ID : 0);
    const [selected, setSelected] = useStateBasedOnProps(props.meseroSelected)


    const handleChangeMesero = async (event: SelectChangeEvent<typeof idSelected>) => {
        if (event.target.value) {
            setIdSelected(Number(event.target.value));

        }
        else
            setIdSelected(0);
        seteserrLogin(false);
    };
    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleOK = async () => {
        let NombreUsuario = '';
        let Pass = '';
        if (selected) {
            NombreUsuario = selected.NombreUsuario;
            Pass = values.password;
            if (sha256(values.password) === selected.Pass) {
                await axios.post(config.SERVER_URI + "/login/password", { NombreUsuario, Pass }).then(res => {
                    setLoginToken(res.data);
                    if (props.setMeseroSelected)
                        props.setMeseroSelected(selected);
                    seteserrLogin(false);
                    if (props.setOpen)
                        props.setOpen(false);
                }).catch(err => {
                    console.log("error Login-->" + err);
                    NombreUsuario = '';
                    setLoginToken({});
                    seteserrLogin(true);
                });
            } else {
                setLoginToken({});
                seteserrLogin(true);
            }
        } else {
            NombreUsuario = '';
            Pass = '';
            props.setMeseroSelected(undefined);
            setIdSelected(0);
            props.setOpen(false);
            seteserrLogin(true);
        }
        setValues({
            ...values,
            nombreUsuario: NombreUsuario,
            password: '',
        });
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [TitleMDialog, setTitleMDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [message, setMessage] = useState("");
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);

    const childDialogRes = async (e: any) => {
    }
    const loginDialogRes = (e: boolean, actionMethod: any) => {
        if (!e)
            setMessage("Acceso no Autorizado!")
        if (actionMethod)
            actionMethod();
        setOpenSDialog(!e);
    }


    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = (value: string) => {
        setOpenSDialog(false);
    };
    const dialogResponse = (e: any) => {
        setOpenSDialog(false);
    }
    useEffect(() => {
        if (idSelected) {
            let m: usuarios | undefined = props.List ? props.List.find(x => x.ID == idSelected) : undefined;
            setSelected(m);
        }
    }, [idSelected])
    useEffect(() => {
        if (props.esOK) {
            setMessage("OKClick");
        }
    }, [props.esOK])
    //#endregion

    return (
        <>
            <Box component="main" className={classes.boxWrapper + ' bg-body my-1 p-1'}>
                <Container maxWidth="md" className={classes.container + ' m-0 p-0'}>
                    <Grid
                        className="p-0 m-1"
                        container
                        xs={12} md={12}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}>
                        <Typography className="text-center w-100 alert-dark rounded-3" variant="subtitle1">
                            {props.titulo}
                        </Typography>
                        <Grid
                            className="px-2 "
                            item
                            container
                            xs={12} md={12}
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                            <RememberMe fontSize='large' />
                        </Grid>
                        <Grid
                            className="px-2 "
                            item
                            container
                            xs={12} md={12}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                <InputLabel id="mesero-label">Mesero</InputLabel>
                                <Select
                                    labelId="mesero-label"
                                    id="mesero-select"
                                    value={idSelected}
                                    onChange={handleChangeMesero}
                                    input={<OutlinedInput label="Mesero" />}
                                >
                                    {props.List
                                        ? props.List.map((e, index) => {
                                            return (
                                                <MenuItem key={index} value={e.ID}>{e.Nombre}</MenuItem>
                                            )
                                        })
                                        : ''
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            className="px-2 "
                            item
                            container
                            xs={12} md={12}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <FormControl error={eserrLogin} sx={{ m: 1, minWidth: 120, width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    aria-describedby="component-error-text"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Contraseña"
                                />
                                <FormHelperText id="component-error-text">
                                    {eserrLogin
                                        ? 'Por Favor indique Contraseña coreecta!'
                                        : 'Indique la contraseña'
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid
                            className="px-2 w-100"
                            item
                            container
                            xs={12} md={12}
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Button className=" bg-dark w-50 my-1" size='small' variant="contained" onClick={() => { handleOK() }}  >
                                Iniciar Sesión
                            </Button>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
            <SimpleToast title="Mensaje Aplicación:" message={message} setMessage={setMessage} />
            <SimpleDialog selectedValue={"selectedValue"} ThemeColor={ColorsDPana.primary} open={openSDIalog} setOpen={setOpenSDialog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
            <ModalDialog selectedValue={"selectedValue"} open={openMDIalog} setOpen={setopenMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
        </>
    )
}