import { FormControl, RadioGroup, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { ColoresDPana, ColorsDPana } from '../../../../utils/Color';
import ItemTipoOpcionesDlg from './item-tipoopciones-dlg';
import "../style.css";
import { tipomodificadores } from '../../../../models/tipomodificadores';
import { useStateBasedOnProps } from '../../../../utils/helpers';

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    handleResponse?: (id: number) => void;
    items?: tipomodificadores[];
    ThemeColor?: ColoresDPana;
    titulo?: string;
    settotalOpciones?: Dispatch<SetStateAction<number>>;
    onTipomChange?: any;
    esMultiOpciones: boolean;
    count?: number;
}

export default function TipoOpcionesItemsDlg({ count, esMultiOpciones, onTipomChange, settotalOpciones, titulo, items, handleResponse, style }: Props) {
    const [itemCount, setitemCount] = useStateBasedOnProps(count ? count : 1);
    const [itemsCount, setitemsCount] = useState<{ id: number, count: number, precioTotal: number }[]>([]);
    const [value, setValue] = useState<any>(items ? items.length > 0 ? items[0].ID : 0 : 0);
    const opcRef = useRef<any>([]);

    const mstyle: React.CSSProperties = {
        color: `rgb(${ColorsDPana.black[0]}, ${ColorsDPana.black[1]}, ${ColorsDPana.black[2]})`,
        backgroundColor: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})`,
        background: `linear-gradient(180deg, rgba(${ColorsDPana.primary[0]},${ColorsDPana.primary[1]},${ColorsDPana.primary[2]}, 0.1) 0%, rgba(${ColorsDPana.textcolor[0]},${ColorsDPana.textcolor[1]},${ColorsDPana.textcolor[2]}, 1) 30px)`,
        ...style
    }
    const createItemListItem = (item: tipomodificadores, key: string | number) => {
        //build a display name
        let displayName = item.Descripcion ? item.Descripcion : "";
        if (!displayName) {
            item.Descripcion = "Opción sin Nombre";
        }
        return (
            <ItemTipoOpcionesDlg
                key={"opcion" + key}
                item={item}
                onClick={() => { }}
                handleCount={onCount}
                ref={el => opcRef.current[key] = el}
                activo={item.ID == value}
            />
        )
    }
    const opcionesaCero = () => {
        if (settotalOpciones)
            settotalOpciones(0);
        onTipomChange(undefined);
    }
    const onCount = (id: number, count: number, PrecioTotal: number) => {
        if (items) {
            if (!itemsCount)
                setitemsCount([]);

            let ic = itemsCount.find(x => x.id == id);
            if (ic) {
                ic.count = count;
                ic.precioTotal = PrecioTotal;
            }
            else {
                PrecioTotal = !Number.isNaN(Number(PrecioTotal)) ? PrecioTotal : 0;
                itemsCount.push({ id: id, count: itemCount, precioTotal: PrecioTotal });
            }
        }
        sumaOpciones(id);
    }
    const onSelectChanged = (id: number) => {
        setValue(id);
        sumaOpciones(id);
        if (handleResponse)
            handleResponse(id);
    }

    const sumaOpciones = (id: number | undefined = undefined) => {
        let suma: number = 0;
        if (!itemsCount)
            setitemsCount([]);
        if (esMultiOpciones) {
            itemsCount.forEach(el => {
                suma += el.precioTotal;
            });
        } else {
            if (id) {
                let item: tipomodificadores | undefined = items?.find(x => x.ID == id);
                opcionesaCero();
                itemsCount.length = 0;
                if (item) {
                    let PrecioTotal = !Number.isNaN(Number(item.MontoPrecioDivisa)) ? item.MontoPrecioDivisa ? +item.MontoPrecioDivisa : 0 : 0;
                    itemsCount.push({ id: item.ID, count: itemCount, precioTotal: PrecioTotal });
                    suma = PrecioTotal * itemCount;
                }
            }
        }
        if (settotalOpciones)
            settotalOpciones(suma);
        onTipomChange(itemsCount);
    }
    useEffect(() => {
        if (itemCount) {
            itemsCount.forEach(el => {
                sumaOpciones(el.id);
            });
        }
    }, [itemCount]);
    useEffect(() => {
        sumaOpciones(value);
    }, []);


    return (
        <div className="item-detail-root" style={mstyle}>
            <div className="item-detail-header" style={{ color: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})` }}>
                <Typography className="item-detail-textheader">{titulo ? titulo : "Opciones"}</Typography>
            </div>
            <div className="item-detail-content">
                <FormControl component="fieldset">
                    <RadioGroup
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={(e) => { onSelectChanged(+e.target.value); }}
                    >
                        {items ?
                            items.length ?
                                items.map((data, index) => createItemListItem(data, index))
                                : ""
                            : ""
                        }
                    </RadioGroup>
                </FormControl>

            </div>
        </div>
    )
}
