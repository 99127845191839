import React, { } from 'react'
import { makeStyles } from '@mui/styles';
import "./style.css";
import { ColoresDPana, ColorsDPana } from '../../utils/Color';
import { IconMenuTopAtributes } from '../../models/Entities/iconMenuTop';
import IconMenuTop from './icon-menutop';


interface IconMenuTopProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children?: React.ReactNode;
    className?: string;
    themeColor?: ColoresDPana;
    style?: React.CSSProperties;
    handleClick?: any;
    Icons: IconMenuTopAtributes[];
    justificado?: 'left' | 'right' | 'center';
}


const useStyles: any = makeStyles({
    root: {
        position: "relative",
        margin: 10,
    },
});

export default function MenuTopIcons({ justificado = 'center', Icons, handleClick, children, className, themeColor = ColorsDPana.sky_blue, style, ...restOfProps }: IconMenuTopProps) {
    const classes = useStyles();
    return (
        <div className={classes.root + " d-flex align-items-center"} style={{ justifyContent: justificado }}>
            {
                Icons.map((icon, index) => {
                    return <IconMenuTop key={index} icon={icon.icon} titulo={icon.titulo} children={icon.children} handleClick={(e: any) => { icon.onClick(e); }} themeColor={icon.color}></IconMenuTop>
                })
            }
        </div>
    )
}
