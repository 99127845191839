import type { facturas } from './facturas';
import type { facturas_detalle_descuentos } from './facturas_detalle_descuentos';
import type { facturas_detalle_impuestos } from './facturas_detalle_impuestos';
import type { modificadores_pedido } from './modificadores_pedido';
import type { modificadores_producto } from './modificadores_producto';
import type { productos } from './productos';
import type { productos_promociones } from './productos_promociones';
import type { tipoacompanantes } from './tipoacompanantes';
import { tipoimpuestos } from './tipoimpuestos';
import type { tipomodificadores } from './tipomodificadores';
import { tipomonedas } from './tipomonedas';
import type { tipoofertas } from './tipoofertas';
import type { tipopromociones } from './tipopromociones';
export interface facturas_detalleAttributes {
  ID: number;
  Id_Factura: number;
  Id_Producto?: number;
  id_ProductoPromocion?: number;
  Id_ModificadorProducto?: number;
  Id_Modificador?: number;
  id_TipoImpuesto?: number;
  id_TipoMoneda?: number;
  Id_TipoModificador?: number;
  Id_TipoAcompanante?: number;
  id_TipoPromocion?: number;
  id_TipoOferta?: number;
  id_defTipoPrecio?: number;
  Descripcion: string;
  NroItem?: number;
  Cantidad?: number;
  MontoPrecio?: number;
  MontoTotalImpuesto?: number;
  MontoTotalBase?: number;
  MontoDescuento?: number;
  ValorDescuento?: number;
  PuntosAplicados?: number;
  Comentario?: string;
  esDevolucion?: number;
  Historico?: string;
}
export class facturas_detalle implements facturas_detalleAttributes {
  ID!: number;
  Id_Factura!: number;
  Id_Producto?: number;
  id_ProductoPromocion?: number;
  Id_ModificadorProducto?: number;
  Id_Modificador?: number;
  id_TipoImpuesto?: number;
  id_TipoMoneda?: number;
  Id_TipoModificador?: number;
  Id_TipoAcompanante?: number;
  id_TipoPromocion?: number;
  id_TipoOferta?: number;
  id_defTipoPrecio?: number;
  Descripcion!: string;
  NroItem?: number;
  Cantidad?: number;
  MontoPrecio?: number;
  MontoTotalImpuesto?: number;
  MontoTotalBase?: number;
  MontoDescuento?: number;
  ValorDescuento?: number;
  PuntosAplicados?: number;
  Comentario?: string;
  esDevolucion?: number;
  Historico?: string;
  Id_Factura_factura!: facturas;
  facturas_detalle_descuentos!: facturas_detalle_descuentos[];
  facturas_detalle_impuestos!: facturas_detalle_impuestos[];
  Id_Modificador_modificadores_pedido!: modificadores_pedido;
  Id_ModificadorProducto_modificadores_producto!: modificadores_producto;
  Id_Producto_producto!: productos;
  id_ProductoPromocion_productos_promocione!: productos_promociones;
  Id_TipoAcompanante_tipoacompanante!: tipoacompanantes;
  id_TipoImpuesto_tipoimpuesto!: tipoimpuestos;
  Id_TipoModificador_tipomodificadore!: tipomodificadores;
  id_TipoMoneda_tipomoneda!: tipomonedas;
  id_TipoOferta_tipooferta!: tipoofertas;
  id_TipoPromocion_tipopromocione!: tipopromociones;
}