import { AppBar, Box, Collapse, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, Popover, Select, SelectChangeEvent, Snackbar, Stack, Theme, Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useState, useEffect, forwardRef } from 'react'
import { useHistory } from 'react-router-dom';
import { ArrowBack, AccountCircle, RememberMe, Deck, VisibilityOff, Visibility } from '@mui/icons-material';
import CartItemsComponent from '../../components/cart-items';
import { ColorsDPana } from '../../utils/Color';
import { styled } from '@mui/styles';
import { useSetSetting, useSetting } from '../../contexts/setting-context';
import { useOrder, useSetOrder } from '../../contexts/order-context';
import { useLoginToken, useSetLoginToken } from '../../contexts/login-context';
import { formatNumber } from 'fast-number-formatter'
import { loadArchivo } from '../../services/services';
import SimpleToast from '../../components/simple-toast';
import { mesas } from '../../models/mesas';
import { usuarios } from '../../models/usuarios';
import { clientes } from '../../models/clientes';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Orden, { ItemPedido } from '../../models/Entities/Orden.model';
import { enutipodlgOpc, sha256 } from '../../utils/helpers';
import CrudOrdenService from '../../services/crudorden.service';
import { enuStatusIntPedidoActual, enuStatusPedido, enuStatusPedidoDetalle, enuTipoPedidoDespacho } from '../../utils/enums';
import axios from 'axios';
import { config } from '../../utils/configs';
import dateFormat from "dateformat";
import { resolvePtr } from 'dns';
import SpinnerLoading from '../../components/spinner-loading';
import LoginDialog from '../dialogs/login-dialog';
import ModalDialog from '../dialogs/modal-dialog';
import GenerarPedido from './components/generar-pedido';
import SelectMesa from './components/select-mesa';
import SimpleDialog from '../dialogs/simple-dialog';
import { dialogResponse } from '../../utils/models';
import { pedidos } from '../../models/pedidos';
import SelectMesero from './components/select-mesero';

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100vw',
            height: '100vh',
            backgroundColor: `rgba(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]},0.8)`,
        },
        topPanel: {
            backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})!important`,
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            borderColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
            borderRadius: '1em',
            border: 'solid 0.1em',
        },
        topPanelEmpresa: {
            backgroundColor: `rgba(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]},0.8)!important`,
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,

        },
        header: {
            width: '100%',
            height: 'auto',
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
            padding: 5
        },
        topBarRow: {
            position: "relative",
            display: "flex",
            maxHeight: '60px!important',
            minHeight: '60px!important',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '5px 5px 5px 15px',
            margin: 0,
        },
        topBarItem: {
            width: 'auto',
            alignItems: 'center',
            display: "flex",
            justifyContent: 'space-between',
        },
        Content: {
            position: "relative",
            backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})`,
        },
        foot: {
            backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})`,
        },

        Unidad: {
            color: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.textcolor[2]})`
        },

        btn: {
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})!important`,
            "&:hover": {
                color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
                backgroundColor: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            },
        },
        cant: {
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})`,
        }
    }),
);

const BotonPedir = styled(Button)<ButtonProps>(({ theme }) => ({
    //color: theme.palette.getContrastText(`rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})`),
    color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
    backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
    '&:hover': {
        backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})!important`,
        boxShadow: `0 0 0 0.4rem rgba(${ColorsDPana.accent[0]}, ${ColorsDPana.accent[1]}, ${ColorsDPana.accent[2]},.5)!important`,
    },
}));
const Item = styled(Paper)(({ theme }) => ({
    padding: 5,
    textAlign: 'center',
}));
interface State {
    amount: string;
    password: string;
    codigoPin: string;
    nombreUsuario: string;
    cliente: string;
    weightRange: string;
    showPassword: boolean;
    openSnackbar: boolean;
    tipoStatusSnakBar: 'success' | 'info' | 'warning' | 'error';
    messageSnackBar: string;
}

const MiAlert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

export default function CartView() {
    const [values, setValues] = useState<State>({
        amount: '',
        password: '',
        codigoPin: '',
        nombreUsuario: '',
        cliente: '',
        weightRange: '',
        showPassword: false,
        openSnackbar: false,
        tipoStatusSnakBar: 'success',
        messageSnackBar: ''
    });
    const forceUpdate = useForceUpdate();
    const setting = useSetting();
    const setSetting = useSetSetting();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder();
    const setLoginToken = useSetLoginToken();
    const loginToken = useLoginToken();
    const classes = useStyles();
    const history = useHistory();

    const [mesaSelected, setMesaSelected] = useState<mesas | undefined>(PedidoActual?.Mesa);
    const [meseroSelected, setMeseroSelected] = useState<usuarios | undefined>(PedidoActual?.Mesero);
    const [clienteSelected, setClienteSelected] = useState<clientes | undefined>(PedidoActual?.Cliente);

    const [openPopover, setopenPopover] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [tipoOpc, settipoOpc] = useState<enutipodlgOpc>(enutipodlgOpc.ninguno);
    const [idmesaSelected, setidMesaSelected] = useState<number>(0);
    const [idmeseroSelected, setidMeseroSelected] = useState<number>(0);
    const [esAceptar, setesAceptar] = useState(false);
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const crudService: CrudOrdenService = new CrudOrdenService();
    const [esFacturado, setesFacturado] = useState(false);
    const [mesaSelectedprev, setMesaSelectedprev] = useState<mesas | undefined>();

    //#region UptoDown Controles
    const uptodown = (item: ItemPedido, up: boolean) => {
        if (item.Extras) {
            if (up) {
                selectMesa(mesaSelected, false);
                history.push({
                    pathname: '/home',
                    search: '?detail=true',  // query string
                    state: {  // location state
                        id: item.Id_Producto,
                    },
                });
            } else {
                removeItem(item);
                return;
            }
        } else {
            if (up) {
                item.newCantidadTotal = item.CantidadTotal ?? 0
                item.newCantidadTotal++;
            }
            else {
                item.Comentario = item.Comentario?.replace('(ELIMINADO)', '');
                item.newCantidadTotal = item.CantidadTotal ?? 0
                if (item.CantidadTotal && item.CantidadTotal > 0)
                    item.newCantidadTotal--;
                if (item.newCantidadTotal == 0) {
                    removeItem(item);
                    return;
                }
            }
            editItem(item);
        }
    }
    const removeItem = (item: ItemPedido) => {

        switch (item.id_defTipoStatusPedido) {
            case enuStatusPedidoDetalle.SinProcesar:
                item.newCantidadTotal = 0;
                item.Comentario = '(ELIMINADO)';
                break;
            case enuStatusPedidoDetalle.DespachoCocina:
            case enuStatusPedidoDetalle.PedidoNuevo:
            case enuStatusPedidoDetalle.Procesado:

            case enuStatusPedidoDetalle.ProcesadoDevuelto:
            case enuStatusPedidoDetalle.Recibido:
            case enuStatusPedidoDetalle.RecibidoCocina:
            case enuStatusPedidoDetalle.RecibidoDevuelto:
        }
        editItem(item);
        // }

    }
    let newOrder!: Orden;
    const editItem = (item: ItemPedido, addToPedido: boolean = false) => {
        newOrder = PedidoActual;
        if (newOrder) {
            let tmpitem = newOrder.editItem(item);
            setPedidoActual(prevState => newOrder);
        } else
            setMessage("No hay Pedido Actual");
        forceUpdate();
    }
    //#endregion

    const loadMesasList = (spinner: boolean = true) => {
        return new Promise(async (resolve, reject) => {
            setloading(spinner);
            await axios.post(config.SERVER_URI + "/adminonline/getmesascpedido")
                .then(res => {
                    resolve(res.data)
                }).catch(err => {
                    setMessage('err-->' + err);
                    reject(err);
                })
            setloading(false);
        });
    };
    const vaciarPedido = () => {
        if (mesaSelected)
            CrudOrdenService.setBlockMesa(mesaSelected.ID, false, loginToken);
        setPedidoActual(new Orden);
    };
    const esPedidoFacturado = async (id: number) => {
        let res: any;
        let data: pedidos;
        res = await axios.post(config.SERVER_URI + "/pedidos/get", {
            data: { id: id }
        });
        if (res.status == 200) {
            data = res.data as unknown as pedidos;
            if (data) {
                if (data.id_defTipoSatusPedido === enuStatusPedido.PedidoFacturado) {
                    setesFacturado(true);
                    setPedidoActual(() => PedidoActual);
                }
                else
                    setesFacturado(false);
            }
        } else {

        }
    }
    const loadPedido = async (id: number, esSpinning: boolean = true): Promise<pedidos | undefined> => {
        if (esSpinning) {
            setloading(true);
            settextloading('Cargando Pedido. Por favor espere')
            vaciarPedido();
        }
        let orden: any = new pedidos;
        await CrudOrdenService.loadPedido(id, loginToken).then(async (res: any) => {
            setloading(false);
            orden = res as Orden;
            orden.id_defTipoPedido = enuTipoPedidoDespacho.ComerAqui;
            if (!orden.Mesa) {
                setMessage("Pedido sin Mesa")
                vaciarPedido()
                return
            }
            selectMesa(orden.Mesa, true)
            setMeseroSelected(orden.Mesero);
            setPedidoActual(orden);
            if (orden.id_defTipoSatusPedido === enuStatusPedido.PedidoFacturado) {
                setMessage("inf-->Pedido Facturado. Cargando...")
                setTimeout(() => {
                    history.push('/cart');
                }, 1500);
            }
            return Promise.resolve(orden);
        }).catch(err => {
            setMessage(`err-->Al cargar Pedido.\n${err}`)
            setloading(false);
            return undefined;
        })
        setloading(false);
        return orden;

    }


    //#region Dialogo SelectOptions
    const handleChangeMesa = (event: SelectChangeEvent<typeof idmesaSelected>) => {
        if (event.target.value)
            setidMesaSelected(Number(event.target.value));
        else
            setidMesaSelected(0);
    };

    const handleClickSelect = (opc: any) => {
        settipoOpc(opc)
        switch (opc) {
            case enutipodlgOpc.cliente:
                setMessage("err-->Codigo para seleccionar cliente")
                break
            case enutipodlgOpc.mesa:
            case enutipodlgOpc.mesero:
                loadSelectMesa("Cargando Mesa");
                return;
            case enutipodlgOpc.pedir:
                if (meseroSelected && mesaSelected)
                    loadGenerarPedido("Enviar Pedido");
                else {
                    setMessage("inf-->Seleccionando Mesa para Ordenar...")
                    setTimeout(() => {
                        loadSelectMesa("Cargando Mesa");
                    }, 2500);
                }
                return;
            default:
                break;
        }

        // setOpen(true)
    }

    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            setValues({
                ...values,
                password: '',
            });
        }
    };
    const handleCancel = () => {

    };
    const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    //#endregion 
    let sendingOrder = false;
    const sendOrden = async () => {
        return new Promise(async (resolve, reject) => {
            if (!sendingOrder) {
                try {
                    sendingOrder = true;
                    if (!crudService.savingData) {
                        PedidoActual.id_defTipoSatusPedido = enuStatusPedido.Procesando;
                        CrudOrdenService.modifPedido(PedidoActual, loginToken)
                            .then(res => {
                                let orden: Orden = res as Orden;
                                orden.Mesa = PedidoActual.Mesa;
                                orden.Mesero = PedidoActual.Mesero;
                                setMeseroSelected(orden.Mesero);
                                selectMesa(orden.Mesa, true);
                                setPedidoActual(orden);
                                resolve(orden);
                            })
                            .catch(error => {
                                if (error.includes("Request failed with status code 401")) {
                                    setMessage("Error de Autenticación. Por favor Iniciar sesión")
                                    setTimeout(() => {
                                        loadLoginDialog(`Autorizar pedido ${meseroSelected?.Nombre}`);
                                    }, 2500);
                                } else {
                                    setMessage(error?.stack);
                                }
                                sendingOrder = false;
                                reject(error);
                            });
                    } else {
                        setMessage("Trabajando... Por favor espere!")
                    }
                } catch (error: any) {
                    if (error.includes("Request failed with status code 401")) {
                        setMessage("Error de Autenticación. Por favor Iniciar sesión")
                        setTimeout(() => {
                            loadLoginDialog(`Autorizar pedido ${mesaSelected?.mesas_detalles[0]?.id_UsuarioUltimoAcceso_usuario?.Nombre}`);
                        }, 2500);
                    } else {
                        setMessage(error?.stack);
                    }
                    sendingOrder = false;
                    reject(error);
                }
                finally {
                    sendingOrder = false;
                    crudService.savingData = false;
                }
            } else {
                setMessage("Enviando Orden. Por favor espere que el servidor Responda.");
                reject("Enviando Orden. Por favor espere que el servidor Responda.");
            }
        })
    };

    const loadArchivos = () => {
        loadArchivo(
            'usuarios',
            true,
            ['Nombre', 'Imagen', 'NombreUsuario', 'Pass', 'CodigoPin'], undefined, undefined
        ).then(
            (resolve) => { setMeseroslst(resolve as usuarios[]) },
            (error) => { setMessage('err-->' + error) }
        );
    }

    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState<boolean>();
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [Meseroslst, setMeseroslst] = useState<usuarios[]>([]);
    const [Mesaslst, setMesaslst] = useState<mesas[]>([]);
    const [okbtnMDialog, setokbtnMDialog] = useState<boolean | undefined>(undefined);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [esOK, setesOK] = useState(false);
    const [sDialogOptions, setsDialogOptions] = useState<any>();

    const loginDialogRes = (e: boolean, actionMethod: any) => {
        if (!e)
            setMessage("Acceso no Autorizado!")
        if (actionMethod)
            actionMethod();
        setOpenSDialog(!e);
    }
    const loadLoginDialog = (titulo: string) => {
        setMessage("inf-->Espere Mientras Iniciamos Sesión.");
        /* 
                if (props.setMesaSelected)
                    props.setMesaSelected(undefined);
                setSelected(undefined); */
        setChildrenMDialog(<SelectMesero
            meseroSelected={meseroSelected}
            setMeseroSelected={setMeseroSelected}
            List={Meseroslst}
            setOpen={setopenMDIalog}
            handleResponse={loginDialogRes}
            titulo={titulo} />);
        setTimeout(() => {
            setopenMDIalog(true);
        }, 2500);
    }

    const loadSelectMesa = (titulo: string) => {
        //vaciarPedido();
        setsDialogOptions({
            esOKEnabled: false,
            esOKButton: false,
            esCancelButton: false,
            handleResponse: (e: dialogResponse) => {
                switch (e) {
                    case 'OK':

                        break;
                    case 'No':
                        setMeseroSelected(meseroSelected);
                        selectMesa(mesaSelected, false);
                        vaciarPedido()
                        break;

                    default:
                        break;
                }
            }
        });
        setChildrenSDialog(<SelectMesa
            ListMeseros={Meseroslst}
            meseroSelected={meseroSelected}
            setMeseroSelected={setMeseroSelected}
            mesaSelected={mesaSelected}
            setMesaSelected={setMesaSelected}
            List={Mesaslst} esOK={esOK}
            setOpen={setopenMDIalog}
            handleResponse={onloadMesa}
            titulo={titulo} />);
        setOpenSDialog(true);
    }
    const onloadMesa = (res: dialogResponse, newMesa: mesas, newMesero?: usuarios) => {
        switch (res) {
            case 'OK':
                let id: number = 0;
                selectMesa(newMesa, true)
                if (newMesa?.mesas_detalles?.length && newMesa.mesas_detalles[0].id_Pedido) {
                    id = newMesa.mesas_detalles[0].id_Pedido;
                    loadPedido(id).then((response: any) => {
                        if (response && response.Mesero !== newMesero) {
                            if (response && newMesero) {
                                response.Id_Usuario = newMesero.ID;
                                response.Mesero = newMesero;
                                CrudOrdenService.modifPedido(response, loginToken).then(() => {
                                    loadPedido(id);
                                });
                            }
                        }
                    });


                }
                setOpenSDialog(false);
                break;
            case 'No':
                setMeseroSelected(undefined);
                selectMesa(mesaSelected, false);
                vaciarPedido()
                break;

        }

    }

    const loadGenerarPedido = (titulo: string) => {
        setChildrenSDialog(<GenerarPedido
            esFacturado={esFacturado}
            setOpen={setopenMDIalog}
            handleResponse={onloadGenerarPedido}
            titulo={titulo}
            pedido={PedidoActual} />);
        setOpenSDialog(true);
    }
    const onloadGenerarPedido = (res: dialogResponse, value: any) => {
        if (res === 'Pedido') {
            sendOrden().then(() => {
                loadPedido(PedidoActual.ID, true);
                selectMesa(mesaSelected, false);
                setTimeout(() => {
                    history.push('/home');
                }, 1500);
            });
        }
        if (res === 'OK') {
            setMesaSelected(undefined)
            setMeseroSelected(undefined);
            vaciarPedido()
            setTimeout(() => {
                selectMesa(mesaSelected, false);
                history.push('/home');
            }, 1500);
        }
        setOpenSDialog(false);
        setPedidoActual(PedidoActual);
    }

    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    //#endregion
    const selectMesa = (mesa: mesas | undefined, select: boolean) => {
        CrudOrdenService.setBlockMesa(mesaSelectedprev ? mesaSelectedprev.ID : 0, false, loginToken);
        if (select) {
            CrudOrdenService.setBlockMesa(mesa ? mesa.ID : 0, true, loginToken);
            setMesaSelected(mesa);
            setMesaSelectedprev(mesa);
        }
        else {
            CrudOrdenService.setBlockMesa(mesa ? mesa.ID : 0, false, loginToken);
            setMesaSelected(undefined);
        }
    }
    useEffect(() => {
        if (esFacturado && PedidoActual.ID) {
            loadGenerarPedido("Pedido Facturado");
            setPedidoActual(PedidoActual);
        }
    }, [esFacturado, PedidoActual])

    useEffect(() => {
        let isMounted = true
        const intervalId = setInterval(async () => {
            if (!isMounted) return
            loadMesasList(false).then((res: any) => {
                if (res)
                    setMesaslst(res);
            })
            if (setting.idPedidoActual) {
                esPedidoFacturado(setting.idPedidoActual);
            }
        }, 5000)

        return () => {
            clearInterval(intervalId); //This is important
            isMounted = false // Let's us know the component is no longer mounted.
        }
    }, [useState])

    useEffect(() => {
        if (PedidoActual) {
            setting.idPedidoActual = PedidoActual.ID
            setSetting(setting);
        }
    }, [PedidoActual])

    useEffect(() => {
        //Cambiar de mesero
        /* if (PedidoActual && PedidoActual.ID) {
            if (PedidoActual?.Mesero !== meseroSelected)
                PedidoActual.Mesero = meseroSelected;
        } */
        if (PedidoActual && !PedidoActual.ID) {
            PedidoActual.Mesero = meseroSelected;
        }

    }, [meseroSelected]);

    useEffect(() => {
        if (PedidoActual && !PedidoActual.ID) {
            PedidoActual.Mesa = mesaSelected;
        }
        /*  if (!mesaSelected)
             vaciarPedido(); */
    }, [mesaSelected]);


    useEffect(() => {
        loadMesasList().then((res: any) => {
            if (res)
                setMesaslst(res);
        })
        loadArchivos();
        if (PedidoActual) {
            selectMesa(PedidoActual.Mesa, true)
            setMeseroSelected(PedidoActual.Mesero);
            setClienteSelected(PedidoActual.Cliente);
        }

    }, []);

    return (
        <div className={classes.root}>
            <div className="d-flex position-relative flex-column h-100" >
                <AppBar className={classes.header} position="fixed" color='transparent'  >
                    <Box className="p-0" sx={{ flexGrow: 1 }}>
                        <Grid
                            className="p-1"
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}>
                            <Grid
                                className="px-2"
                                xs={1} md={1} container
                                direction="column"
                                justifyContent="center"
                                alignItems="center">
                                <IconButton className={classes.topBarItem} edge="start" color="inherit" aria-label="open drawer" onClick={() => {
                                    selectMesa(mesaSelected, false);
                                    history.push({
                                        pathname: '/home',
                                        search: '?detail=false',  // query string
                                        state: {  // location state
                                            id: undefined,
                                        },
                                    });
                                }}>
                                    <ArrowBack />
                                </IconButton>
                            </Grid>
                            <Grid
                                xs={11} md={3}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center" >
                                <Grid
                                    xs={8} md={12}
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center" >
                                    <Typography variant='subtitle1'>{config.nombreEmpresa} </Typography>
                                </Grid>
                                <Grid
                                    className="px-1 text-white bg-black opacity-75 alert-danger"
                                    xs={4} md={12}
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center" >
                                    <Typography variant='caption'>{'dPana onSite'} </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Item className={classes.topPanel + " px-1"}>
                                    <Grid
                                        xs={12} md={12}
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center" >
                                        <Grid
                                            xs={3} md={3}
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center" >
                                            <Typography className="px-1 text-start opacity-50" variant='caption'>{`Fecha: ` + dateFormat(PedidoActual.FechaActual, "dd/mm")}  </Typography>
                                            <Typography className="px-1 text-start opacity-50" variant='caption'>{PedidoActual.ID ? `#: ` + PedidoActual.NroPedido : 'Sin Enviar'}  </Typography>
                                        </Grid>
                                        <Grid
                                            className={mesaSelected !== undefined ? ' border-danger border card-img-top p-1' : ''}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => { handleClickSelect(enutipodlgOpc.mesa); }}
                                            xs={3} md={3}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center" >
                                            <Grid
                                                xs={12} md={1}
                                                item
                                            >
                                                <Typography className="px-1 text-start opacity-50" variant='caption'> <Deck /></Typography>
                                            </Grid>
                                            <Grid
                                                xs={12} md={11}
                                                item
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                {mesaSelected
                                                    ? <Typography className="px-1 text-center " variant='caption' onClick={() => { handleClickSelect(enutipodlgOpc.mesa); }}  >{mesaSelected.Nombre}</Typography>
                                                    : <Button className="p-0 px-1  opacity-50" variant='outlined' size='small' style={{ margin: 2, color: 'white', border: '1px solid white' }} onClick={() => { handleClickSelect(enutipodlgOpc.mesa); }}  >{'Mesa'} </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            className={meseroSelected !== undefined ? 'border-danger border card-img-top p-1' : ''}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => { handleClickSelect(enutipodlgOpc.mesero); }}
                                            xs={3} md={3}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center" >
                                            <Grid
                                                xs={12} md={1}
                                                item
                                            >
                                                <Typography className="px-1 text-start opacity-50" variant='caption'><RememberMe /></Typography>
                                            </Grid>
                                            <Grid
                                                xs={12} md={11}
                                                item
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                {meseroSelected
                                                    ? <Typography className="px-1 text-center " variant='caption'   >{meseroSelected.Nombre}</Typography>
                                                    : <Button className="p-0 px-1  opacity-50" variant='outlined' size='small' style={{ margin: 2, color: 'white', border: '1px solid white' }} onClick={() => { handleClickSelect(enutipodlgOpc.mesero); }}  >{'Mesero'} </Button>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            className={clienteSelected !== undefined ? ' border-danger border card-img-top p-1' : ''}
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => { handleClickSelect(enutipodlgOpc.cliente); }}
                                            xs={3} md={3}
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center" >
                                            <Grid
                                                xs={12} md={1}
                                                item
                                            >
                                                <Typography className="px-1 text-start opacity-50" variant='caption'><AccountCircle /></Typography>
                                            </Grid>
                                            <Grid
                                                xs={12} md={11}
                                                item
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                {clienteSelected
                                                    ? <Typography className="px-1 text-center " variant='caption'   >{clienteSelected?.Nombres}</Typography>
                                                    : <Button className="p-0 px-1  opacity-50" variant='outlined' size='small' style={{ margin: 2, color: 'white', border: '1px solid white' }} onClick={() => { handleClickSelect(enutipodlgOpc.cliente); }}  >{'Cliente'} </Button>
                                                }
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Item>
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Item className={classes.topPanel + " px-1"}>
                                    <Grid
                                        xs={12} md={12}
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center" >
                                        <Grid
                                            item
                                            xs={6} md={6}
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography className="px-1 opacity-50" variant='caption'>{`Total Bs.: `}  </Typography>
                                            <Typography className="opacity-50" variant='h6'>{`${formatNumber(PedidoActual.getMontoTotal, 2)}`}  </Typography>
                                        </Grid>
                                        <Grid
                                            xs={6} md={6}
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center" >
                                            <Typography className="px-1" variant='caption'>{`Total $: `}  </Typography>
                                            <Typography variant='h5'>{`${formatNumber(PedidoActual.getMontoTotal, 2)}`}  </Typography>
                                        </Grid>
                                    </Grid>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </AppBar>
                <div className={classes.Content + "w-100 h-100 overflow-scroll position-relative"}>
                    <CartItemsComponent handleRemove={removeItem} handleUptoDown={uptodown} PedidoActual={PedidoActual} setPedidoActual={setPedidoActual} ></CartItemsComponent>
                </div>
                <div className={classes.foot + "bg-black w-100 p-1 d-flex justify-content-center align-items-center"}>
                    <BotonPedir className=" item-detail-btnpedir" variant="contained" onClick={() => { handleClickSelect(enutipodlgOpc.pedir); }}>
                        <Typography >{esFacturado ? 'Ver Factura' : 'Pedir'}</Typography>
                    </BotonPedir>
                </div>
            </div>
            <Popover
                open={openPopover}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 0, left: 0 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                The content of the Popover.
            </Popover>
            <Dialog disableEscapeKeyDown open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <></>
                </DialogTitle>
                <DialogContent className='p-2'>
                    <></>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancelar</Button>
                    <Button   >Aceptar</Button>
                </DialogActions>
            </Dialog>
             {/*// TODO:Revisar  */}
            <Snackbar open={values.openSnackbar} autoHideDuration={6000} onClose={()=>{}} > 
                <MiAlert onClose={handleCloseSnackBar} severity={values.tipoStatusSnakBar} sx={{ width: '100%' }}>
                    {values.messageSnackBar}
                </MiAlert>
            </Snackbar>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleToast title="Mensaje Aplicación:" message={message} setMessage={setMessage} />
            <SimpleDialog {...sDialogOptions} selectedValue={"selectedValue"} ThemeColor={ColorsDPana.primary} open={openSDIalog} setOpen={setOpenSDialog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
            <ModalDialog setokButton={setokbtnMDialog} okButton={okbtnMDialog} selectedValue={"selectedValue"} setOpen={setopenMDIalog} open={openMDIalog} onClose={handleCloseSimpleDialog} children={ChildrenMDIalog} title={TitleSDialog} />
        </div>
    )
}