
export enum enuOrigenDCTO {
    Ninguno = 0,
    Todos,
    PorItem,
    General
}
export enum enuTipoUsuario {
    SuperUsuario = 0,
    Administrador = 1,
    SupervisorCaja = 2,
    Cajero = 3,
    Mesero = 4,
    Cliente = 5,
    Usuario = 6,
    Invitado = 99,
}
export enum enuTipoNotificacion { Successful, Warning, Danger };
export enum enuKssSistemas {
    Ninguno = 0,
    kssMarket = 100,
    kssBigFood = 200,
    kssSocialClub = 300,
    kssTotalKontrol = 400
}


/// <summary>
/// Enumera las letras Prefijo de los Nuemros de Cedula
/// </summary>
export enum enuLetrasRIF {
    V = 0,
    E = 1,
    J = 2,
    G = 3,
    P

}

export enum enuTipoNacionalidad {
    Venezolano = 1,
    Extranjero = 2
}
export enum enuTipoSexo {
    Indefinido = 0,
    Masculino = 1,
    Femenino = 2
}

/// <summary>
/// indica los tipos de datos a pedir al cliente
/// </summary>
export enum enuTiposPedirDatosCliente {
    Ninguno = 0,
    NoPedirdatos,
    PedirDatosresumidos,
    PedirDartosCompletos
}

/// <summary>
/// Enumera el tipo de autorizacion Solicitada.
/// </summary>
export enum enuTipoAutorizacion {
    Ninguno = 0,
    InicioSesion = 1,
    Solicitud_Autorizacion = 2,
    Negacion_Autorizacion = 3
}

/// <summary>
/// Enumera los tipos Privilegios de usuarios
/// Cada nivel Superior Aumenta en decenas
/// </summary>
export enum enuTipoUsuarioNivel {
    //Usuarios de Sistema
    Ninguno = 0,
    ROOT = 30,
    Administrador_Sistema = 21,

    //Usuarios Administrativos
    /// <summary>
    /// Nivel Superior Administrativo
    /// </summary>
    Administrador = 20,
    SubAdministrador = 19,
    Usuario_Avanzado = 18,
    SubUsuario_Avanzado = 17,
    /// <summary>
    /// Nivel inferior Administrativo
    /// </summary>
    Usuario = 16,

    //Usuarios de punto de Venta
    /// <summary>
    /// Nivel Superior POS
    /// </summary>
    POS_Supervisor_Caja = 10,
    POS_Cajero = 9,
    POS_Supervisor_Mesonero = 8,
    POS_Mesonero = 7,
    /// <summary>
    /// Nivel Inferior POS
    /// </summary>
    POS_Cliente = 4
}


/// <summary>
/// Tipo de Pago
/// </summary>
export enum enuTipoPago {
    Ninguno = 0,
    NoPago = 0,
    Efectivo = 1,
    Credito = 2,
    NotaDebito = 3,
    Nota_Credito = 4,
    Tarjeta_Debito = 5,
    Tarjeta_Credito_VISA = 6,
    Tarjeta_Alimentacion = 7,
    CestaTicket = 8,
    Cupon = 9,
    Transferencia_Bancaria = 10,
    CriptoMoneda_Otra = 11,
    Pago_Movil = 12,
    Cheque = 13,
    Pagos_Internos = 14,
    Prepagado = 15,
    Cortesía = 16,
    Bono_Consumo = 17,
    Anulación = 18,
    Consumos_Internos = 18,
    Bio_Pago = 19,
    Pago_Digital = 20,
    EFECTIVO_Moneda_Extranjera = 21,
    Tarjeta_Moneda_Extranjera = 22,
    Otros_Pagos_Moneda_Extranjera = 23,
    Otros_Pagos = 24,
    Tarjeta_Credito_MasterCard = 25,
    Tarjeta_Credito_Otra = 25,
    Transferencia_Bancaria_Zelle = 26,
    CriptoMoneda_BitCoin = 27,
    CriptoMoneda_Petro = 28,
    Transferencia_Extranjera = 29,
}

/// <summary>
/// Tipo de Movimiento de Caja
/// </summary>
export enum enuPOSTipoMovimientoCaja {
    FondoCaja = 2,
    Ingreso = 0,
    Egreso = 1
}

/// <summary>
/// Tipo Egreso de Caja
/// </summary>
export enum enuPOSTipoEgresoCaja {
    Devolucion = 1,
    DepositoBanco = 2,
    Vale = 3,
    PagoProveedor = 4,
    RetiroCaja = 5,
    Otro = 6
}

/// <summary>
/// Tipo Ingreso de Caja
/// </summary>
export enum enuPOSTipoIngresoCaja {
    NotaCredito = 1,
    DepositoBanco = 2,
    IngresoEfectivo = 3,
    AbonoCliente = 4,
    Otro = 5
}

/// <summary>
/// Tipo Abono de Caja
/// </summary>
export enum enuPOSTipoAbonoCaja {
    AbonoCLiente = 1,
    AbonoProveedor = 2,
    Otro = 5
}
/// <summary>
/// Tipo de Documento
/// </summary>
export enum enuPOSTipoDocumento {
    Ninguno = 0,
    FacturaVenta = 11,
    FacturaCompra = 12,
    NotaDebito = 21,
    NotaCredito = 22,
    NotaEntregaCompra = 23,
    NotaEntregaVenta = 24,
    PresupuestoVenta = 51,
    PresupuestoCompra = 52,
    PedidoVenta = 61,
    PedidoCompra = 62,
    DevolucionCompra = 63,
    DevolucionVenta = 64,
    POSNotaConsumo = 31,
    POSComanda = 32,
    POSReciboVenta = 33,
    POSReporteX = 41,
    POSReporteZ = 42

}

/// <summary>
/// Tipo de Cuadre de Caja
/// </summary>
export enum enuTipoCuadreCaja {
    ArqueoCaja,
    CorteParcialX,
    CorteParcialZ
}



/// <summary>
/// indica tipos de Precios
/// </summary>
export enum EnuTiposPrecio {
    Ninguno = 0,
    precioManual = 1,
    precioMaximo = 2,
    precioMayor = 3,
    precioMinimo = 4,
    precioDivisa = 5,
    precioOferta = 6
}

/// <summary>
/// Indica los Identificadores referencias de Los impuestos
/// </summary>
export enum enuTipoImpuestos {
    Exento,
    General,
    Aumentado,
    Reducido
}


/// <summary>
/// Indica las modiciaciones hechas a una factura
/// </summary>
export enum enuTiposModificacionFactura {
    Ninguno = 0,
    AnulacionItems,
    AnulacionFactura,
    AnulacionDescuento,
    AnulacionImpuesto,
    ModificacionItem,
    ModificacionDescuento,
    ModificacionImpuesto,
    CambioCliente,
    CambioFechaEmision,
    CambioFechaPago,
    CambioFechaCobro,
    CambioStatusDoc
}

/// <summary>
/// Indica si la forma de recepcion/venta es por Empaque o por Unidad
/// </summary>
export enum enuTipoUnidadMedidaDespacho {
    Ninguno = 0,
    Empaque,
    Unidad
}
export enum enuTipoCompra {
    SolicitudPrecios = 0,
    OrdenCompra,
    RemisionCompra,
    RecepcionOrdenCompra,
    RecepcionFacturaCompra,
    RecepcionfacturaGasto,
    DevolucionCompra,
}
/// <summary>
/// Indica las formas de descarga del inventario al facturar
/// </summary>
export enum enuTiposDescargaInventario {
    DescargarAlCierreZ,
    DescargarAlFacturar
}
export enum enuTipoMovimientoInventario {
    Desconocido = 0,
    CargasProductos = 1,
    DescargasProductos = 2,
    DevolucionProductos = 3,
    CargaMateriaPrima = 4,
    DescargaMateriaPrima = 5,
    DevolucionMateriaPrima = 6,
    DescargaMateriaPrimaProducto = 7,
    CargaProductoPendiente = 11,
    DescargaProductoPendiente = 12,
    CargaMateriaPrimaPendiente = 13,
    DescargaMateriaPrimaPendiente = 14,
    ConteoFisico = 21,
    CargaDevolucionVenta,
    DescargaDevolucionCompra,
    PendienteCompra,
    PendienteVenta,
    TrasladoAlmacenes,
    ConsumoInterno,
    EnviosProduccion,
    ReconteoPedido,
    ReconteoComprometido,
    ReconteoFísico,
    CierreAnual,
    AperturaAnual
}
export enum enuTipoMovimientoInventario_TipoCarga {
    IngresoProducto,
    ConteoFisico,
    ReposicionManual
}
export enum enuTipoMovimientoInventario_TipoDescarga {
    EgresoProducto,
    VentaManual,
    DevolucionManual
}
export enum enuTipoMovimientoInventario_ConteoFisico {
    InicioInventario,
    RecuentoInventario,
    AjusteManual
}
export enum enuTipoMovimientoInventario_TrasladoAlmacenes {
    TrasladoMostrador,
    TrasladoDepositos,
    TrasladoSucursales
}
export enum enuTipoMovimientoInventario_ConsumoInterno {
    UsoInterno,
    UsoEmpleados,
    Prestamo
}
export enum enuTipoMovimientoPedidoDetalle {
    Ninguno = 0,
    Agregar,
    Modificar,
    Eliminar,
    Devolucion,

}
export enum enuTipoMovimientoInventario_EnviarProducion {
    MateriaPrima,
    ComplementarCotillon,
    Desperdicio
}

export enum enuTipoEstatusMesa {
    Ninguno = 0,
    Libre = 1,
    Reservada = 2,
    Bloqueada = 3,
    OcupadasinPedido = 4,
    OcupadaconPedido = 5,
    OcupadaconPedido_retrasoPedido = 6,
    OcupadaconPedido_tiemposinaccion = 7,
    OcupadaconPrecuenta = 8,
    OcupadaconPrecuenta_tiemposinaccion = 9,
    EditandoAdministrador = 10,
    EditandoSupervisor = 11,
    EditandoCajero = 12,
    EditandoMesero = 13,
    EditandoCliente = 14,
    EditandoUsuario = 15,
    ConsultandoAdministrador = 16,
    ConsultandoSupervisor = 17,
    ConsultandoCajero = 18,
    ConsultandoMesero = 19,
    ConsultandoCliente = 20,
    ConsultandoUsuario = 21
}


export enum enuTipoUsuarioRestaurant {
    Ninguno = 0,
    Soporte,
    Supervisor,
    JefeMeseros,
    Mesero,
    JefeCajero,
    Cajero,
    JefeConductores,
    Conductor,
    Cliente
}

export enum enuTipoComercio {
    Indefinido = 0,
    Restaurant,
    Farmacia,
    Supermercado,
    Panaderia,
    Zapateria
}
/// <summary>
/// Indica el tipo de Punto de terminal
/// </summary>
export enum enuPOSTipoTerminal {
    Ninguno = 0,
    CajaPuntoVenta,
    VerificadorFijo,
    VerificadorFijoRemoto,
    VerificadorManual,
    VerificadorManualRemoto,
    DespachadorFijo,
    DespachadorFijoRemoto,
    DespachadorFijoManual
    //Despachador por vpn
}

/// <summary>
/// Indica el tipo de Terminal POS
/// </summary>
export enum enuAdministrativosPOS {
    Ninguno = 0,
    SistemaAdministrativo,
    SistemaPOS,
    SistemaDespachador,
    SistemaConsultaMovil
}

/// <summary>
/// Tipo de Estacion POS
/// </summary>
export enum enuTiposEstacionPOS {
    Ninguno = 0,
    CajaPuntoVenta,
    VerificadorFijo,
    VerificadorFijoRemoto,
    VerificadorManual,
    VerificadorManualRemoto,
    DespachadorFijo,
    DespachadorFijoRemoto,
    DespachadorFijoManual
}

/// <summary>
/// Enumera los Tipos de Movimientos en los botones desplazamiento CRUD
/// </summary>
export enum enuBtnsRegistro {
    Primero = 0,
    Anterior = 1,
    Siguiente = 2,
    Ultimo = 3,
    Agregar = 4,
    Eliminar = 5,
    Guardar = 6,
    Cancelar = 7,
}


/// <summary>
/// Tipo de Accion de Impresion Fiscal
/// </summary>
export enum enuAccionImpresionIF {
    Ninguno = 0,
    NotaConsumo,
    Factura,
    MovimientoCaja,
    AperturaCaja,
    ReimprimirFactura,
    reporteCuadreCaja,
    reporteZFiscal,
    reporteXFiscal,
    reporteXNoFiscal,
    MostrarMensajeComercial,
    MostrarProductoVisor
}


/// <summary>
/// Estado de Servicios
/// </summary>
export enum enuStatusServicioPago {
    Ninguno = 0,
    Recibido,
    Proceso,
    Pagado,
    ParcialmentePagado,
    Entregado,
    Desconocido
}
export enum enuTipoExtra {
    Acompanante,
    Opcion,
    Extra

}
/// <summary>
/// Tipos de Pedidos
/// </summary>

export enum enuTipoPedidoDespacho {
    Desconocido = 0,
    ComerAqui = 1,
    llevar = 2,
    Delivery = 3,
    Pickup = 4,
    ConsumoInterno = 5,
    Pedido = 6,
    Guardado = 7,
    Recuperado = 8,
    VentaDirecta
}
/// <summary>
/// Tipos de Pedidos
/// </summary>
export enum enuTipoOrigenPedido {
    Desconocido = 0,
    VentaDirecta = 1,
    Mesa = 2,
    SitioWeb = 3,
    WhatsApp = 4,
    Facebook = 5,
    Instagram = 6,
    AplicacionMovil = 7,
    LlamadaTelefonica = 8,
    POS
}
/// <summary>
/// Status del Detalle del Pedido
/// </summary>
export enum enuStatusPedidoDetalle {
    SinProcesar = 0,
    PedidoNuevo = 1,
    Procesado = 2,
    RecibidoCocina = 3,
    DespachoCocina = 4,
    Recibido = 5,
    ProcesadoDevuelto = 6,
    RecibidoDevuelto = 7,
    AgregandoProducto = 8,
    Facturado = 9
}
/// <summary>
/// Estado de Pedido
/// </summary>
export enum enuStatusPedido {
    Ninguno = 0,
    Procesando = 1,
    PedidoNuevo = 2,
    PedidoDespachado = 3,
    PedidoRecibido = 4,
    PedidoFacturado = 5,
    PedidoDevuelto = 6,
    PedidoAnulado = 7,
    PendienteFacturar = 8,
    Editando = 9,
}

/// <summary>
/// Estado de Pedido
/// </summary>
export enum enuStatusComanda {
    SinProcesar = 0,
    EnEspera = 1,
    EnCocina = 2,
    SaliendoCocina = 3,
    Despachado = 4,
    Devolucion = 5,
    Anulada = 6,
}




/// <summary>
/// Estado de Facturas
/// </summary>
export enum enuStatusFactura {
    pendienteFacturar = 0,
    Facturado = 1,
    FacturaEnviada = 2,
    FacturaCobrada = 3,
    PendienteCobro = 4,
    facturaAnulada = 5
}

/// <summary>
/// Estado de Clientes
/// </summary>
export enum enuStatusCliente {
    Moroso = 2,
    Suspendido = 3,
    SaldoInsuficiente = 4,
    ClienteBloqueado = 5
}

/// <summary>
/// Estado de Documento
/// </summary>
export enum enuStatusDocumento {
    Ninguno = 0,
    enProceso = 1,
    Procesado = 2,
    Aprobado = 2,
    Reprobado = 1,
    PendienteAprobacion = 3,
    PendienteCierre = 4
}

/// <summary>
/// Estado Pago de Documento
/// </summary>

export enum enuStatusPagoDocumento {
    Ninguno = 0,
    SinPagos = 1,
    ParcialmentePagado = 2,
    PagadoTotalmente = 3,
    PendienteConfirmar = 4,
    ParcialmentePagado_PendienteConfirmar = 5,
    ParcialmentePagado_Credito = 6,
    Pagado_Credito = 7,
}

/// <summary>
/// Estado interno d elas Ordenes
/// </summary>

export enum enuStatusIntPedidoActual {
    Ninguno = 0,
    FacturadoAtendiendo = 1,
    AbiertoPedidoOnsite = 2,
    AbiertoPedidoPOS = 3,

}

