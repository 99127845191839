import { FormControlLabel, Radio, Button, RadioGroup, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react'
import { tipoacompanantes } from '../../../../../models/tipoacompanantes';
import { ColoresDPana, ColorsDPana } from '../../../../../utils/Color';
import "../../style.css";
const useStyles: any = makeStyles({
    btn: {
        color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
        backgroundColor: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
        "&:hover": {
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
        },
    },
    cant: {
        color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})`,
    }
});

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    handleClick?: any;
    item?: tipoacompanantes;
    ThemeColor?: ColoresDPana;
    titulo?: string;
    max?: number;
    handleCount?: (id: number, count: number, precioTotal: number) => void;
    activo?: boolean;
}
export default function ItemAcompanantesDlg({ activo, handleCount, max, item, style }: Props) {
    const classes = useStyles();
    const [precio, setprecio] = useState<any>();
    const [precioTotal, setprecioTotal] = useState<any>();
    const [simbolo, setsimbolo] = useState<any>("");
    const [btns, setbtns] = useState(false);
    const [count, setcount] = useState(0);
    const [limite, setlimite] = useState<any>("");

    const uptodown = (up: boolean) => {
        let cnt: number = count;
        if (up) {
            if (max != count) {
                cnt++;
                setcount(cnt);
            }
        }
        else {
            if (cnt > 0) {
                cnt--;
                setcount(cnt);
            }
        }
        if (handleCount)
            handleCount(item ? item.ID : 0, +cnt, +cnt > 0 ? (cnt * (item?.MontoPrecioDivisa1 ? item?.MontoPrecioDivisa1 : 0)) : 0);
    }
    useEffect(() => {
        if (max && max > 0) {
            if (count == max)
                setlimite("(Limite!)")
            else
                setlimite("");
        }
        if (item && precio && count > 0) {
            let total = precio as number * count;
            setprecioTotal(total);
        } else {
            setprecioTotal(precio);
        }
    }, [count]);


    useEffect(() => {
        if (item) {
            if (item.MontoPrecioDivisa1 && item.MontoPrecioDivisa1 > 0) {
                setprecio(item.MontoPrecioDivisa1);
                setsimbolo("$");
                setprecioTotal(item.MontoPrecioDivisa1);
            }
            else {
                setprecio("");
                setsimbolo("");
                setprecioTotal("");
            }
        }
    }, []);

    return (
        <div className="item-detail-root">
            <div className="item-detail-contentitem" >
                <FormControlLabel className="p-0 mx-1" value={item ? item.ID : 0}
                    control={
                        <Radio
                            className="p-0 mx-1 " size='small'
                        />}
                    label={<span className="item-detail-txtitem">  {item ? item.Descripcion : "Acompañante sin descripción"} {limite}
                        <span style={count > 0 || activo ? { fontWeight: 600 } : { fontWeight: 400 }}>{" " + simbolo}
                            <span className="px-1">{precio}</span>
                        </span>
                    </span>} />
                {
                    btns ?
                        <div className="item-detail-containercant">
                            <Button className={classes.btn + " item-detail-btncant"} onClick={() => { uptodown(false) }}> <Remove /></Button>
                            <div className={classes.cant + " item-detail-txtcant"}>
                                <Typography >{count}</Typography>
                            </div>
                            <Button className={classes.btn + " item-detail-btncant"} onClick={() => { uptodown(true) }}> <Add /></Button>
                        </div>
                        : ""
                }
            </div>
        </div>
    )
}
