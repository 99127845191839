
import axios from "axios";
import { config } from "../../../utils/configs";
import { Box, ButtonProps, createStyles, Button, Theme, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Add, Close, Remove, ShoppingCart } from "@mui/icons-material";
import { addDefaultImageComida, addDefaultSrcOnError, imgtoJson } from "../../../utils/helpers";
import { ColoresDPana, ColorsDPana } from "../../../utils/Color";
import TipoOpcionesItemsDlg from "./tipoopciones-items-dlg";
import ObservacioneDlg from "./observaciones-dlg";
import AcompanantesDlg from "./acompanantes-dlg";
import ExtraItemsDlg from "./extra-items-dlg";
import { makeStyles, styled } from "@mui/styles";
import { productos } from "../../../models/productos";
import { tipoproductos } from "../../../models/tipoproductos";
import { modificadores_producto } from "../../../models/modificadores_producto";
import { tipomodificadores } from "../../../models/tipomodificadores";
import { tipoacompanantes } from "../../../models/tipoacompanantes";
import "./style.css"
import { ItemPedido } from "../../../models/Entities/Orden.model";
import { pedidos_detalle_modificadores } from "../../../models/pedidos_detalle_modificadores";
import { enuStatusPedidoDetalle, enuTipoExtra, EnuTiposPrecio } from "../../../utils/enums";
import SettingsApp from "../../../models/Entities/Settings";
import { productos_promociones } from "../../../models/productos_promociones";
import SimpleDialog from "../../../views/dialogs/simple-dialog";



export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    handleClick?: any;
    item?: productos;
    ThemeColor?: ColoresDPana;
    handleResponse?: any;
    setting: SettingsApp;
    mensaje?: string;

}
const useStyles: any = makeStyles({
    root: {
        width: '90vw',
        height: '84vh',
        maxHeight: 700,
        maxWidth: 350
    },
    top: {
        position: "relative",
        display: "block"
    },
    contentTop: {
        zIndex: 10,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: 2
    },
    imgItem: {
        display: "block",
        width: "100%",
        margin: "auto",
        maxHeight: "130px",
        minHeight: "130px",
        objectFit: "cover",
    },
    rowDesc: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        position: "sticky",
        top: "0",
        marginTop: "-25px",
        marginBottom: "12px",
        boxShadow: `0px 8px 10px -4px rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})`,
    },
    containerCant: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap"
    },
    contentName: {
        height: "25px",
        backgroundColor: `rgba(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]}, 0.5)`
    },
    txtName: {
        color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})`,
        fontWeight: 700
    },
    txtDesc: {
        color: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})`,
        fontSize: "0.7rem !important",
        minHeight: '35px !important',
        maxHeight: '35px !important'
    },
    contentDesc: {
        minHeight: '67px !important',
        maxHeight: '67px !important',
        backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})`,
    },
    btnTop: {
        color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]}) !important`,
        background: "transparent",
        border: "none",
        display: 'flex',
        "&:hover": {
            color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})`,
            background: "transparent",
            border: 'none',
        },
    },
    btnTopIco: {
        height: 32,
        width: 32,
        "&:hover ": {
            height: 34,
            width: 34,
        },
    },
    contentBack: {
        borderRadius: "0",
        height: "40px",
        width: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]}, 0.5)`,
    },
    contentCart: {
        borderRadius: "50%",
        borderColor: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})`,
        border: "1px dashed",
        display: "flex",
        height: "50px",
        width: "50px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `rgba(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]}, 0.5)`,
    },
    Content: {
        position: "relative",
    },
    foot: {
        backgroundColor: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})`,
    },
    Unidad: {
        color: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.textcolor[2]})`
    },

    btn: {
        color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
        backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})!important`,
        "&:hover ": {
            color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
            backgroundColor: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
        },
    },
    cant: {
        color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})`,
    }
});

const BotonPedir = styled(Button)<ButtonProps>(({ theme }) => ({
    //color: theme.palette.getContrastText(`rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})`),
    color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
    backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
    '&:hover': {
        backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})!important`,
        boxShadow: `0 0 0 0.4rem rgba(${ColorsDPana.accent[0]}, ${ColorsDPana.accent[1]}, ${ColorsDPana.accent[2]},.5)!important`,
    },
}));
export default function ItemDetailDlg({ setting, item, handleResponse, style }: Props) {
    const [message, setMessage] = useState("");

    const history = useHistory();
    const classes = useStyles();
    const [tipopList, settipopList] = useState<tipoproductos[]>([]);
    const [modifList, setmodifList] = useState<modificadores_producto[]>([]);
    const [tipomList, settipomList] = useState<tipomodificadores[]>([]);
    const [acompList, setacompList] = useState<tipoacompanantes[]>([]);
    const [tipopSelected, settipopSelected] = useState<tipoproductos>();
    const [esLimite, setesLimite] = useState<boolean>(false);
    const [count, setcount] = useState<number>(1);
    const [precio, setprecio] = useState<number>(0);
    const [simbolo, setsimbolo] = useState<any>("");
    const [precioTotal, setprecioTotal] = useState<number>(0);
    const [totalExtras, settotalExtras] = useState<number>(0);
    const [totalOpciones, settotalOpciones] = useState<number>(0);
    const [totalAcompanantes, settotalAcompanantes] = useState<number>(0);
    const extraRef = useRef<any>();

    const [esmultiAcomp, setesmultiAcomp] = useState<boolean | undefined>(item?.id_tipo_producto_tipoproducto?.esMultiAcompanantes ? item?.id_tipo_producto_tipoproducto?.esMultiAcompanantes as unknown as boolean : false);
    const [tipomSelected, settipomSelected] = useState<tipomodificadores>();
    const [tipomCount, settipomCount] = useState<{ id: number, count: number, precioTotal: number }[]>([]);
    const [extrasCount, setextrasCount] = useState<{ id: number, count: number, precioTotal: number }[]>([]);
    const [acompCount, setacompCount] = useState<{ id: number, count: number, precioTotal: number }[]>([]);
    const [mensaje, setMensaje] = useState("");

    const totalstyle: React.CSSProperties = {
        fontWeight: 600
    }

    const mstyle: React.CSSProperties = {

        ...style
    }
    const loadList = (id: number) => {
        //get a list of all cajass
        axios.post(config.SERVER_URI + "/web/getproductodetail",
            { data: { id: id } }
        ).then(result => {
            let data: productos[] = result.data;
            if (data && data.length && data[0].id_tipo_producto_tipoproducto) {
                let tpSelected = data[0].id_tipo_producto_tipoproducto;
                if (tpSelected) {
                    settipopSelected(tpSelected);
                    setesLimite(tpSelected.esLimiteIngrediente ? true : false);
                    if (tpSelected.tipoproductos_tipomodificadores && tpSelected.tipoproductos_tipomodificadores.length > 0) {
                        let tm: tipomodificadores[] = [];
                        tpSelected.tipoproductos_tipomodificadores.filter(tptm => tptm.id_TipoProducto == tpSelected.ID).forEach(x => {
                            tm.push(x.id_TipoModificador_tipomodificadore);
                        });
                        settipomList(tm);
                        selectTipom(tm[0], item ? item.id_tipo_producto ? item.id_tipo_producto : 0 : 0);
                    }
                    if (tpSelected.tipoproductos_tipoacompanantes && tpSelected.tipoproductos_tipoacompanantes.length > 0) {
                        let ta: tipoacompanantes[] = [];
                        tpSelected.tipoproductos_tipoacompanantes.filter(tpta => tpta.id_TipoProducto == tpSelected.ID).forEach(x => {
                            ta.push(x.id_TipoAcompanante_tipoacompanante);
                        })
                        setacompList(ta);
                    }
                }
            }
        }).catch(err => {
            if (!err.response)
                setMessage('err-->' + err.message ? err.message : "Error Desconocido");
            else
                setMessage('err-->' + err.response ? err.response.data ? err.response.data : err.response : err);
        }).finally(() => {
        })
    }
    const selectTipom = (item: tipomodificadores, idtipop: number) => {
        settipomSelected(item);
        if (item.tipomodificadores_modificadores && item.tipomodificadores_modificadores.length > 0) {
            let tm: modificadores_producto[] = [];
            item.tipomodificadores_modificadores.forEach(x => {
                if (x.id_TipoProducto == idtipop)
                    tm.push(x.id_Modificador_modificadores_producto);
            })
            setmodifList(tm);
            // selectModif(tm[0]);
        }
    }

    const uptodown = (up: boolean) => {
        let cnt: number = count;
        if (up) {
            cnt++;
            setcount(cnt);
        }
        else {
            if (cnt > 1) {
                cnt--;
                setcount(cnt);
            }
        }
    }
    const handleExtrachange = (changes: any) => {
        setextrasCount(changes);
    }
    const handleAcompchange = (changes: any) => {
        setacompCount(changes);
    }
    const handleTipoModifchange = (changes: any) => {
        settipomCount(changes);
    }
    const sumarTotales = () => {
        if (item && count > 0)
            setprecioTotal((+precio * +count) + +totalExtras + (precio > 0 ? +totalOpciones : (+totalOpciones * count)) + (precio > 0 ? +totalAcompanantes : (+totalAcompanantes * count)));
        else
            setprecioTotal(+precio);
    }
    const selectOpcion = (id: number) => {
        ResetModificadores();
        if (tipomList && tipomList.length > 0) {
            var tm = tipomList.find(x => x.ID == id);
            modifList.length = 0;
            if (tm)
                selectTipom(tm, item ? item.id_tipo_producto ? item.id_tipo_producto : 0 : 0);
        }
    }
    const ResetModificadores = () => {
        if (extraRef.current)
            extraRef.current.ResetExtras();
    }
    //#region 
    const [ItemActual, setItemActual] = useState<ItemPedido | null>();
    let modificadoresProducto: modificadores_producto[];
    //#region Codigo Nuevo

    const crearItemPedido = (item: productos, cantidad: number = 0, comentario: string = "", mensaje: string = "", promocion: productos_promociones | undefined = undefined): ItemPedido | null => {
        if (item) {
            let i = "";
            if (item.ImagenWeb) {
                try {
                    i = imgtoJson(item.ImagenWeb.toString());
                    i = i.replace("http://localhost:8080/dl/sushibarstrike_v1_UD4u/", "http://sushibarstrike.com.ve/imagenes/")
                } catch (error) {
                    i = item.ImagenWeb;
                }
            }
            item.Imagen = i;
            item.ImagenWeb = i;

            let tmpItem: any = new ItemPedido(setting);
            if (item) {
                tmpItem.Imagen = item.ImagenWeb;
                tmpItem.PuntosRating = item.PuntosCantidad ?? 0;
                tmpItem.Descripcion = item.Nombre as string;
                tmpItem.Id_Producto = item.ID;
                tmpItem.id_TipoImpuesto = item.id_impuesto1;
                tmpItem.MontoPrecio = setting.getPrecio(item);
                tmpItem.MontoPrecioDivisa = item.MontoPrecioDivisa ?? 0;
                tmpItem.PuntosAplicados = item.PuntosCantidad ?? 0;
            }
            if (promocion) {
                tmpItem.Imagen = promocion.Imagen;
                tmpItem.PuntosRating = promocion.Puntos_Cantidad ?? 0;
                tmpItem.Descripcion = promocion.Nombre as string;
                tmpItem.Id_Producto = promocion.ID;
                tmpItem.id_TipoImpuesto = promocion.id_TipoImpuesto;
                tmpItem.MontoPrecio = setting.getPrecio(promocion);
                tmpItem.MontoPrecioDivisa = promocion.MontoPrecioDivisa ?? 0;
                tmpItem.PuntosAplicados = promocion.Puntos_Cantidad ?? 0;
            }
            //params
            tmpItem.newCantidadTotal = cantidad == 0 ? 1 : cantidad;
            tmpItem.Comentario = comentario;
            tmpItem.Mensaje = mensaje;
            //setting
            tmpItem.TasaIVA = +setting.getTasaIVA(tmpItem.id_TipoImpuesto);
            tmpItem.id_defTipoPrecio = setting.CajaActual ? setting.CajaActual.id_defTipoPrecioPredet : 1;
            //defaults
            tmpItem.FechaActual = new Date();
            tmpItem.CantidadImpreso = 0;
            tmpItem.CantidadDevolucion = 0;
            tmpItem.esDevolucion = 0;
            tmpItem.esImpreso = 0;
            tmpItem.esDespachado = 0;
            tmpItem.id_SectorImpresion = item.id_sector_impresion ?? 0;
            tmpItem.id_defTipoStatusPedido = enuStatusPedidoDetalle.SinProcesar;
            tmpItem.id_ProductoPromocion = 0;
            tmpItem.id_TipoPromocion = 0;
            tmpItem.id_TipoOferta = 0;
            return tmpItem;
        } else return null

        /*  let i = "";
         if (item.ImagenWeb) {
             try {
                 i = imgtoJson(item.ImagenWeb.toString());
                 i = i.replace("http://localhost:8080/dl/sushibarstrike_v1_UD4u/", "http://sushibarstrike.com.ve/imagenes/")
             } catch (error) {
                 i = item.ImagenWeb;
             }
         }
         item.Imagen = i;
         item.ImagenWeb = i;
         let tmpItem: ItemPedido = new ItemPedido;
         tmpItem.Imagen = item.ImagenWeb;
         tmpItem.PuntosRating = item?.PuntosCantidad ?? 0;
         tmpItem.Descripcion = (item.Nombre as string);
         tmpItem.Id_Producto = item.ID;
         tmpItem.MontoPrecioDivisa = item.MontoPrecioDivisa ?? 0;
         tmpItem.FechaActual = new Date();
         tmpItem.newCantidadTotal = cantidad;
         tmpItem.id_TipoImpuesto = item.id_impuesto1;
         tmpItem.TasaIVA = setting.Impuestos?.find(x => x.ID == item.id_impuesto1)?.MontoTasa ?? 0;
         tmpItem.id_ProductoPromocion = 0;
         tmpItem.id_TipoPromocion = 0;
         tmpItem.id_TipoOferta = 0;
         tmpItem.id_defTipoStatusPedido = enuStatusPedidoDetalle.SinProcesar;
         tmpItem.id_defTipoPrecio = setting.CajaActualPrecioPrdet;
         tmpItem.CantidadDevolucion = 0;
         if (setting.CajaActualPrecioPrdet) {
             switch (setting.CajaActualPrecioPrdet) {
                 case EnuTiposPrecio.precioDivisa:
                     tmpItem.MontoPrecio = item.MontoPrecioDivisa ?? 0;
                     break;
                 case EnuTiposPrecio.precioMaximo:
                     tmpItem.MontoPrecio = item.MontoPrecio2 ?? 0;
                     break;
                 case EnuTiposPrecio.precioMayor:
                     tmpItem.MontoPrecio = item.MontoPrecio3 ?? 0;
                     break;
                 case EnuTiposPrecio.precioMinimo:
                     tmpItem.MontoPrecio = item.MontoPrecio1 ?? 0;
                     break;
                 case EnuTiposPrecio.precioOferta:
                     tmpItem.MontoPrecio = item.MontoPrecioOferta ?? 0;
                     break;
             }
             if (setting.CajaActualPrecioPrdet as EnuTiposPrecio === EnuTiposPrecio.precioManual)
                 tmpItem.MontoPrecio = item.MontoPrecio1 ?? 0;
         }
         else
             tmpItem.MontoPrecio = item.MontoPrecio1 ?? 0;
         tmpItem.MontoPrecioDivisa = item.MontoPrecioDivisa ?? 0;
         tmpItem.PuntosAplicados = item.PuntosCantidad ?? 0;
         tmpItem.Comentario = "";
         tmpItem.Mensaje = "";
         tmpItem.CantidadImpreso = 0;
         tmpItem.esDevolucion = 0;
         tmpItem.esImpreso = 0;
         tmpItem.esDespachado = 0;
         tmpItem.id_SectorImpresion = item.id_sector_impresion ?? 0; */
        // editItem(tmpItem);

    }
    const crearExtra = (extra: any, cantidad: number, tipo: enuTipoExtra): any => {
        let tmpExtra = new pedidos_detalle_modificadores;
        let Precio: number = 0;
        let PrecioDivisa: number = 0;
        tmpExtra.Nombre = extra.Descripcion ? extra.Descripcion : extra.Nombre;
        switch (tipo) {
            case enuTipoExtra.Acompanante:
                tmpExtra.id_TipoAcompanante = extra.ID;
                Precio = !Number.isNaN(Number(extra.MontoPrecio1)) ? extra.MontoPrecio1 ? +extra.MontoPrecio1 : 0 : 0;
                PrecioDivisa = !Number.isNaN(Number(extra.MontoPrecioDivisa1)) ? extra.MontoPrecio1 ? +extra.MontoPrecioDivisa1 : 0 : 0;
                break;
            case enuTipoExtra.Opcion:
                tmpExtra.id_TipoModificador = extra.ID;
                Precio = !Number.isNaN(Number(extra.MontoPrecio)) ? extra.MontoPrecio ? +extra.MontoPrecio : 0 : 0;
                PrecioDivisa = !Number.isNaN(Number(extra.MontoPrecioDivisa)) ? extra.MontoPrecio ? +extra.MontoPrecioDivisa : 0 : 0;
                break;
            case enuTipoExtra.Extra:
                tmpExtra.id_ModificadorProducto = extra.ID;
                Precio = !Number.isNaN(Number(extra.MontoPrecio1)) ? extra.MontoPrecio1 ? +extra.MontoPrecio1 : 0 : 0;
                PrecioDivisa = !Number.isNaN(Number(extra.MontoPrecioDivisa1)) ? extra.MontoPrecio1 ? +extra.MontoPrecioDivisa1 : 0 : 0;
                break;
        }
        tmpExtra.MontoPrecioDivisa = PrecioDivisa;
        tmpExtra.MontoPrecio = Precio;
        tmpExtra.FechaActual = new Date();
        tmpExtra.CantidadTotal = cantidad;
        tmpExtra.TasaIVA = setting?.Impuestos ? setting.Impuestos.find(x => x.ID == extra.id_TipoImpuesto)?.MontoTasa ?? 0 : 0;
        tmpExtra.id_TipoImpuesto = extra.id_TipoImpuesto;
        return tmpExtra;
    }
    //#endregion
    const onClickPedir = () => {
        let itemPedido: ItemPedido = new ItemPedido(setting);
        let extrasItemPedido: pedidos_detalle_modificadores[] = [];
        let acompItemPedido: pedidos_detalle_modificadores[] = [];
        let tipomItemPedido: pedidos_detalle_modificadores[] = [];

        if (item)
            itemPedido = crearItemPedido(item, count) as ItemPedido;
        if (acompCount)
            acompCount.forEach(el => {
                let ex: any = acompList.find(x => x.ID === el.id);
                if (ex && el.count > 0) {
                    if (!acompItemPedido)
                        acompItemPedido = [];
                    acompItemPedido.push(crearExtra(ex, el.count, enuTipoExtra.Acompanante));
                }
            });
        if (tipomCount)
            tipomCount.forEach(el => {
                let ex = tipomList.find(x => x.ID === el.id);
                if (ex && el.count > 0) {
                    if (!tipomItemPedido)
                        tipomItemPedido = [];
                    tipomItemPedido.push(crearExtra(ex, el.count, enuTipoExtra.Opcion));
                }
            });
        if (extrasCount)
            extrasCount.forEach(el => {
                let ex: any = modifList.find(x => x.ID === el.id);
                if (ex && el.count > 0) {
                    if (!extrasItemPedido)
                        extrasItemPedido = [];
                    extrasItemPedido.push(crearExtra(ex, el.count, enuTipoExtra.Extra));
                }
            });
        if (acompItemPedido && acompItemPedido.length > 0)
            acompItemPedido.forEach(aip => {
                itemPedido.editExtra(aip);
            });
        if (tipomItemPedido && tipomItemPedido.length > 0)
            tipomItemPedido.forEach(eip => {
                itemPedido.editExtra(eip);
            });
        if (extrasItemPedido && extrasItemPedido.length > 0)
            extrasItemPedido.forEach(eip => {
                itemPedido.editExtra(eip);
            });
        itemPedido.Comentario = mensaje;
        setMessage("");
        handleResponse(itemPedido);
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const childDialogRes = () => {
        setOpenSDialog(false);
    }

    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    //#endregion
    useEffect(() => {
        // setMessage("modifSelected.Nombre as string");
    }, [extrasCount, acompCount]);
    useEffect(() => {
        sumarTotales();
    }, [count, totalExtras, totalOpciones, totalAcompanantes]);
    useEffect(() => {
        if (item && item.id_tipo_producto) {
            loadList(item.ID);
        }
        if (item) {
            if (item.MontoPrecioDivisa && item.MontoPrecioDivisa > 0) {
                setprecio(+item.MontoPrecioDivisa);
                setsimbolo("$");
                setprecioTotal(+item.MontoPrecioDivisa);
            }
            else {
                setprecio(0);
                setsimbolo("");
                setprecioTotal(0);
            }
        }

    }, [])
    const convertImage = (item: string | undefined): string => {
        let s: string = item ? item.toString() : "";
        if (s.includes('[{' && '}]')) {
            try {
                s = imgtoJson(s);
                s = s.replace("http://localhost:8080/dl/sushibarstrike_v1_UD4u/", "http://sushibarstrike.com.ve/imagenes/");
            } catch (error) {

            } finally {
                return s;
            }
        } else return s;
    }
    return (
        <>
            <Box className={classes.root}>
                <div className="d-flex position-relative flex-column h-100" style={{ backgroundColor: `rgb(${ColorsDPana.white[0]}, ${ColorsDPana.white[1]}, ${ColorsDPana.white[2]})` }}>
                    <div className={classes.top}>
                        <div className={classes.contentTop}>
                            <div className={classes.contentBack}>
                                <Button className={classes.btnTop} onClick={() => { handleResponse(undefined); }}>
                                    <Close className={classes.btnTopIco} />
                                </Button>
                            </div>
                            <div className={classes.contentCart}>
                                <Button className={classes.btnTop} onClick={() => { history.push("/cart"); }}>
                                    <ShoppingCart className={classes.btnTopIco} />
                                </Button>
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <img onError={addDefaultSrcOnError} className={classes.imgItem} src={item ? convertImage(item.ImagenWeb) : addDefaultImageComida()} />
                        </div>
                        <div className={classes.rowDesc}>
                            <div className={classes.contentName + " w-100 d-flex justify-content-between align-items-center"}>
                                <Typography className={classes.txtName}> {item ? item.Nombre ? item.Nombre : "Producto Sin Nombre" : "Producto no Seleccionado"}</Typography>
                                <Typography className={classes.txtName}>
                                    <span>{simbolo}
                                        <span className="px-1">{precio ? precio.toFixed(2) : "0.00"}</span>
                                    </span>
                                </Typography>
                            </div>
                            <div className={classes.contentDesc + " px-2"}>
                                <Typography className={classes.txtDesc}>{item ? item.DescripcionWeb ? item.DescripcionWeb : "" : ""}</Typography>
                                <div className="item-detail-contentitem" >
                                    <div className="w-100 text-end px-2" style={{ color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})` }}>
                                        <Typography className={"item-detail-txttotal mx-1"}>
                                            <span style={count > 0 ? { fontWeight: 600 } : { fontWeight: 400 }}>{" " + simbolo}
                                                <span className="px-1">{precioTotal ? precioTotal.toFixed(2) : "0.00"}</span>
                                            </span>
                                        </Typography>
                                    </div>
                                    <div className="item-detail-containercant">
                                        <Button className={classes.btn + " item-detail-btncant"} style={mstyle} onClick={() => { uptodown(false) }} > <Remove /></Button>
                                        <div className={classes.cant + " item-detail-txtcant"} style={mstyle}>
                                            <Typography >{count}</Typography>
                                        </div>
                                        <Button className={classes.btn + " item-detail-btncant"} style={mstyle} onClick={() => { uptodown(true) }}> <Add /></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Content + " w-100 h-100 overflow-scroll position-relative"}>
                        {tipopSelected ?
                            <>
                                {tipopSelected.esAcompanantes ?
                                    acompList ?
                                        acompList.length ?
                                            <AcompanantesDlg esMultiAcompanantes={esmultiAcomp as boolean} titulo={tipopSelected.LabelAcompanantes} onAcompChange={handleAcompchange} count={count} items={acompList} settotalAcompanantes={settotalAcompanantes} />
                                            : ""
                                        : ""
                                    : ""
                                }
                                {tipomList ?
                                    tipomList.length ?
                                        <TipoOpcionesItemsDlg esMultiOpciones={false} handleResponse={selectOpcion} titulo={tipopSelected.LabelTipoModificador} onTipomChange={handleTipoModifchange} count={count} items={tipomList} settotalOpciones={settotalOpciones} />
                                        : ""
                                    : ""
                                }
                                {modifList ?
                                    modifList.length ?
                                        <ExtraItemsDlg esMultiExtras={true} ref={extraRef} onExtrasChange={handleExtrachange} titulo={tipopSelected.LabelModificador} items={modifList} max={tipopSelected.limite_ingr ? tipopSelected.limite_ingr : 0} totalItems={count} esLimite={esLimite} settotalExtras={settotalExtras} />
                                        : ""
                                    : ""
                                }
                            </>
                            : ""
                        }
                        <ObservacioneDlg mensaje={mensaje} setMensaje={setMensaje} />
                    </div>
                    <div className=" w-100 p-1 d-flex justify-content-center align-items-center" >
                        <BotonPedir className="item-detail-btnpedir" onClick={() => { onClickPedir(); }}>
                            <Typography >Añadir a Carrito</Typography>
                        </BotonPedir>
                    </div>
                </div >
            </Box >
            <SimpleDialog selectedValue={"selectedValue"} ThemeColor={ColorsDPana.primary} open={openSDIalog} setOpen={setOpenSDialog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
        </>
    )
}
