import axios from 'axios';
import { useHistory } from "react-router-dom";
import { cajas } from "../cajas";
import { estaciones_sesiones } from "../estaciones_sesiones";
import { usuarios } from "../usuarios";
import { enuTiposEstacionPOS, enuTiposPedirDatosCliente, EnuTiposPrecio, enuTipoUsuario, enuTipoUsuarioNivel } from "../../utils/enums";
import { empresas } from "../empresas";
import { tipoimpuestos } from "../tipoimpuestos";
import { empresas_config } from "../empresas_config";
import { estaciones } from "../estaciones";
import { sectores_impresion } from "../sectores_impresion";
import { estaciones_dispositivos_impresora } from "../estaciones_dispositivos_impresora";
import { AppDatabase, IDBConfig } from "./dbDexie";
import { useState } from 'react';
import { config } from '../../utils/configs';
import { mesas } from '../mesas';
import { empleados } from '../empleados';
import { syncs } from '../syncs';
import CrudOrdenService, { tipoSync } from '../../services/crudorden.service';
import { correlativos } from '../correlativos';
import SimpleToast from '../../components/simple-toast';
import SpinnerLoading from '../../components/spinner-loading';
import SimpleDialog from '../../views/dialogs/simple-dialog';
import { LoginToken } from '../../contexts/login-context';
import { tipomonedas } from '../tipomonedas';
function LoadSetting(step: number) {
    const history = useHistory();
    history.push(`/setting/${step}`);
}

export default class SettingsApp {
    //Variables Globales
    // DataBase Connection
    private static readonly FOURTYFIVE_MINUTES = 30 * 60 * 1000;
    private static readonly ONE_HOUR = 60 * 60 * 1000;
    private static readonly ONE_DAY = 24 * 60 * 60 * 1000;
    private static readonly SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
    private static readonly THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
    // Instantiate it
    dbDexie = new AppDatabase();
    idEstacion?: number;
    idCaja?: number;

    ConnectionString!: string;
    ListaConexiones: string[] = [];

    //Impuestos
    Impuestos!: tipoimpuestos[];
    ImpuestoPpal?: tipoimpuestos
    esIVAIncluido: boolean = true;

    //Empresa
    EmpresaActual!: empresas;
    EmpresaActualConfig!: empresas_config;

    //Sesion
    SesionActual!: estaciones_sesiones;  //Buscar en el LocalStorage o Cookies primero

    //Estacion
    EstacionActual!: estaciones;
    public esImprimirEmpleado: boolean = false;
    public esImprimirRefPedido: boolean = false;
    public esImprimirRefFactura: boolean = false;
    public esImprimirNombreCaja: boolean = false;
    public lblRefPedido: string = "";
    public lblEmpleado: string = "";
    public lblNombreCaja: string = "";
    public lblRefFactura: string = "";
    //Caja
    CajaActual?: cajas | undefined;
    TipoPrecioActual: number = EnuTiposPrecio.precioMaximo;

    // User
    UsuarioActual?: usuarios | undefined;
    bLoggedIn: boolean = false; //Revisar Cookies o localstorage
    //Empleado
    EmpleadoActual?: empleados | undefined;
    //Mesa
    MesaActual?: mesas | undefined;
    // Impresoras
    ImpresorasComanda?: sectores_impresion[] | undefined;
    ImpresoraFiscal?: estaciones_dispositivos_impresora | undefined;

    // User Settings
    UserSettingsID!: number;
    Color!: string;
    FontSize!: number;
    esImgProductos: boolean = false;
    esImgProveedores: boolean = false;
    esImgCategorias: boolean = false;
    esImgSubCategorias: boolean = false;
    TasaDivisa!: number;
    MonedasActuales: tipomonedas[] = [];
    MonedaPrincipal?: tipomonedas;

    correlativos!: correlativos;
    loginToken!: LoginToken;
    lastSyncs!: syncs[];

    //Area Mesas 
    idAreaSelected: number = 0;
    idPedidoActual?: number;

    /* async function test() {
        var id = await db.tasks.put({ date: Date.now(), description: 'Test Dexie', done: 0 });
        console.log("Got id " + id);
        // Now lets add a bunch of tasks
        await db.tasks.bulkPut([
            { date: Date.now(), description: 'Test Dexie bulkPut()', done: 1 },
            { date: Date.now(), description: 'Finish testing Dexie bulkPut()', done: 1 }
        ]);
       
        // Ok, so let's query it
        var tasks = await db.tasks.where('done').above(0).toArray();
        console.log("Completed tasks: " + JSON.stringify(tasks, 0, 2));
    
        // Ok, so let's complete the 'Test Dexie' task.
        await db.tasks
            .where('description')
            .startsWithIgnoreCase('test dexi')
            .modify({ done: 1 });
    
        console.log("All tasks should be completed now.");
        console.log("Now let's delete all old tasks:");
    
        // And let's remove all old tasks:
        await db.tasks
            .where('date')
            .below(Date.now())
            .delete();
    
        console.log("Done.");
    
    
        // Find some old friends
        const oldFriends = await db.friends
            .where('age').above(75)
            .toArray();
    
        // or make a new one
        await db.friends.add({
            name: 'Camilla',
            age: 25,
            street: 'East 13:th Street',
            picture: await getBlob('camilla.png')
        });
    }
    const db = new Dexie("FriendDatabase");
    db.version(1).stores({ friends: "++id,name,age" });

    db.transaction('rw', db.friends, async() => {
    // Make sure we have something in DB:
    if ((await db.friends.where({name: 'Josephine'}).count()) === 0) {
        const id = await db.friends.add({name: "Josephine", age: 21});
        alert (`Addded friend with id ${id}`);
    }
    // Query:
    const youngFriends = await db.friends.where("age").below(25).toArray();
    // Show res:
    alert ("My young friends: " + JSON.stringify(youngFriends));
}).catch(e => {
    alert(e.stack || e);
});
    test().catch(err => {
        console.error("Uh oh! " + err.stack);

          if (this.dbDexie.Config) {
            console.log("Version", this.dbDexie.verno);
            console.log("Tables", this.dbDexie.tables.map(({ name, schema }) => ({
                name,
                schema
            })));
            this.dbDexie.open()
                .catch(err => {
                    console.error(`Open failed: ${err.stack}`);
                })
            var idbConfig = this.dbDexie.Config.where('id').equals(1);
            console.log("Completed tasks: " + JSON.stringify(idbConfig));
        }
        else {
            //Solicitar COntraseña Administrador y abrir pagina config
              this.dbDexie.friends.add({ name: "Josephine", age: 21 }).then(function () {
                  return this.dbDexie.friends.where("age").below(25).toArray();
              }).then(function (youngFriends) {
                  alert("My young friends: " + JSON.stringify(youngFriends));
              }).catch(function (e) {
                  alert("Error: " + (e.stack || e));
              });
            this.dbDexie.table('config').put({ id: 1, id_Estacion:, id_Caja:, id_lastSession:, id_lastUser:, id_Licencia:, LastFecha:  });
        } 
    }); */
    constructor() {

        this.dbDexie.transaction('rw', this.dbDexie.Config_dx, async () => {
            if ((await this.dbDexie.Config_dx.where({ id: 1 }).count()) === 0) {
                const id = await this.dbDexie.Config_dx.add({ id: 1, id_Estacion: '1', id_Caja: '1', id_lastSession: '1', id_lastUser: '1', id_Licencia: '1', LastFecha: (new Date()).toLocaleDateString() });
            }
            else {
                var config = await this.dbDexie.Config_dx.where('id').equals(1).toArray();
                // const config = await this.dbDexie.Config.where({ id: 1 })[0];
                this.idEstacion = config[0].id_Estacion as unknown as number;
                this.idCaja = config[0].id_Caja as unknown as number;
                // alert("Config: " + JSON.stringify(config));
                this.LoadData();
                // LoadSetting(0);
            }
        }).catch(e => {
            alert(e.stack || e);
        });
        this.getLastSync();

    }

    async LoadData() {
        await axios.post(config.SERVER_URI + '/tipoimpuestos/list')
            .then(res => {
                if (res.status === 200) {
                    this.Impuestos = res.data as unknown as tipoimpuestos[];
                    if (this.Impuestos) {
                        this.ImpuestoPpal = this.Impuestos?.find(x => x.Descripcion.toLowerCase().includes('grabable') || x.Nomenclatura?.toLowerCase().includes('iva g'));
                    }
                } else
                    this.loadToast("No se cargaron Impuestos")
            })
            .catch(e => {
                this.loadToast('err-->LoafImpresoras' + e);
            });

        await axios.post(config.SERVER_URI + '/empresas/get', {
            data: { id: 1 },
            options: {
                include: ["id_Licencia_licencia", "empresas_configs"]
            }
        })
            .then(res => {
                if (res.status === 200) {
                    this.EmpresaActual = res.data as empresas;
                    if (this.EmpresaActual) {
                        if (this.EmpresaActual.empresas_configs && this.EmpresaActual.empresas_configs.length > 0)
                            this.esIVAIncluido = this.EmpresaActual.empresas_configs[0].esIVAIncluido as unknown as boolean;
                        if (this.idEstacion && this.idCaja) {
                            axios.post(config.SERVER_URI + '/estaciones/get', {
                                data: { id: this.idEstacion },
                                options: {
                                    include: ["cajas", "estaciones_sesiones"]
                                }
                            })
                                .then(res => {
                                    if (res.status == 200) {
                                        this.EstacionActual = res.data;
                                        if (this.EstacionActual && this.EstacionActual.cajas && this.EstacionActual.cajas.length > 0) {
                                            this.CajaActual = this.EstacionActual.cajas[0];
                                            if (this.CajaActual) {
                                                if (!this.CajaActual.esAbierta) {
                                                    this.loadToast('inf-->Por favor iniciar Caja')
                                                } else {

                                                }
                                            } else {
                                                this.loadToast('Caja no definida');
                                            }
                                        }
                                    } else {
                                        this.loadToast('Estación no Definida');
                                    }
                                })
                                .catch(e => {
                                    this.loadToast('err-->LoadEstaciones ' + e);
                                });
                        }
                        else {
                            this.loadToast('Estación no Definida');
                        }
                    }
                } else {
                    this.loadToast('Empresa no Definida');
                }
            })
            .catch(e => {
                this.loadToast('err-->LoadEmpresas ' + e);
            });
        this.getLastSync();
    }

    async getCorrelativos(): Promise<correlativos> {
        this.correlativos = await CrudOrdenService.getCorrelativos(this.loginToken, this) as correlativos;
        return this.correlativos;
    }

    /**
     * Optiene los ultimos syncs
     */
    async getLastSyncbyTipo(tipoDato: tipoSync): Promise<number> {
        return await CrudOrdenService.getSync(tipoDato) as number
    }
    /**
     * Optiene los ultimos syncs
     */
    async getLastSync() {
        let res: syncs[];
        res = await CrudOrdenService.getSyncList() as syncs[];
        this.lastSyncs = res;
        return res;
    }
    /**
     * Optiene los ultimos syncs
     */
    async setLastSync(tipoDato: tipoSync, sync_id: number) {
        let res: syncs[] = await CrudOrdenService.updateSync(tipoDato, sync_id, this.loginToken) as unknown as syncs[];
        this.lastSyncs = res;
        return res;
    }
    getImpuestos() {

    }
    getTasaIVA(id: number | undefined): number {
        if (!id) {
            this.loadToast("wrn-->Definir Impuesto a Producto")
            return 0
        }
        if (this.Impuestos)
            return this.Impuestos.find(x => x.ID == id)?.MontoTasa ?? 0;
        else {
            this.loadToast("wrn-->No se cargaron Impuestos")
            return 0;
        }
    }
    async setCajaPrecioPredet(tipoprecio: number): Promise<number> {
        if (this.CajaActual) {
            await axios.post(config.SERVER_URI + '/cajas/update', {
                ID: this.loginToken.ID,
                hash: this.loginToken.hash,
                data: { id: this.CajaActual.ID, id_defTipoPrecioPredet: tipoprecio }
            })
                .then(res => {
                    if (res.status == 200) {
                        return res.data.id_defTipoPrecioPredet;
                    } else {
                        this.loadToast('No set PrecioPredeterminado');
                    }
                })
                .catch(e => {
                    this.loadToast('err-->setPrecioPred ' + e);
                    return 1
                });
        }
        return 1;
    }
    getPrecio(item: any, esPermiteManual: boolean = false): number {
        let tp: number = 0;
        if (!this.CajaActual) {
            this.setCajaPrecioPredet(1).then(res => {

            })
        }
        tp = this.CajaActual && this.CajaActual.id_defTipoPrecioPredet ? this.CajaActual.id_defTipoPrecioPredet : 1;
        switch (tp) {
            case EnuTiposPrecio.precioDivisa:
                if (item.MontoPrecioDivisa)
                    return item.MontoPrecioDivisa ?? 0;
                if (item.MontoPrecioDivisa1)
                    return item.MontoPrecioDivisa1 ?? 0;
                break;
            case EnuTiposPrecio.precioMaximo:
                if (item.MontoPrecio1)
                    return item.MontoPrecio1 ?? 0;
                if (item.MontoPrecio)
                    return item.MontoPrecio ?? 0;
                break;
            case EnuTiposPrecio.precioMayor:
                if (item.MontoPrecio2)
                    return item.MontoPrecio2 ?? 0;
                break;
            case EnuTiposPrecio.precioMinimo:
                if (item.MontoPrecio3)
                    return item.MontoPrecio3 ?? 0;
                break;
            case EnuTiposPrecio.precioOferta:
                if (item.MontoPrecioOferta)
                    return item.MontoPrecio2 ?? 0;
                break;
            case EnuTiposPrecio.precioManual:
                if (!esPermiteManual) {
                    if (item.MontoPrecio1)
                        return item.MontoPrecio1 ?? 0;
                    if (item.MontoPrecio)
                        return item.MontoPrecio ?? 0;
                }
        }
        this.loadToast("🤐PrecioPredet Inválido")
        return 0;
    }

    unloadToast() {
        this.loadToast("");
    }
    loadToast(message: string) {
        return (<SimpleToast title="Mensaje Aplicación" message={message} setMessage={this.unloadToast} />)
    }
    loadLoading(textloading: string, loading: boolean) {
        return (<SpinnerLoading tipo='spinner' open={loading} text={textloading} />)
    }
    handleCloseSimpleDialog() {
        this.loadDialog(false);
    }
    loadDialog(open: boolean, titulo: string = "", children: any = undefined) {
        return (<SimpleDialog selectedValue={"selectedValue"} setOpen={undefined} open={open} onClose={this.handleCloseSimpleDialog} children={children} title={titulo} />)
    }
    IniciarSesion() {
        //Login Usuario
        //Verificarr Estacion ID
        //Si Caja Cerrada 
        //Abrir sesion 
    }


}


