import { TextareaAutosize } from '@mui/material';
import { useState } from 'react';
import "../../style.css";
interface Props {
    mensaje?: string;
    setMensaje?: any;
}
export default function ObservacionesItemDlg({ mensaje, setMensaje }: Props) {
    const [value, setValue] = useState(mensaje);
    const handleChange = (msj: string) => {
        setValue(msj);
        setMensaje(msj);
    }
    return (
        <div className="item-detail-root">
            <TextareaAutosize className="item-detail-textarea" value={value} onChange={(e) => { handleChange(e.target.value) }} aria-label="empty textarea" placeholder="Escribe algo adicional!" />
        </div>
    )
}
