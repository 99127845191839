import { Typography, Button, Popover } from '@mui/material';
import { Add, DeleteOutline, Chat, ChevronRightOutlined, DeleteForever, LabelOutlined, Remove } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react'
import { ColorsDPana } from '../../../utils/Color';
import "../style.css";
import { addDefaultSrcOnError } from '../../../utils/helpers';
import { ItemPedido } from '../../../models/Entities/Orden.model';
import { formatNumber } from 'fast-number-formatter'
import { pedidos_detalle_modificadores } from '../../../models/pedidos_detalle_modificadores';
import { enuStatusPedidoDetalle, enuTipoExtra } from '../../../utils/enums';

const useStyles: any = makeStyles({
    root: {
        backgroundColor: `rgb(${ColorsDPana.secundary[0]}, ${ColorsDPana.secundary[1]}, ${ColorsDPana.secundary[2]})`,
        color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
        borderColor: `rgba(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]},0.8)!important`,
        borderRadius: '1em',
        border: 'solid 0.1em',
    },
    btn: {
        color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
        backgroundColor: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
        "&:hover": {
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
            fontWeight: 800,
        },
    },
    btnDelete: {
        color: `rgb(${ColorsDPana.rojo[0]}, ${ColorsDPana.rojo[1]}, ${ColorsDPana.rojo[2]})!important`,
        minWidth: '30px !important',
        "&:hover": {
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
            fontWeight: 800,
        },
    },
    cant: {
        color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
    }


});
interface Props {
    Item?: ItemPedido;
    style?: any;
    handleUptoDown?: any;
    handleRemove?: any;
}
export function BasicPopover() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                Open Popover
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
            </Popover>
        </div>
    );
}

export default function ItemCart({ handleRemove, handleUptoDown, Item, style }: Props) {

    const classes = useStyles();

    const clsStatusPedido = () => {
        if (Item) {
            switch (Item.id_defTipoStatusPedido) {
                case enuStatusPedidoDetalle.SinProcesar:
                    return " opacity-100";
                case enuStatusPedidoDetalle.DespachoCocina:
                    return " bg-success opacity-50";
                case enuStatusPedidoDetalle.PedidoNuevo:
                case enuStatusPedidoDetalle.Procesado:
                    return " opacity-50";
                case enuStatusPedidoDetalle.ProcesadoDevuelto:
                    return " bg-dark bg-opcity-50 opacity-50";
                case enuStatusPedidoDetalle.Recibido:
                    return " bg-warning bg-opcity-50 opacity-50";
                case enuStatusPedidoDetalle.RecibidoCocina:
                    return " bg-warning bg-opcity-50 opacity-50";
                case enuStatusPedidoDetalle.RecibidoDevuelto:
                    return " bg-danger opacity-50";
            }
        }
    }
    const mstyle: React.CSSProperties = {
        ...style
    }
    useEffect(() => {

    }, []);
    const CrearExtras = (ex: pedidos_detalle_modificadores, index: number) => {
        if (ex.Nombre?.includes("*"))
            return;
        let Display: string = "";
        if (ex.Nombre)
            Display = ex.Nombre;
        /*  if (ex.Descripcion)
             Display = ex.Descripcion; */
        Display = `${ex.CantidadTotal && ex.CantidadTotal > 1 ? ex.CantidadTotal + 'x' : ''}${Display} ${ex && ex.MontoPrecioDivisa && +ex.MontoPrecioDivisa > 0 ? formatNumber(ex?.MontoPrecioDivisa as number, 2) : ''}`;
        let cls: string = "";
        let tipo: enuTipoExtra = 0;
        if (ex.id_TipoModificador)
            tipo = enuTipoExtra.Opcion
        if (ex.id_TipoAcompanante)
            tipo = enuTipoExtra.Acompanante
        if (ex.id_ModificadorProducto)
            tipo = enuTipoExtra.Extra
        let icon;
        switch (tipo) {
            case enuTipoExtra.Acompanante:
                cls = "text-start border-start border-5 border-dark bg-black opacity-75   font-monospace my-1 p-1 "
                break;
            case enuTipoExtra.Opcion:
                cls = "text-start bg-body opacity-50 text-black font-monospace my-0 p-1"
                break;
            case enuTipoExtra.Extra:
                cls = "text-start  alert-danger opacity-50 font-monospace my-0 p-0"
                icon = <LabelOutlined />
                break;
        }
        return (
            <Typography key={index} className={cls + " item-cart-txtitemextra "}> {tipo == enuTipoExtra.Extra ? <> <ChevronRightOutlined /> {Display}</> : Display}</Typography>
        )
    }
    return (
        <div className={classes.root + " item-cart-contentitem m-2 p-2" + clsStatusPedido()} >
            <div className="d-flex ">
                <div className="item-cart-containerimage">
                    <img onError={addDefaultSrcOnError} src={Item?.Imagen} className={"item-cart-image d-block lazyloaded"} loading='lazy' />
                </div>
                <div className="item-cart-containertxt mx-1 d-grid row card-body ">
                    <Typography className="text-white item-cart-txtitem m-0 p-1"  >{Item?.Descripcion}
                        <span className="px-1">
                            {(Item?.CantidadTotal && Item.CantidadTotal > 1) || Item?.Extras ? ` ($ ${formatNumber(Item?.MontoPrecioDivisa as number, 2)}c/u)` : ""}
                        </span>
                    </Typography>
                    {Item?.Extras
                        ? Item.Extras.map((ex: any, index: number) => {
                            return (CrearExtras(ex, index))
                        })
                        : ""
                    }
                    {Item?.Comentario ?
                        <Typography className="alert-warning border-start border-5 border-warning p-1" sx={{ p: 2 }}><Chat /> {Item?.Comentario}</Typography>
                        : ''
                    }
                </div>
            </div>
            <Typography className="item-cart-txttotal mx-1"  ><span>$<span className={"px-1"}>{formatNumber(Item?.MontoTotal as number, 2)}</span></span></Typography>
            <div className=" item-cart-containercant">
                <Button disabled={Item?.CantidadTotal == 0 ? true : false} className={classes.btn + " mx-1 item-cart-btncant"} style={mstyle}
                    onClick={() => {
                        /*  if ((Item?.CantidadTotal && Item?.CantidadTotal <= 1) || Item?.Extras)
                             handleRemove(Item)
                         else */
                        handleUptoDown(Item, false);
                    }} >
                    {Item?.CantidadTotal
                        ? Item?.CantidadTotal == 1 || Item?.Extras
                            ? <DeleteForever />
                            : <Remove />
                        : <DeleteOutline className='opacity-25' />
                    }
                </Button>
                <div className={classes.cant + " item-cart-txtcant"} style={mstyle}>
                    <Typography variant='h5' component='div' className="text-white">{Item?.CantidadTotal}</Typography>
                </div>
                <Button className={classes.btn + " mx-1 item-cart-btncant"} style={mstyle} onClick={() => { handleUptoDown(Item, true); }}> <Add /></Button>
                {Item?.CantidadTotal && Item?.CantidadTotal > 1 && Item.Extras?.length == 0
                    ? <Button className={classes.btnDelete} onClick={() => { handleRemove(Item); }} > <DeleteForever /></Button>
                    : <> </>
                }

            </div>
        </div >
    )
}