import { Paper, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect } from 'react'
import Orden, { ItemPedido } from '../../models/Entities/Orden.model';
import { ColorsDPana } from '../../utils/Color';
import ItemCartComponent from './item-cart';
import "./style.css";
interface Props {
    PedidoActual?: Orden;
    setPedidoActual?: any;
    handleUptoDown?: any;
    handleRemove?: any;
}

const useStyles: any = makeStyles((theme: Theme) => createStyles({
    root: {
        position: "relative",
        marginTop: "75px !important",
        padding: 5,
        backgroundColor: 'transparent !important',
    },
    contentItems: {
        width: "100%",
        justifyContent: 'center',
        margin: 'auto',
    }
}));

export default function CartItemsComponent({ handleRemove, handleUptoDown, PedidoActual, setPedidoActual }: Props) {
    const classes = useStyles();
    useEffect(() => {
    }, []);
    const crearPedidoActual = (item: ItemPedido, index: number) => {
        return (
            <ItemCartComponent handleRemove={handleRemove} handleUptoDown={handleUptoDown} key={index} Item={item}></ItemCartComponent>
        )
    }
    return (
        <Paper className={"item-cart-root"} sx={{ p: 2, margin: 'auto', flexGrow: 1 }}>
            {PedidoActual?.Items ?
                PedidoActual.Items.map((i, index) => {
                    return (crearPedidoActual(i, index))
                })
                : ""
            }
        </Paper>
    )
}