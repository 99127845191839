import React, { } from 'react'
import { Badge, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import "./style.css";
import { ColoresDPana, ColorsDPana } from '../../../utils/Color';
import { Facebook, Home, HomeOutlined, Info, Instagram, LocationOnOutlined, MenuOutlined, PersonOutline, ShoppingCartOutlined, VpnKeyOutlined, WhatsApp } from '@mui/icons-material';
import { iconTop } from '../../../models/Entities/iconMenuTop';


interface IconMenuTopProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children?: any;
    className?: string;
    themeColor?: ColoresDPana;
    style?: React.CSSProperties;
    handleClick?: any;
    image?: string;
    icon?: iconTop;
    titulo?: string;
    zIndex?: number;
    Url?: string;
}


const useStyles: any = makeStyles({
    imagen: {
        fontSize: 35,
        margin: "0px 1px auto",
        position: "relative",

    }
});

/* const StyledBadge = withStyles(() =>
    createStyles({
        badge: {
            right: 5,
            top: 3,
            padding: '0 4px',
            backgroundColor: "#000"
        },
    }),
)(Badge); */


export default function IconMenuTop({ image, icon, titulo, zIndex, Url, handleClick, children, className, themeColor = ColorsDPana.sky_blue, style, ...restOfProps }: IconMenuTopProps) {
    const classes = useStyles();
    return (
        <>
            {icon === "admin"
                ? <PersonOutline titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></PersonOutline>
                : icon === "cart"
                    ? <Badge badgeContent={children} >
                        <ShoppingCartOutlined titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></ShoppingCartOutlined>
                    </Badge>
                    : icon === "facebook"
                        ? <Facebook titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></Facebook>
                        : icon === "home"
                            ? <HomeOutlined titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></HomeOutlined>
                            : icon === "instagram"
                                ? <Instagram titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></Instagram>
                                : icon === "location"
                                    ? <LocationOnOutlined titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></LocationOnOutlined>
                                    : icon === "login"
                                        ? <VpnKeyOutlined titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></VpnKeyOutlined>
                                        : icon === "menu"
                                            ? <MenuOutlined titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></MenuOutlined>
                                            : icon === "user"
                                                ? <PersonOutline titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></PersonOutline>
                                                : icon === "whatsapp"
                                                    ? <WhatsApp titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></WhatsApp>
                                                    : icon === "info"
                                                        ?
                                                        <Info titleAccess={titulo} className={classes.imagen} style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={(e) => { handleClick(e); }} ></Info>
                                                        : <Home style={{ color: `rgb(${themeColor[0]},${themeColor[1]},${themeColor[2]})` }} onClick={() => { handleClick(); }}></Home>

            }

        </>


    )
}


