import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import "./style.css";

export default function RootView() {
    const history = useHistory();
    const loadPage = () => {
        history.push("/home")
    }
    return (
        <>
            {loadPage()}
        </>
    )
}