import { Button, Collapse, Grid, Stack, Typography } from "@mui/material";
import dateFormat from "dateformat";
import { formatNumber } from "fast-number-formatter";
import { forwardRef, useEffect, useState } from "react";
import Orden, { ItemPedido } from "../../../models/Entities/Orden.model";
import { enutipodlgOpc } from "../../../utils/helpers";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Aod, AttachEmail, LocalPrintshop, PointOfSale } from "@mui/icons-material";
import { mesas } from "../../../models/mesas";
import { usuarios } from "../../../models/usuarios";
import { dialogResponse } from "../../../utils/models";
import LoginDialog from "../../dialogs/login-dialog";
import SelectMesa from "./select-mesa";

const MiAlert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface Props {
    handleResponse?: any;
    titulo?: string;
    actionMethod?: any;
    openModal?: boolean;
    handleChange?: any;
    esOK?: boolean;
    setOpen?: any;
    pedido?: Orden;
    esFacturado?: boolean;
}
export default function GenerarPedido(props: Props) {
    const [rows, setRows] = useState(props.pedido?.Items);
    const [aceptar, setAceptar] = useState(false);
    const [message, setMessage] = useState("");
    const [tipoOpc, settipoOpc] = useState<enutipodlgOpc>(enutipodlgOpc.ninguno);

    const crearRow = (e: ItemPedido, index: number) => {
        return (
            <Grid
                key={'row' + index}
                item
                container
                className="p-1 bg-body border-bottom border-decundary"
                xs={12} md={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Grid
                    item
                    xs={12} md={4} >
                    <Typography variant='subtitle2'>{e.Descripcion} </Typography>
                </Grid> <Grid
                    className='text-end'
                    item
                    xs={3} md={2} >
                    <Typography variant='caption'>{formatNumber(e.CantidadTotal ? e.CantidadTotal : 0, 2)} </Typography>
                </Grid> <Grid
                    className='text-end'
                    item
                    xs={3} md={3} >
                    <Typography variant='caption'>{formatNumber(e.MontoPrecioDivisa ? e.MontoPrecioDivisa : 0, 2)} </Typography>
                </Grid>
                <Grid
                    className='text-end'
                    item
                    xs={3} md={3} >
                    <Typography variant='subtitle2'>{formatNumber(e.MontoTotal, 2)} </Typography>
                </Grid>
            </Grid>
        )
    }
    const handleOK = (res: dialogResponse) => {
        if (props.setOpen)
            props.setOpen(false);
        props.handleResponse(res);
    }
    const esNoMesa = () => {
        if (props.pedido && props.pedido.Mesa)
            return false;
        else
            return true;
    }
    const esNoMesero = () => {
        if (props.pedido && props.pedido.Mesero)
            return false;
        else
            return true;
    }
    const esNoItems = () => {
        if (props.pedido && props.pedido.Items && props.pedido.Items.length > 0 && props.pedido.MontoTotal && props.pedido.MontoTotal > 0)
            return false;
        else
            return true;
    }
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [Meseroslst, setMeseroslst] = useState<usuarios[]>([]);
    const [Mesaslst, setMesaslst] = useState<mesas[]>([]);
    const [okbtnMDialog, setokbtnMDialog] = useState<boolean | undefined>(undefined);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [esOK, setesOK] = useState(false);
    const [sDialogOptions, setsDialogOptions] = useState<any>();

    const loginDialogRes = (e: boolean, actionMethod: any) => {
        if (!e)
            setMessage("Acceso no Autorizado!")
        if (actionMethod)
            actionMethod();
        setOpenSDialog(!e);
    }
    const loadLoginDialog = (titulo: string) => {
        setChildrenSDialog(<LoginDialog handleResponse={loginDialogRes} titulo={titulo} esPIN={true} ></LoginDialog>);
        setOpenSDialog(true);
    }


    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    //#endregion

    const handleClickSelect = (opc: any) => {
        settipoOpc(opc)
        switch (opc) {
            case enutipodlgOpc.cliente:
                setMessage("Codigo para seleccionar cliente")
                break
        }

        // setOpen(true)
    }
    useEffect(() => {
        if (esNoItems() || esNoMesero() || esNoMesa()) {
            setAceptar(false)
        } else
            setAceptar(true)
    }, []);


    return (
        <>
            <Grid
                container
                className="p-1 bg-body border-bottom border-decundary"
                xs={12} md={12}
                direction="column"
                justifyContent="flex-start"
                alignItems="center">
                <Grid
                    item
                    container
                    className="p-1 bg-body border-bottom border-decundary"
                    xs={12} md={12}
                    direction="column"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Typography className="text-center w-100 alert-dark rounded-3" variant="subtitle1">
                        {props.titulo}
                    </Typography>
                    <Grid
                        item
                        container
                        xs={12} md={4}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center" >
                        <Typography variant='subtitle2'> {props.pedido?.ID ? "#" + props.pedido.ID + "-" : ""} </Typography>
                        <Typography variant='subtitle2'> {props.pedido?.FechaActual ? dateFormat(props.pedido?.FechaActual, 'dd/mm/yy') : ""} </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12} md={4}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center" >
                        <Grid
                            className='text-end'
                            xs={6} md={3} >
                            <Typography variant='caption'>{'Mesa:'} </Typography>
                        </Grid>
                        <Grid
                            className='text-center alert-dark'
                            xs={6} md={3} >
                            <Typography variant='subtitle2'  >
                                {props.pedido?.Mesa
                                    ? props.pedido?.Mesa.Nombre
                                    : ''
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12} md={4}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center" >
                        <Grid
                            className='text-end'
                            xs={6} md={3} >
                            <Typography variant='caption'>{'Mesero:'} </Typography>
                        </Grid>
                        <Grid
                            className='text-center alert-dark'
                            xs={6} md={3} >
                            <Typography variant='subtitle2'>
                                {props.pedido?.Mesero
                                    ? props.pedido?.Mesero.Nombre
                                    : ''
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12} md={4}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center" >
                        <Grid
                            className='text-end'
                            xs={6} md={3} >
                            <Typography variant='caption'>{'Cliente:'} </Typography>
                        </Grid>
                        <Grid
                            className='text-center alert-dark'
                            xs={6} md={3} >
                            <Typography variant='subtitle2'>
                                {props.pedido?.Cliente
                                    ? props.pedido?.Cliente.Nombres
                                    : <Button className="p-1 bg-gradient m-1 border-0 text-dark" variant='outlined' size='small' onClick={() => { handleClickSelect(enutipodlgOpc.cliente); }
                                    }  >{'Seleccionar'} </Button>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    xs={12} md={12}
                    className="p-0 m-0 border border-dark"
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}>
                    <Grid
                        item
                        container
                        className="p-1 bg-secondary text-white border-bottom border-decundary"
                        xs={12} md={12}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid
                            item
                            xs={3} md={4} >
                            <Typography variant='subtitle1'>{'Desc.'} </Typography>
                        </Grid> <Grid
                            className='text-end'
                            item
                            xs={3} md={2} >
                            <Typography variant='subtitle1'>{'Cant.'} </Typography>
                        </Grid> <Grid
                            className='text-end'
                            item
                            xs={3} md={3} >
                            <Typography variant='subtitle1'>{'Precio'} </Typography>
                        </Grid>
                        <Grid
                            className='text-end'
                            item
                            xs={3} md={3} >
                            <Typography variant='subtitle1'>{'Total'} </Typography>
                        </Grid>
                    </Grid>
                    {rows?.map((e, index) => {
                        return (
                            crearRow(e, index)
                        )
                    })
                    }
                    <Grid
                        item
                        container
                        className="p-1 alert-dark border-bottom border-decundary"
                        xs={12} md={12}
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="center">
                        <Grid
                            item
                            container
                            xs={12} md={4}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center" >
                            <Grid
                                className='text-end'
                                xs={6} md={3} >
                                <Typography variant='caption'>{'Total Base:'} </Typography>
                            </Grid>
                            <Grid
                                className='text-end'
                                xs={6} md={3} >
                                <Typography variant='subtitle2'>{formatNumber(props.pedido?.MontoTotalBase ?? 0, 2)} </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12} md={4}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center" >
                            <Grid
                                className='text-end'
                                xs={6} md={3} >
                                <Typography variant='caption'>{'IVA:'} </Typography>
                            </Grid>
                            <Grid
                                className='text-end'
                                xs={6} md={3} >
                                <Typography variant='subtitle2'>{formatNumber(props.pedido?.MontoTotalIVA ?? 0, 2)} </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12} md={4}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center" >
                            <Grid
                                className='text-end'
                                xs={6} md={3} >
                                <Typography variant='caption'>{'TOTAL:'} </Typography>
                            </Grid>
                            <Grid
                                className='text-end alert-danger'
                                xs={6} md={3} >
                                <Typography variant='h6'>{formatNumber(props.pedido?.MontoTotal ?? 0, 2)} </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Stack sx={{ width: '100%' }} spacing={0.5}>
                        <Collapse in={esNoItems()}>
                            <MuiAlert severity="error">Este Pedido no tiene <strong>Productos</strong></MuiAlert>
                        </Collapse>
                        <Collapse in={esNoMesa()}>
                            <MuiAlert severity="error">Se requiere la <strong>Mesa</strong></MuiAlert>
                        </Collapse>
                        <Collapse in={esNoMesero()}>
                            <MuiAlert severity="error">Se requiere la <strong>Mesero</strong></MuiAlert>
                        </Collapse>
                        {/*  <Collapse in={!props.pedido?.Cliente ? true : false}>
                        <MuiAlert severity="error">Se requiere un <strong>Cliente</strong></MuiAlert>
                    </Collapse> */}
                    </Stack>
                    <Grid
                        className=" w-100 m-0 p-0 bg-transparent"
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"

                    >
                        {!props.esFacturado
                            ? <>
                                <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={6}>
                                    <Button
                                        className=" w-100 my-1"
                                        size='small'
                                        variant='outlined'
                                        onClick={() => {
                                            handleOK('Imprimir');
                                        }}
                                        endIcon={<LocalPrintshop />}>
                                        Imprimir
                                    </Button>
                                </Grid>
                                <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={6}>
                                    <Button
                                        className=" bg-danger w-100  my-1"
                                        size='small'
                                        variant="contained"
                                        onClick={() => {
                                            handleOK('Pedido');
                                        }}
                                        endIcon={<PointOfSale />}
                                    >
                                        Ordenar
                                    </Button>
                                </Grid>
                            </>
                            : <>
                                <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={4}>
                                    <Button
                                        className=" w-100  my-1"
                                        size='small'
                                        variant="outlined"
                                        onClick={() => {
                                            handleOK('Email');
                                        }}
                                        endIcon={<Aod />}
                                    >
                                        Ver Fatcura
                                    </Button>
                                </Grid>
                                <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={4}>
                                    <Button
                                        className=" w-100  my-1"
                                        size='small'
                                        variant="outlined"
                                        onClick={() => {
                                            handleOK('Ver');
                                        }}
                                        endIcon={<AttachEmail />}
                                    >
                                        Enviar Fatcura
                                    </Button>
                                </Grid>
                                <Grid item className="m-0 p-1 bg-transparent text-center" xs={12} sm={4}>
                                    <Button
                                        className=" bg-danger w-100  my-1"
                                        size='small'
                                        variant="contained"
                                        onClick={() => {
                                            handleOK('OK');
                                        }}
                                    >
                                        Aceptar
                                    </Button>
                                </Grid>
                            </>

                        }
                    </Grid>
                </Grid>

            </Grid>

        </>
    );
}
