import { Typography, Button, FormControlLabel } from '@mui/material';
import { Add, Label, Remove } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Dispatch, forwardRef, SetStateAction, useEffect, useImperativeHandle, useState } from 'react'
import { modificadores_producto } from '../../../../../models/modificadores_producto';
import { ColoresDPana, ColorsDPana } from '../../../../../utils/Color';
import "../../style.css";
import { formatNumber } from 'fast-number-formatter';

const useStyles: any = makeStyles({
    btn: {
        color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
        backgroundColor: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,

        "&:hover": {
            color: `rgb(${ColorsDPana.textcolor[0]}, ${ColorsDPana.textcolor[1]}, ${ColorsDPana.textcolor[2]})!important`,
            backgroundColor: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})!important`,
        },
    },
    cant: {
        color: `rgb(${ColorsDPana.primary[0]}, ${ColorsDPana.primary[1]}, ${ColorsDPana.primary[2]})`,
    }
});

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    handleClick?: any;
    item?: modificadores_producto;
    ThemeColor?: ColoresDPana;
    titulo?: string;
    max: number;
    setVan: any;
    setVanGratis: any;
    Van: number;
    VanGratis: number;
    esLimite: boolean;
    handleCount: (id: number, count: number, PrecioTotal: number) => void;
    totalItems: number;
    handleResponse?: any;
}
export const ItemExtraDlg = forwardRef(({ totalItems, esLimite, handleCount, Van, VanGratis, setVanGratis, setVan, max, titulo, item, handleResponse, style }: Props, ref) => {
    const classes = useStyles();
    const [precio, setprecio] = useState<number>(0);
    const [precioTotal, setprecioTotal] = useState<number>(0);
    const [txtprecioTotal, settxtprecioTotal] = useState<string>("");
    const [simbolo, setsimbolo] = useState<any>("");
    const [count, setcount] = useState(0);
    useImperativeHandle(ref, () => ({
        ResetExtras,
    }));
    const ResetExtras = () => {
        setcount(0);
    }
    const mstyle: React.CSSProperties = {
        ...style
    }
    const uptodown = (up: boolean) => {
        let cnt: number = count;
        if (up) {
            let sumar: boolean = false;
            sumar = esLimite ? max == Van ? false : true : true;
            let limiteGratis: number = totalItems < max ? max : totalItems;


            if (esLimite) {
                if (max != Van) {
                    if (!precio) {
                        if (VanGratis < limiteGratis) {
                            cnt++;
                            setVanGratis(VanGratis ? VanGratis + 1 : 1);
                            setVan(Van ? Van + 1 : 1);
                        }
                    } else {
                        cnt++;
                        setVan(Van ? Van + 1 : 1);
                    }
                }
            } else {
                if (!precio) {
                    if (VanGratis < limiteGratis) {
                        cnt++;
                        setVanGratis(VanGratis ? VanGratis + 1 : 1);
                        setVan(Van ? Van + 1 : 1);
                    }
                } else {
                    cnt++;
                    setVan(Van ? Van + 1 : 1);
                }
            }
            setcount(cnt);
        }
        else {
            if (cnt > 0) {
                cnt--;
                setcount(cnt);
                setVan(Van ? Van - 1 : cnt);
                if (!precio) {
                    setVanGratis(VanGratis ? VanGratis - 1 : cnt);
                }
            }
        }
        if (handleCount)
            handleCount(item ? item.ID : 0, +cnt, cnt > 0 ? (+precio * +cnt) : 0);

    }

    useEffect(() => {
        if (item && precio > 0 && count > 0) {
            let total = +precio * +count;
            setprecioTotal(+total);
            settxtprecioTotal(total.toFixed(2));
        } else {
            setprecioTotal(+precio);
            if (precio > 0)
                settxtprecioTotal(precio.toFixed(2));
            else
                settxtprecioTotal("");
        }
    }, [count]);

    useEffect(() => {
        if (item) {
            if (item.MontoPrecioDivisa1 && item.MontoPrecioDivisa1 > 0) {
                let p: number = +item.MontoPrecioDivisa1
                setprecio(p);
                setsimbolo("$");
                setprecioTotal(p);
                settxtprecioTotal(p.toFixed(2))
            }
            else {
                setprecio(0);
                setsimbolo("");
                setprecioTotal(0);
                settxtprecioTotal("")
            }
        }
    }, []);

    return (

        <div className="item-detail-root">
            <div className="item-detail-contentitem" >
                <Typography className={"item-detail-txtitem mx-1 "} style={count > 0 ? { fontWeight: 600 } : { fontWeight: 400 }}> {item ? item.Nombre : ""}
                    <span >
                        {item?.MontoPrecioDivisa1 ? item?.MontoPrecioDivisa1 > 0 ? " " + simbolo : '' : ''}
                        <span className="px-1">{item?.MontoPrecioDivisa1 ? item.MontoPrecioDivisa1 > 0 ? formatNumber(item.MontoPrecioDivisa1 * (count > 0 ? count : 1), 2) : '' : ''}</span>
                    </span>
                </Typography>
                <div className="item-detail-containercant">
                    <Button className={classes.btn + " item-detail-btncant"} style={mstyle} onClick={() => { uptodown(false) }} > <Remove /></Button>
                    <div className={classes.cant + " item-detail-txtcant"} style={mstyle} >
                        <Typography >{count}</Typography>
                    </div>
                    <Button className={classes.btn + " item-detail-btncant"} style={mstyle} onClick={() => { uptodown(true) }}> <Add /></Button>
                </div>

            </div>
        </div>
    )
});
export default ItemExtraDlg;
