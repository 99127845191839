import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react'
import { tipomodificadores } from '../../../../../models/tipomodificadores';
import { ColoresDPana } from '../../../../../utils/Color';
import "../../style.css";

export interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    handleClick?: any;
    item?: tipomodificadores;
    ThemeColor?: ColoresDPana;
    titulo?: string;
    activo?: boolean;
    handleResponse?: any;
    handleCount?: any;
}
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}
export const ItemTipoOpcionesDlg = forwardRef(({ activo, titulo, item, handleResponse, handleCount, style }: Props, ref) => {
    const [precio, setprecio] = useState<any>();
    const [simbolo, setsimbolo] = useState<any>("");
    const [btns, setbtns] = useState(false);
    let countref = useRef(0);
    useImperativeHandle(ref, () => ({
        Activar,
        item
    }));
    const Activar = (value: boolean) => {
        if (value) {

        }
        else {

        }
    };

    useEffect(() => {
        if (item) {
            if (item.CantidadMaxima && item.CantidadMaxima)
                setbtns(true);
            else
                setbtns(false);
            if (item.MontoPrecioDivisa && item.MontoPrecioDivisa > 0) {
                setprecio(item.MontoPrecioDivisa);
                setsimbolo("$");
            }
            else {
                setprecio("");
                setsimbolo("");
            }
        }
    }, []);

    return (
        <div className="item-detail-root">
            <div className="item-detail-contentitem" >
                <FormControlLabel className="p-0 mx-1" value={item ? item.ID : 0}
                    control={
                        <Radio
                            className="p-0 mx-1" size='small'
                        />}
                    label={
                        <span className="item-detail-txtitem"> {item ? item.Descripcion ? item.Descripcion : "Opción sin Descripción" : "Opción sin Descripción"}
                            <span style={activo ? { fontWeight: 600 } : { fontWeight: 400 }} >
                                {" " + simbolo}
                                <span className="px-1">{precio}</span>
                            </span>
                        </span>}
                />
            </div>
        </div>
    )
});
export default ItemTipoOpcionesDlg;

