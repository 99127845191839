import { Alert, AlertTitle, Collapse } from "@mui/material";
import { split } from "lodash";
import React, { CSSProperties, useEffect, useState } from "react";
import { useStateBasedOnProps } from "../utils/helpers";

type toastType = 'err' | 'inf' | 'wrn' | 'ok' | undefined;
export interface SimpleToastProps {
    title: string,
    message: string,
    setMessage: React.Dispatch<React.SetStateAction<string>>,
    style?: CSSProperties
}

export default function SimpleToast({ title, message, setMessage, style, ...restOfProp }: SimpleToastProps) {
    const [msj, setMsj] = useState('');
    const [tipomsj, settipomsj] = useState<'success' | 'info' | 'warning' | 'error'>('error');
    let s: string = "";
    let bgcolor: string = 'rgba(57,217,98,0.85)';
    let color: string = 'green';
    const verifMesj = () => {
        let m: string = message;
        if (m) {
            if (split(m, '-->').length > 1)
                s = split(message, '-->')[0];
            m = m.replace('err-->', '').replace('inf-->', '').replace('ok-->', '').replace('wrn-->', '');
            if (m.includes('Error: Network Error'))
                m = 'Error de Red. Por favor revise su conexión.'

            if (s === 'err') {
                bgcolor = 'rgba(243, 44, 44 ,0.85)';
                color = 'white';
                settipomsj('error');
            }
            if (s === 'inf') {
                bgcolor = 'rgba(65,175,217,0.85)';
                color = 'black';
                settipomsj('info');
            }
            if (s === 'wrn') {
                bgcolor = 'rgba(231, 214, 17 ,0.85)';
                color = 'black';
                settipomsj('warning');
            }
            if (s === 'ok') {
                bgcolor = 'rgba(57, 217, 98 ,0.85)';
                color = 'green';
                settipomsj('success');
            }
            setMsj(m);
        }
    }
    useEffect(() => {

        verifMesj();

    }, [message])
    useEffect(() => {
        let time: number = 1000;
        if (msj !== '') {
            time = msj.length > 10 ? time * 2 : msj.length > 20 ? time * 3 : msj.length > 30 ? time * 4 : msj.length > 40 ? time * 5 : 1000;

            switch (tipomsj) {
                case 'error':
                    time = time * 1.5;
                    break;
                case 'info':
                case 'warning':
                case 'success':
                    time = time * 1;
                    break;
            }
        }
        if (message !== '' || msj !== '')
            setTimeout(() => {
                setMsj("");
                setMessage("")
            }, time);
    }, [msj])
    return (
        <>
            <Collapse in={msj !== ""}>
                <Alert
                    onClick={() => { setMsj("") }}
                    severity={tipomsj}
                    onClose={() => { setMessage(""); setMsj(""); }}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        maxHeight: "200px",
                        maxWidth: "80%",
                        overflowY: "auto",
                        zIndex: 9999
                    }}
                >
                    <AlertTitle>{title}</AlertTitle>
                    {msj}
                </Alert>
            </Collapse>
        </>

    );
}