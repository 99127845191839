import React, { createContext, useContext, useState } from "react";
import SettingsApp from "../models/Entities/Settings";


export const SettingContext = createContext<SettingsApp>(new SettingsApp);
export const SetSettingContext = createContext<React.Dispatch<React.SetStateAction<SettingsApp>>>({} as React.Dispatch<React.SetStateAction<SettingsApp>>);

export function useSetting() {
    return useContext(SettingContext);
}

export function useSetSetting() {
    return useContext(SetSettingContext);
}
export interface Props {
    children?: any;
}

export function SettingProvider({ children }: Props) {
    const [SettingApp, setSettingApp] = useState<SettingsApp>(new SettingsApp);

    return (
        <SettingContext.Provider value={SettingApp}>
            <SetSettingContext.Provider value={setSettingApp}>
                {children}
            </SetSettingContext.Provider>
        </SettingContext.Provider>
    );
}