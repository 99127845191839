import { useState, useEffect, lazy, Suspense } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import MenuTopIcons from '../../components/menutop-icons';
import { IconMenuTopAtributes } from '../../models/Entities/iconMenuTop';
import { ColorsDPana } from '../../utils/Color';
import { categorias as Categorias } from '../../models/categorias';
import axios from 'axios';
import { subcategorias_productos } from '../../models/subcategorias_productos';
import { productos } from '../../models/productos';
import ItemDetailDlg from '../../components/menu-items/item-detail-dlg';
import { makeStyles } from '@mui/styles';
import { config } from '../../utils/configs';
import logo from '../../assets/imagenes/logo.png';
import logodPana from '../../assets/imagenes/logoicon-white.png';
import { AppBar, CircularProgress, Skeleton, createStyles, Toolbar, Popover, Box, Card, CardMedia, Typography, IconButton, Grid, Button } from '@mui/material';
import { useSetSetting, useSetting } from '../../contexts/setting-context';
import { useOrder, useSetOrder } from '../../contexts/order-context';
import { useLoginToken } from '../../contexts/login-context';
import Orden, { ItemPedido } from '../../models/Entities/Orden.model';
import { clientes } from '../../models/clientes';
import { enuStatusPedido, enuTipoOrigenPedido, enuTipoPedidoDespacho } from '../../utils/enums';
import { addDefaultSrcOnError, enutipodlgOpc } from '../../utils/helpers';
import { formatNumber } from 'fast-number-formatter'
import { Deck, RememberMe } from '@mui/icons-material';
import { mesas } from '../../models/mesas';
import { usuarios } from '../../models/usuarios';
import SpinnerLoading from '../../components/spinner-loading';
import CrudOrdenService from '../../services/crudorden.service';
import SimpleToast from '../../components/simple-toast';
import SimpleDialog from '../dialogs/simple-dialog';
import { loadArchivo } from '../../services/services';
import SelectMesa from '../cart-view/components/select-mesa';
import { dialogResponse } from '../../utils/models';
import { pedidos } from '../../models/pedidos';
import "./style.css";

const MenuItems = lazy(() => import('../../components/menu-items'));
const CarouselComponent = lazy(() => import('../../components/CarouselCat'));

const useStyles: any = makeStyles((theme: any) =>
    createStyles({
        Main: {
            background: "#fff"
        },
        main: {
            width: "95%",
            display: "block",
            padding: "0 .75rem",
            margin: "0 auto"
        },
        list: {
            marginTop: 2,
        },
        subheader: {
            backgroundColor: 'white',
        },
        root: {
            top: 0,
            bottom: 'auto',
            boxShadow: "none",
            padding: 0,
            minHeight: 50

        },
        appBar: {
            top: 0,
            bottom: 'auto',
            backgroundColor: `rgb(${ColorsDPana.black[0]}, ${ColorsDPana.black[1]}, ${ColorsDPana.black[2]})`,
            padding: 5
        },
        grow: {
            flexGrow: 1,
        },
        fabButton: {
            position: 'relative',
            zIndex: 1000,
            margin: '0 auto',
            height: 70,
            width: 95,
            boxShadow: "none",
            borderRadius: "0",
            objectFit: "cover",
            top: 0,
            [theme?.breakpoints?.down(768)]: {
                height: 50,
                width: 50,
                top: 0,
            }
        },
        tbAlto: {
            minHeight: '40px !important',
            maxHeight: 50,
            background: "#fff"
        },
        tbMedio: {
            height: 46,
            minHeight: 46,
            maxHeight: 46
        },
        tbBajo: {

            height: 120,
            minHeight: 120,
            maxHeight: 120
        },
        paper: {
            textAlign: 'center',
            height: 46
        }

    }));

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}
export default function HomeView() {
    const location = useLocation();
    const setting = useSetting();
    const setSetting = useSetSetting();
    const PedidoActual = useOrder();
    const setPedidoActual = useSetOrder();
    const loginToken = useLoginToken();
    const history = useHistory();
    const [mesaSelected, setMesaSelected] = useState<mesas | undefined>(PedidoActual?.Mesa);
    const [meseroSelected, setMeseroSelected] = useState<usuarios | undefined>(PedidoActual?.Mesero);
    const [clienteSelected, setClienteSelected] = useState<clientes | undefined>(PedidoActual?.Cliente);
    const forceUpdate = useForceUpdate();
    const classes = useStyles();
    const [message, setMessage] = useState("");
    const [show, setShow] = useState(false);
    const [itemList, setItemList] = useState<any[]>([]);
    const [categoriaList, setCategoriaList] = useState<Categorias[]>([]);
    const [itemId, setItemId] = useState();
    const [itemSelected, setItemSelected] = useState<Categorias>();
    const [subcategorias, setsubcategorias] = useState<subcategorias_productos[]>([]);
    const [productosList, setproductosList] = useState<productos[]>([]);
    const [openPopover, setopenPopover] = useState(false);
    const [tipoOpc, settipoOpc] = useState<enutipodlgOpc>(enutipodlgOpc.ninguno);
    const [open, setOpen] = useState(false);
    const [esAceptar, setesAceptar] = useState(false);
    const [conValues, setconValues] = useState(false);
    const [tipoDespacho, settipoDespacho] = useState<enuTipoPedidoDespacho>();
    const [loading, setloading] = useState(false);
    const [textloading, settextloading] = useState('');
    const [mesaSelectedprev, setMesaSelectedprev] = useState<mesas | undefined>();

    //POPOVER INFO
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClickInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseInfo = () => {
        setAnchorEl(null);
    };
    const openInfo = Boolean(anchorEl);
    const id = openInfo ? 'simple-popover' : undefined;
    //END POPOVER INFO
    let icons: IconMenuTopAtributes[] =
        [
            { titulo: "Info. Pedido", icon: 'info', color: ColorsDPana.rojoOscuro, children: PedidoActual?.Items?.length, onClick: (e: any) => { handleClickInfo(e) } },
            { titulo: "Carrito Compras", icon: "cart", color: ColorsDPana.rojoOscuro, children: PedidoActual?.Items?.length, onClick: () => { onClickIconCart() } }
        ];
    let iconsPopover: IconMenuTopAtributes[] =
        [
            { titulo: "Carrito Compras", icon: "cart", color: ColorsDPana.black, children: PedidoActual?.Items?.length, onClick: () => { onClickIconCart(); } }
        ];

    //#region Crear orden 25/11/2021
    const [ItemActual, setItemActual] = useState<ItemPedido | null>();
    const [selectedItem, setSelectedItem] = useState<ItemPedido>(new ItemPedido(setting));

    const vaciarPedido = () => {
        if (mesaSelected)
            CrudOrdenService.setBlockMesa(mesaSelected.ID, false, loginToken);
        setPedidoActual(new Orden);
    }
    const esPedidoFacturado = async (id: number) => {
        let res: any;
        let data: pedidos;
        res = await axios.post(config.SERVER_URI + "/pedidos/get", {
            data: { id: id }
        });
        if (res.status == 200) {
            data = res.data as unknown as pedidos;
            if (data) {
                if (data.id_defTipoSatusPedido === enuStatusPedido.PedidoFacturado) {
                    setMessage("inf-->Pedido Facturado. Cargando...")
                    setTimeout(() => {
                        history.push('/cart');
                    }, 1500);
                }
            }
        } else {

        }
    }
    const loadPedido = async (id: number): Promise<pedidos | undefined> => {
        let orden: any = new pedidos;
        await CrudOrdenService.loadPedido(id, loginToken).then(async (res: any) => {
            orden = res as Orden;
            orden.id_defTipoPedido = enuTipoPedidoDespacho.ComerAqui;
            if (!orden.Mesa) {
                setMessage("Pedido sin Mesa")
                vaciarPedido()
                return
            }
            selectMesa(orden.Mesa, true)
            setMeseroSelected(orden.Mesero);
            setPedidoActual(orden);
            if (orden.id_defTipoSatusPedido === enuStatusPedido.PedidoFacturado) {
                setMessage("inf-->Pedido Facturado. Cargando...")
                setTimeout(() => {
                    history.push('/cart');
                }, 1500);
            }
            return Promise.resolve(orden);
        }).catch(err => {
            setMessage(`err-->Al cargar Pedido.\n${err}`)
            return undefined;
        })
        return orden;

        /*   setloading(false); */

    }
    let newOrder!: Orden;
    const CrearOrden = () => {
        newOrder = new Orden();
        switch (tipoDespacho) {
            case enuTipoPedidoDespacho.Desconocido:
                break;
            case enuTipoPedidoDespacho.ComerAqui:
                /*  if (mesaSelected && mesaSelected.mesas_detalles.length) {
                     loadPedido(mesaSelected.mesas_detalles[0].id_Pedido);
                     return;
                 } */
                setting.MesaActual = mesaSelected;
                setting.UsuarioActual = meseroSelected;
                break;
            case enuTipoPedidoDespacho.llevar:
                break;
            case enuTipoPedidoDespacho.Delivery:
                break;
            case enuTipoPedidoDespacho.Pickup:
                break;
            case enuTipoPedidoDespacho.ConsumoInterno:
                break;
            case enuTipoPedidoDespacho.Pedido:
                break;
            case enuTipoPedidoDespacho.Guardado:
                break;
            case enuTipoPedidoDespacho.Recuperado:
                break;
            case enuTipoPedidoDespacho.VentaDirecta:
                setting.MesaActual = undefined;
                break;
            default:
                break;
        }
        newOrder.initOrden(setting, tipoDespacho ? tipoDespacho : enuTipoPedidoDespacho.ComerAqui, enuStatusPedido.Procesando, enuTipoOrigenPedido.AplicacionMovil);
        setSetting(setting);
        setPedidoActual(newOrder);
    }

    const editItem = (item: ItemPedido, addToPedido: boolean = false) => {
        newOrder = PedidoActual;
        if (!newOrder || !newOrder.FechaActual || !newOrder.Items)
            CrearOrden();
        if (newOrder) {
            let tmpitem = newOrder.editItem(item);
            if (tmpitem && tmpitem.CantidadTotal && tmpitem.Comentario?.includes('(ELIMINADO)'))
                tmpitem.Comentario = tmpitem?.Comentario.replace('(ELIMINADO)', '');
            setItemActual(() => tmpitem as ItemPedido);
            setSelectedItem(tmpitem as ItemPedido);
            setPedidoActual(() => newOrder);
        } else
            setMessage("No hay Pedido Actual");
        forceUpdate();
    }

    //#endregion

    const loadMesasList = (spinner: boolean = false) => {
        return new Promise(async (resolve, reject) => {
            setloading(spinner);
            await axios.post(config.SERVER_URI + "/adminonline/getmesascpedido")
                .then(res => {
                    resolve(res.data)
                }).catch(err => {
                    setMessage('err-->' + err);
                    reject(err);
                })
            setloading(false);
        });
    };
    const loadArchivos = () => {
        if (categoriaList) {
            axios.post(config.SERVER_URI + "/web/itemslist").then(result => {
                let items: productos[] = result.data
                setproductosList(items);
                if (result.data && result.data.length > 0) {
                    if (categoriaList && categoriaList.length > 0) {
                        categoriaList.map((cat) => {
                            if (cat.subcategorias_productos && cat.subcategorias_productos.length) {
                                cat.subcategorias_productos.map((scat) => {
                                    items.forEach(e => {
                                        if (!cat.productos)
                                            cat.productos = [];
                                        if (e.id_sub_categoria == scat.ID)
                                            cat.productos.push(e);
                                    });
                                });
                            }
                        });
                    }
                }
                forceUpdate();
            }).catch(err => {
                console.error(err);
                if (err.response.data)
                    setMessage(err.response.data);
            })
        }
        loadArchivo(
            'usuarios',
            true,
            ['Nombre', 'Imagen', 'NombreUsuario', 'Pass', 'CodigoPin'], undefined, undefined
        ).then(
            (resolve) => { setMeseroslst(resolve as usuarios[]) },
            (error) => { setMessage('err-->' + error) }
        );
    };
    const loadList = (item: Categorias | undefined = undefined) => {
        //get a list of all cajas
        axios.post(config.SERVER_URI + "/web/catslist", {
            options: {
                include: ["subcategorias_productos"]
            }
        }).then(result => {
            if (result && result.data) {
                var c: Categorias[] = [];
                result.data.forEach((e: Categorias) => {
                    if (e.subcategorias_productos && e.subcategorias_productos.length)
                        c.push(e);
                });
                setCategoriaList(c);
            }
        }).catch(err => {
            console.error(err);
            setMessage(err.response.data);
        })

    }
    const onClickAdminPage = () => {
        history.push("/login")
    }
    const onClickIconCart = () => {
        PedidoActual.id_defTipoSatusPedido = enuStatusPedido.Editando;
        setPedidoActual(PedidoActual);
        selectMesa(mesaSelected, false);
        history.push("/cart")

    };
    //#region Dialogs
    const [openSDIalog, setOpenSDialog] = useState(false);
    const [TitleSDialog, setTitleSDialog] = useState('');
    const [ChildrenSDIalog, setChildrenSDialog] = useState(<button />);
    const [Meseroslst, setMeseroslst] = useState<usuarios[]>([]);
    const [Mesaslst, setMesaslst] = useState<mesas[]>([]);
    const [okbtnMDialog, setokbtnMDialog] = useState<boolean | undefined>(undefined);
    const [ChildrenMDIalog, setChildrenMDialog] = useState(<button />);
    const [openMDIalog, setopenMDIalog] = useState(false);
    const [esOK, setesOK] = useState(false);
    const [sDialogOptions, setsDialogOptions] = useState<any>();

    const childDialogRes = (item: any) => {
        if (item) {
            editItem(item);
            setopenPopover(true);
        }
        setOpenSDialog(false);
    }

    const loadMenuDlg = (e: any) => {
        setChildrenSDialog(<ItemDetailDlg setting={setting} item={e} handleResponse={childDialogRes} ></ItemDetailDlg>);
        handleClickOpenSimpleDialog();
    };
    const handleClickOpenSimpleDialog = () => {
        setOpenSDialog(true);
    };
    const handleCloseSimpleDialog = () => {
        setOpenSDialog(false);
    };
    const handleClose = () => {
        setopenPopover(false);
    };
    const PopupExtras = () => {
        let extras: string = "";
        let i: number = 0;
        if (ItemActual) {
            if (ItemActual.Extras) {
                ItemActual.Extras.forEach(ex => {
                    i++;
                    extras +=
                        (ex.CantidadTotal && ex.CantidadTotal > 1 ? ex.CantidadTotal + 'x' : '') +
                        ex.Nombre + ' ' +
                        (ex.MontoPrecioDivisa && ex.MontoPrecioDivisa > 0 ? formatNumber(ex.MontoPrecioDivisa, 2) : '') +
                        (i != ItemActual.Extras.length ? ', ' : '');
                });
            }
        }
        return (
            <Typography variant="caption" component="div">
                {extras}
            </Typography>
        )

    }

    const loadSelectMesa = (titulo: string) => {
        //vaciarPedido();
        /* setsDialogOptions({
            esOKEnabled: false,
            esOKButton: false,
            esCancelButton: false,
            handleResponse: (e: dialogResponse) => {
                

            }
        }); */
        setChildrenSDialog(<SelectMesa
            ListMeseros={Meseroslst}
            meseroSelected={meseroSelected}
            setMeseroSelected={setMeseroSelected}
            mesaSelected={mesaSelected}
            setMesaSelected={setMesaSelected}
            List={Mesaslst} esOK={esOK}
            setOpen={setOpenSDialog}
            handleResponse={onloadMesa}
            titulo={titulo} />);
        setOpenSDialog(true);
    }

    const onloadMesa = (res: dialogResponse, newMesa: mesas, newMesero?: usuarios) => {
        switch (res) {
            case 'OK':
                let id: number = 0;
                selectMesa(newMesa, true)
                if (newMesa?.mesas_detalles?.length && newMesa.mesas_detalles[0].id_Pedido) {
                    id = newMesa.mesas_detalles[0].id_Pedido;
                    loadPedido(id).then((response: any) => {
                        if (response && response.Mesero !== newMesero) {
                            if (response && newMesero) {
                                response.Id_Usuario = newMesero.ID;
                                response.Mesero = newMesero;
                                CrudOrdenService.modifPedido(response, loginToken).then(() => {
                                    loadPedido(id);
                                });
                            }
                        }
                    });


                }
                setOpenSDialog(false);
                break;
            case 'No':
                setMeseroSelected(undefined);
                selectMesa(mesaSelected, false);
                vaciarPedido()
                break;

        }

    }
    //#endregion
    const selectMesa = (mesa: mesas | undefined, select: boolean) => {
        CrudOrdenService.setBlockMesa(mesaSelectedprev ? mesaSelectedprev.ID : 0, false, loginToken);
        if (select) {
            CrudOrdenService.setBlockMesa(mesa ? mesa.ID : 0, true, loginToken);
            setMesaSelected(mesa);
            setMesaSelectedprev(mesa);
        }
        else {
            CrudOrdenService.setBlockMesa(mesa ? mesa.ID : 0, false, loginToken);
            setMesaSelected(undefined);
        }
    }
    const handleClickSelect = (opc: any) => {
        settipoOpc(opc)
        switch (opc) {
            case enutipodlgOpc.cliente:
                setesAceptar(true);
                if (clienteSelected)
                    setconValues(true);
                else
                    setconValues(false);
                break;
            case enutipodlgOpc.mesa:
            case enutipodlgOpc.mesero:
                loadSelectMesa("Seleccionar Mesa");
                return;
            default:
                break;
        }
        setOpen(true)
    }
    useEffect(() => {
        //console.log(location.pathname); // result: '/secondpage'
        if (location && productosList && productosList.length > 0) {
            if (location.search === '?detail=true') {
                let myparam = location.state as any;
                if (myparam && myparam.id) {
                    let item = productosList.find(x => x.ID === myparam.id)
                    if (item)
                        loadMenuDlg(item);
                    //console.log("ID:" + i); // result: 'some_value'
                }
            }
        }
    }, [productosList]);
    useEffect(() => {
        let isMounted = true
        const intervalId = setInterval(async () => {
            if (!isMounted) return
            loadMesasList(false).then((res: any) => {
                if (res)
                    setMesaslst(res);
            })
            if (setting.idPedidoActual) {

                esPedidoFacturado(setting.idPedidoActual)
            }
        }, 5000)

        return () => {
            clearInterval(intervalId); //This is important
            isMounted = false // Let's us know the component is no longer mounted.
        }
    }, [useState])
    useEffect(() => {
        if (PedidoActual) {
            setting.idPedidoActual = PedidoActual.ID
            setSetting(setting);
        }
    }, [PedidoActual])
    useEffect(() => {
        loadArchivos();
    }, [categoriaList]);
    useEffect(() => {
        //Cambiar de mesero
        /* if (PedidoActual && PedidoActual.ID) {
            if (PedidoActual?.Mesero !== meseroSelected)
                PedidoActual.Mesero = meseroSelected;
        } */
        if (PedidoActual && !PedidoActual.ID) {
            PedidoActual.Mesero = meseroSelected;
        }

    }, [meseroSelected]);

    useEffect(() => {
        if (PedidoActual && !PedidoActual.ID) {
            PedidoActual.Mesa = mesaSelected;
        }
        if (!mesaSelected)
            vaciarPedido();
    }, [mesaSelected]);

    useEffect(() => {
        loadList();
        loadMesasList().then((res: any) => {
            if (res)
                setMesaslst(res);
        })
        loadArchivos();
        settipoDespacho(enuTipoPedidoDespacho.ComerAqui);
        if (PedidoActual && (PedidoActual.Mesa || PedidoActual.Mesero)) {
            selectMesa(PedidoActual.Mesa, true)
            setMeseroSelected(PedidoActual.Mesero);
            setClienteSelected(PedidoActual.Cliente);
            if (PedidoActual.ID && PedidoActual.id_defTipoSatusPedido !== enuStatusPedido.Editando) {
                loadPedido(PedidoActual.ID)
            }
        }
    }, []);

    return (
        <div className={classes.Main} >
            <AppBar className={classes.root} position="fixed" color='transparent' style={{ color: 'white', background: "rgb(124, 0, 0) " }}>
                <Toolbar className={classes.tbAlto}>
                    <Box className="p-0" sx={{ flexGrow: 1, width: '100%', maxHeight: 50 }}>
                        <Grid
                            xs={12} md={12}
                            className=" w-100 p-0 m-0 h-100"
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            spacing={1}>
                            <Grid xs={4}>
                                <Button className="btnMenu rounded-0" sx={{ color: "#fff", position: "absolute", top: "0", backgroundColor: "#7d1414", width: "50px", height: "50px" }}>
                                    <img
                                        src={logodPana}
                                        style={{ maxHeight: 50, maxWidth: 50 }}
                                        onClick={() => {
                                            history.push('/gotodpana');
                                        }} />

                                </Button>
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "center" }} xs={4}>
                                <div style={{ display: "flex", alignItems: "center", borderRadius: "50%", justifyContent: "center", height: "100px", width: "112px", backgroundColor: "#fff" }}>
                                    <img color="secondary" aria-label="add" src={logo} className={classes.fabButton} onClick={() => { onClickAdminPage(); }} />
                                </div>
                            </Grid>
                            <Grid
                                className='p-0 m-0 h-100'
                                item
                                container
                                direction='row'
                                justifyContent="flex-end"
                                alignItems="flex-end" xs={4} sm={4}
                            >
                                <MenuTopIcons Icons={icons}></MenuTopIcons>

                            </Grid>
                        </Grid>
                    </Box>
                </Toolbar>
                <Toolbar className={classes.tbMedio}>
                    <Grid container xs={12} >
                        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={4} >
                            <p style={{ textDecoration: "underline" }}> MENU </p>
                        </Grid>
                        <Grid xs={4}  >
                        </Grid >
                        <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={4}>
                            <p style={{ textDecoration: "underline" }}> PROMO </p>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Toolbar className={classes.tbBajo}>
                    <Suspense fallback={<div className="vw-100 h-100 d-flex flex-column m-0"><CircularProgress color="inherit" />Cargando...</div>}>
                        <CarouselComponent itemsCategroia={categoriaList}></CarouselComponent>
                    </Suspense>
                </Toolbar>
            </AppBar>

            <div className={classes.main}>
                {categoriaList.map((cat, idx) => {
                    return (
                        <Suspense key={'mi' + idx} fallback={<div className="w-100 h-100 d-flex flex-column m-2 "><Skeleton variant='rectangular' width={210} height={118} /></div>}>
                            <MenuItems handleClick={loadMenuDlg} key={"cat-" + idx} idx={idx} Categoria={cat} itemsMenu={cat.productos} ></MenuItems>
                        </Suspense>
                    )
                })
                }
            </div>
            <Popover
                open={openPopover}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 5, left: 5 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClose}
                style={{ opacity: '0.9' }}
            >
                {ItemActual
                    ? <Card className="p-1 bg-warning" sx={{ display: 'flex', maxWidth: 400 }}  >
                        <Grid container spacing={2}>
                            <Grid xs={3} md={3} item>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 80, height: 120 }}
                                    onError={addDefaultSrcOnError}
                                    image={ItemActual.Imagen}
                                />
                            </Grid>
                            <Grid item xs={9} md={9} container>
                                <Grid
                                    xs={9} md={9}
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Typography variant="subtitle1" component='div'>
                                        {` ${(ItemActual.MontoPrecioDivisa && ItemActual.MontoPrecioDivisa > 0 ? (ItemActual.CantidadTotal && ItemActual.CantidadTotal > 1 ? ItemActual.CantidadTotal + 'x' : '') : '') +
                                            (ItemActual.MontoPrecioDivisa && ItemActual.MontoPrecioDivisa > 0 ? formatNumber(ItemActual.MontoPrecioDivisa, 2) : '')}`}
                                    </Typography>
                                    <Typography component="div" variant="h6">
                                        {`${ItemActual.Descripcion}`}
                                    </Typography>
                                    {<PopupExtras />}
                                    <Grid
                                        className="px-1 alert-warning   "
                                        xs={12} md={12}
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography variant='caption' component="div">
                                            Total:
                                        </Typography>
                                        <Typography variant="subtitle1" component="b">
                                            {` ${formatNumber(ItemActual.MontoTotal, 2)}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    className="border-end border-bottom border-2 border-danger"
                                    xs={3} md={3}
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                >
                                    <Typography className="px-1 text-end alert-warning" variant="caption" component="p">
                                        Pedido:
                                        <Typography variant="h6" component="div">
                                            {`${formatNumber(PedidoActual.getMontoTotal, 2)}`}
                                        </Typography>
                                    </Typography>
                                    <IconButton className="px-1 bg-danger bg-opacity-50" aria-label="Visitar Carrito">
                                        <MenuTopIcons className="h-100" Icons={iconsPopover} ></MenuTopIcons>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                    : ""
                }
            </Popover>

            <Popover
                id={id}
                open={openInfo}
                anchorEl={anchorEl}
                onClose={handleCloseInfo}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {PedidoActual && PedidoActual.Mesa
                    ? <Card className="p-1 pe-2 bg-warning" sx={{ display: 'flex', maxWidth: 150, minHeight: 150 }}  >
                        <Grid xs={12} md={12}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography className="text-center bg-danger bg-gradient bg-opacity-25 w-100 " component='div' variant="h6" >
                                {PedidoActual.ID ? `Pedido: ` + PedidoActual.NroPedido : 'Sin Enviar'}
                            </Typography>
                            <Button className=' p-1 m-1 pe-2 text-dark border-danger  border-bottom w-100 justify-content-between' onClick={() => { handleClickSelect(enutipodlgOpc.mesa); }}>
                                <Deck />
                                <Typography className="px-0 text-start  " variant='caption'>{mesaSelected?.Nombre}</Typography>
                            </Button>
                            <Button className=' p-1 m-1 pe-2 text-dark border-danger border-bottom w-100 justify-content-between ' onClick={() => { handleClickSelect(enutipodlgOpc.mesero); }} >
                                <RememberMe />
                                <Typography className="px-0 text-start " variant='caption'>{meseroSelected?.Nombre}</Typography>
                            </Button>

                        </Grid>
                    </Card>
                    :
                    <Card className="p-1 bg-warning" sx={{ display: 'flex', maxWidth: 150 }}  >

                        <Grid
                            className="p-0 m-0"
                            xs={12} md={12}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Deck />
                            <Button className="p-1 text-center bg-danger bg-gradient bg-opacity-25 w-100 " style={{ minWidth: 0 }} onClick={() => { handleClickSelect(enutipodlgOpc.mesa); }}>
                                <Typography className="px-0 text-start text-white text-capitalize " variant='caption'>
                                    Seleccionar Mesa</Typography>
                            </Button>
                        </Grid>
                    </Card>
                }
            </Popover>
            <SpinnerLoading tipo='spinner' open={loading} text={textloading} />
            <SimpleDialog selectedValue={"selectedValue"} ThemeColor={ColorsDPana.primary} open={openSDIalog} setOpen={setOpenSDialog} onClose={handleCloseSimpleDialog} children={ChildrenSDIalog} title={TitleSDialog} />
            <SimpleToast title="Mensaje Aplicación" message={message} setMessage={setMessage} />

        </div>
    )
}

